import React, { useState, useEffect } from "react";
import { Box, Select, MenuItem, FormControl, InputLabel, Grid } from "@mui/material";
import { getTRD } from "../../services/backend/ExpedientService";
import { useSelector } from "react-redux";


const SelectTRD = ({ required = true, onChange = () => {} }) => {
  const user = useSelector((state) => state.user.value);
  const [TRD, setTRD] = useState();
  const [dependence, setDependece] = useState('');
  const [serie, setSerie] = useState('');
  const [subserie, setSubserie] = useState('');

  const getTRDAsync = async () => {
    if (TRD) return;
    const response = await getTRD(user);
    setTRD(response);
  };

  useEffect(() => {    
    getTRDAsync();
  }, []); // eslint-disable-line

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Box sx={{ mt: 1, mb: -1, fontSize: '.9em', fontWeight: 'bold', ml: 1 }}>Seleccionar TRD</Box>
      </Grid>
      {TRD
        && <Grid item xs={serie ? 4 : dependence ? 6 : 12}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="trd-dependence">Dependencia</InputLabel>
            <Select
              required={required}
              labelId="trd-dependence"
              label="Formato de documento"
              value={dependence || ''}
              onChange={(evt) => {
                setDependece(evt.target.value);
                setSerie('');
                setSubserie('');
                onChange(null);
              }}
            >
              {TRD.map((item) => (
                <MenuItem key={item.code} value={item}>{item.dependence}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }

      {dependence
        && <Grid item xs={serie ? 4 : 6}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="trd-serie">Serie</InputLabel>
            <Select
              required={required}
              labelId="trd-serie"
              label="Serie"
              value={serie || ''}
              onChange={(evt) => {
                setSerie(evt.target.value);
                setSubserie('');
                onChange(null);
              }}
            >
              {Object.keys(dependence.series).map((code) => (
                <MenuItem key={code} value={dependence.series[code]}>
                  {dependence.series[code].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }

      {serie
        && <Grid item xs={4}>
          <FormControl fullWidth sx={{ marginY: 1 }}>
            <InputLabel id="trd-subserie">Subserie</InputLabel>
            <Select
              required={required}
              labelId="trd-subserie"
              label="Subserie"
              value={subserie || ''}
              onChange={(evt) => {
                setSubserie(evt.target.value);
                const { series, ...restDep } = dependence;
                const { subseries, ...restSerie } = serie;
                onChange({
                  dependence: restDep,
                  serie: restSerie,
                  subserie: evt.target.value
                });
              }}
            >
              {Object.keys(serie.subseries).map((code) => (
                <MenuItem key={code} value={serie.subseries[code]}>
                  {serie.subseries[code].name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      }
    </Grid>
  );
};

export default SelectTRD;
