import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  TextField,
  Button,
  Box,
  FormControl
} from "@mui/material";

const AddNewTagModal = ({ open, title, onSubmit, onClose }) => {
  const[name, setName] = useState('');

  const handleClose = () => {
    if (onClose) onClose();
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (onSubmit) onSubmit(name);
    handleClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Añadir nuevo {title}</DialogTitle>
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 1 }}>
        <FormControl fullWidth sx={{ my: 1 }}>
          <TextField
            label="Nombre"
            required
            onChange={(evt) => setName(evt.target.value)}
          />
        </FormControl>
        <FormControl fullWidth sx={{ my: 1 }}>
          <Button variant="contained" type="submit">
            Añadir
          </Button>
        </FormControl>
      </Box>
    </Dialog>
  );
};

export default AddNewTagModal;
