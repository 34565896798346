import React, { useState, useEffect } from "react";
import {
  Box, Button, Card, Chip, CircularProgress, Container, Paper, Table, TableBody,
  TableCell, TableContainer, TableHead, TableRow, Typography, Dialog,
  Accordion, AccordionSummary, AccordionDetails
} from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBack from '@mui/icons-material/ArrowBack';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import DocumentFormModal from "./DocumentFormModal";
import ExpedientFormModal from "./ExpedientFormModal";
import { getDocuments, getExpedient } from "../../services/backend/ExpedientService";
import { getFiled } from "../../services/backend/CorrespondenceService";
import { PERMISSIONS } from "../../constants";
import DocumentItem from "./DocumentItem";
import FiledItem from "./FiledItem";
import { sortAsc } from "../../functions";

const Expedient = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const user = useSelector((state) => state.user.value);
  const defaultExpedient = state?.defaultExpedient;
  const { expedientId } = useParams();
  const [expedient, setExpedient] = useState(defaultExpedient);
  const [openExpedientForm, setOpenExpedientForm] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [documentForm, setDocumentForm] = useState();
  const [documents, setDocuments] = useState();
  const [isOpenPreview, setIsOpenPreview] = useState(false);
  const [fileds, setFileds] = useState([]);

  useEffect(() => {
    (async () => {
      let response;
      if (!expedient) {
        response = await getExpedient(expedientId, user);
        if (!user.permissions.includes(PERMISSIONS.EXPEDIENT.READ)
            && !response.editors?.includes(user.userId)
            && !response.viewers?.includes(user.userId)) navigate('/home');
        if (response) setExpedient(response);
      } else {
        if (!documents) {
          response = await getDocuments(expedient.expedientId, user);
          setDocuments(response);
        }

        if (expedient.fileds) {
          const promises = [];
          const arr = [];
          for (let i = 0; i < expedient.fileds.length; i++) {
            promises.push(getFiled(user.token, expedient.fileds[i]).then((f) => arr.push(f)))
          }

          await Promise.all(promises);
          setFileds(arr);
        }
      }
    })();
  }, [expedient]); // eslint-disable-line

  if (!expedient) return (
    <Box sx={{ mt: 2, textAlign: 'center'}}
      ><CircularProgress />
    </Box>
  );
  
  return (
    <Container sx={{ p: 2 }}>
      <Dialog open={!!isOpenPreview} onClose={() => setIsOpenPreview(false)}>
        <img alt='preview' src={isOpenPreview} width={520} style={{ maxWidth: '100%' }} />
      </Dialog>

      <Box sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5,
      }}>
        <Typography variant="h4">
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            style={{ margin: 16 }}
            onClick={() => navigate(-1)}
          >
            Volver
          </Button>
          {expedient.description}
        </Typography>
        <Box>
        {(user.permissions.includes(PERMISSIONS.EXPEDIENT.UPDATE)
          || expedient.editors?.includes(user.userId))
          && <Button variant="outlined" onClick={() => setOpenExpedientForm(true)} sx={{ mr: 2 }}>
              Modificar expediente
            </Button>
          }
          {(user.permissions.includes(PERMISSIONS.EXPEDIENT.CREATE_DOCUMENT)
            || expedient.editors?.includes(user.userId))
            && <Button variant="contained" onClick={() => setOpenForm(true)}>
              Agregar Documento
            </Button>
          }
        </Box>
      </Box>

      <ExpedientFormModal
        open={openExpedientForm}
        expedient={expedient}
        expedientTypeId={expedient.expedientTypeId}
        trd={expedient.trd}
        onClose={() => setOpenExpedientForm(false)}
      />

      <DocumentFormModal
        open={openForm}
        expedient={expedient}
        document={documentForm}
        setDocumentForm={setDocumentForm}
        onClose={() => setOpenForm(false)}
        onSubmit={(document) => {
          setDocuments([...documents, document ]);
        }}
      />

      <Card sx={{ p: 2, maxWidth: 600, margin: 'auto' }}>
        <Box sx={{ textAlign: 'right' }}>
          <Typography>
            <strong>Creado en: </strong>{expedient.createdAt}
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', my: 3 }}>
          <Typography>
            <strong>Identificador de expediente</strong>: {expedient.expedientId}
          </Typography>
          <Typography>
            <strong>Tipo</strong>: {expedient.expedientTypeId}
          </Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Typography><strong>Descripción</strong></Typography>
          <Typography>{expedient.description}</Typography>
        </Box>
        <Box sx={{ my: 3 }}>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1-content"
              id="panel1-header"
            >
              Metadatos y palabras claves
            </AccordionSummary>
            <AccordionDetails>
              <Box>
                <Typography><strong>Metadatos</strong></Typography>
                <Typography sx={{ fontFamily: 'courier' }}>
                  <pre>{JSON.stringify(expedient.metadata, null, 2)}</pre>
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ mb: 1 }}><strong>Palabras claves</strong></Typography>
                {expedient.keywords.map((keyword) => (<Chip key={keyword} label={keyword} sx={{ mr: 1, mb: 1 }} />))}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {expedient.fileds?.length > 0
          && <Box sx={{ my: 3 }}>
            <Typography sx={{ mb: 1 }}><strong>Radicados relacionados</strong></Typography>
            {fileds.length === 0 && <CircularProgress />}
            {fileds.length > 0
              && <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Previa</TableCell>
                      <TableCell>Radicado</TableCell>
                      <TableCell>Descripción</TableCell>
                      <TableCell>Ver</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {fileds.sort(sortAsc('createdAt')).map((filed) => (
                      <FiledItem
                        key={filed.filedId}
                        filed={filed}
                        setIsOpenPreview={setIsOpenPreview}
                      />
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            }
          </Box>
        }
        <Box sx={{ my: 3 }}>
          <Typography sx={{ mb: 1 }}><strong>Documentos</strong></Typography>
          {!documents && <CircularProgress />}
          {documents?.length === 0 && 'No hay documentos en el expediente'}
          {documents && documents.length !== 0
            && <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Previa</TableCell>
                    <TableCell>Nombre</TableCell>
                    <TableCell>Folios</TableCell>
                    <TableCell>Ver</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {documents.sort(sortAsc('createdAt'))?.map((document) => (
                    <DocumentItem
                      key={document.documentId}
                      document={document}
                      setIsOpenPreview={setIsOpenPreview}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          }
        </Box>
      </Card>
    </Container>
  );
};

export default Expedient;
