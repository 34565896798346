import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Header from "./Header";
import Flow from "../flow/Flow";
import RichEditor from '../generic/rich-editor';
import Editor from "../flow/Editor";
import { PERMISSIONS } from "../../constants";

export default function LayoutViewFlow() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [flow, setFlow] = useState(null);

  useEffect(() => {
    if (!user.permissions.includes(PERMISSIONS.PROCESS.READ_FLOW)) {
      navigate('/processes');
    }
  }, [user.permissions, navigate]);

  return (
    <>
      <Header />
      <Container maxWidth={false}>
        <Grid container>
          <Grid item xs={6}>
            <Flow parentSetFlow={setFlow} disabled={true} />
          </Grid>
          <Grid item xs={6}>
            {flow && (
              flow.document?.file ?
              <RichEditor readOnly={true} file={flow.document.file} />
              : <Editor disabled={true} flow={flow} />
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
