import React, { useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Container from "@mui/material/Container";
import { debugLog } from "../../functions";

function AddKanbanDialog({ dialogInfo, handleClose }) {
  const [kanban, setKanban] = useState({});

  const handleCloseDialog = () => {
    setKanban({});
    handleClose(false);
  };
  const handleCreateKanban = () => {
    debugLog("panel: ", kanban);
    const newKanban = { ...kanban };
    setKanban({});
    handleClose(true, newKanban);
  };

  const handleChange = (e) => {
    //debugLog("e.target: ", e.target["data-indeterminate"]);
    //debugLog("e.target.name: ", e.target.name);
    //debugLog("e.target.value: ", e.target.value);
    e.preventDefault();
    let newTask = {
      ...kanban,
      [e.target.name]: e.target.value,
    };
    setKanban(newTask);
  };

  return (
    <Dialog open={dialogInfo.open} onClose={handleCloseDialog}>
      <DialogTitle>Crear Tablero</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To subscribe to this website, please enter your email address here. We
          will send updates occasionally.
        </DialogContentText>
        <Container maxWidth="md">
          <TextField
            autoFocus
            margin="dense"
            id="name"
            name="name"
            label="Nombre de Tablero"
            fullWidth
            variant="standard"
            value={kanban.name}
            onChange={handleChange}
          />
          <TextField
            autoFocus
            margin="dense"
            id="description"
            name="description"
            label="Description de Tablero"
            fullWidth
            variant="standard"
            value={kanban.description}
            onChange={handleChange}
          />
        </Container>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog}>Cancel</Button>
        <Button onClick={handleCreateKanban}>Crear Panel</Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddKanbanDialog;
