import React from "react";

import Header from "./Header";
import ProcessTemplate from "../processes/ProcessTemplate";
import Process from "../processes/Process";

export default function LayoutViewProcessTemplate({ instance }) {
  return (
    <>
      <Header />
      {instance ? <Process /> : <ProcessTemplate />}
    </>
  );
}
