import { createSlice } from "@reduxjs/toolkit";

export const correspondenceSlice = createSlice({
  name: "correspondences",
  initialState: {
    value: null,
  },
  reducers: {
    setCorrespondence: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCorrespondence } = correspondenceSlice.actions;

export default correspondenceSlice.reducer;
