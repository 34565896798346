import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Avatar, Box, Button, Card, CardActionArea, CardHeader, CircularProgress,
  Container, IconButton, Menu, MenuItem, TextField, Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBack from '@mui/icons-material/ArrowBack';

import { setSearch } from "../../redux/slices/expedientSlice";
import { getExpedientType, getExpedientsByValue } from "../../services/backend/ExpedientService";
import ExpedientFormModal from "./ExpedientFormModal";
import { PERMISSIONS } from "../../constants";

let timeout;

const ExpedientList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { expedientTypeId } = useParams();
  const user = useSelector((state) => state.user.value);
  const { searchText, expedients } = useSelector((state) => state.expedient);
  const [expedientType, setExpedientType] = useState();
  const [expedientForm, setExpedientForm] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [openMenu, setOpenMenu] = useState();

  const handleMenuOpen = (evt, expedient) => {
    evt.stopPropagation();
    setExpedientForm(expedient);
    setOpenMenu(evt.currentTarget);
  };

  const getExpedientsByValueAsync = async (value) => {
    if (!value) return dispatch(setSearch({ searchText: '', expedients: undefined }));

    dispatch(setSearch({ searchText: value, expedients: null }));
    const response = await getExpedientsByValue(value, expedientTypeId, user);
    if (response) dispatch(setSearch({ searchText: value, expedients: response }));
    else dispatch(setSearch({ searchText: value, expedients: undefined }));
  };

  const getExpedientTypesAsync = async () => {
    const response = await getExpedientType(expedientTypeId, user);
    if (!response.owners.includes(user.userId)
      && !user.permissions.includes(PERMISSIONS.EXPEDIENT.LIST)) navigate('/expedients');
    if (response) setExpedientType(response);
  };

  useEffect(() => {
    getExpedientTypesAsync();
  }, []); // eslint-disable-line

  return (
    <Container sx={{ p: 2 }}>
      <Box sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5,
      }}>
        <Typography variant="h4">
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            style={{ margin: 16 }}
            onClick={() => navigate(-1)}
          >
            Volver
          </Button>
          Expedientes: {expedientTypeId}
        </Typography>
        {user.permissions.includes(PERMISSIONS.EXPEDIENT.CREATE)
          && <Button variant="contained" onClick={() => setOpenForm(true)}>
            Crear Nuevo Expediente
          </Button>
        }
      </Box>

      <ExpedientFormModal
        open={openForm}
        expedient={expedientForm}
        expedientTypeId={expedientTypeId}
        trd={expedientType?.trd}
        onClose={() => setOpenForm(false)}
      />

      <Menu
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => {
          setOpenMenu(null);
          setExpedientForm(null);
        }}
      >
        <MenuItem onClick={() => setOpenForm(true)}>
          Editar
        </MenuItem>
      </Menu>

      <Box sx={{ textAlign: 'center', py: 3 }}>
        <TextField
          placeholder="Buscar expediente"
          value={searchText}
          onChange={(evt) => {
            dispatch(setSearch({ searchText: evt.target.value, expedients }));
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(() => getExpedientsByValueAsync(evt.target.value), 2000);
          }}
          onKeyDown={(evt) => {
            if (evt.key === 'Enter') {
              if (timeout) clearTimeout(timeout);
              getExpedientsByValueAsync(searchText);
            }
          }}
        />
      </Box>

      {expedients?.length === 0 && <Typography>No se encontraron expedientes con el criterio de búsqueda</Typography>}
      {expedients ? (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {expedients.map((expedient) => (
            <Card
              key={expedient.expedientId}
              sx={{ cursor: 'pointer', m: 1, p: 1, width: 300 }}
            >
              <CardActionArea
                onClick={() => navigate(
                  `/expedients/${expedientTypeId}/${expedient.expedientId}`,
                  { state: { defaultExpedient: expedient } }
                )}
              >
                <CardHeader
                  avatar={<Avatar>R</Avatar>}
                  title={(
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ flex: 1 }}>{expedient.expedientId}</Box>
                      <IconButton onClick={(evt) => handleMenuOpen(evt, expedient)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  )}
                />
                <Box>{expedient.description}</Box>
                <Box sx={{ textAlign: 'right', mt: 1 }}>
                  {expedient.createdAt}
                </Box>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      ) : expedients === null ? (
        <Box sx={{ textAlign: "center", width: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", width: 1 }}>
          Realice una búsqueda para mostrar los expedientes que coincidan con la búsqueda
        </Box>
      )}
    </Container>
  );
};

export default ExpedientList;
