import React, { useState } from "react";
import {
  BottomNavigation,
  BottomNavigationAction,
  Container,
} from "@mui/material";

import FavoriteIcon from "@mui/icons-material/Favorite";
import ArchiveIcon from "@mui/icons-material/Archive";
import ViewKanbanIcon from "@mui/icons-material/ViewKanban";
import KanbanConfig from "./KanbanConfig";

function Config() {
  const [option, setOption] = useState("kanban");

  return (
    <Container style={{ width: "100%", maxWidth: "none" }}>
      <BottomNavigation
        showLabels
        value={option}
        onChange={(event, newValue) => {
          setOption(newValue);
        }}
      >
        <BottomNavigationAction label="kanban" icon={<ViewKanbanIcon />} />
        <BottomNavigationAction label="Favorites" icon={<FavoriteIcon />} />
        <BottomNavigationAction label="Archive" icon={<ArchiveIcon />} />
      </BottomNavigation>
      {option === 0 ? <KanbanConfig /> : null}
    </Container>
  );
}

export default Config;
