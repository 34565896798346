import * as React from "react";
import Header from "./Header";
import Processes from "../processes/Processes";

export default function LayoutProcesses() {
  return (
    <>
      <Header />
      <Processes />
    </>
  );
}
