import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Avatar,
  Badge,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  TextField,
} from "@mui/material";

import {
  addUser,
  addPeople,
  updateUser,
  updatePeople,
} from "../../services/backend/UserService";
import {
  setUser,
  addPeople as addPeopleToStore,
  addUser as addUserToStore,
  updatePeople as updatePeopleToStore,
  updateUsers as updateUserToStore,
} from "../../redux/slices/userSlice";
import UploadFile from "./UploadFile";
import ProgressUploadFile from "./ProgressUploadFile";
import UserPasswordModal from './UserPasswordModal';
import { debugLog } from "../../functions";

const UserProfile = ({
  editInfo,
  user,
  showPhoto,
  userType,
  onClose,
  onSubmit,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.value);
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [entity, setEntity] = useState("");
  const [dependence, setDependence] = useState("");
  const [level, setLevel] = useState("");
  const [position, setPosition] = useState("");
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [showUserPasswordModal, setShowUserPasswordModal] = useState(false);

  const handleFileUpload = (files) => {
    debugLog("files: ", files);
    setFile(files[0]);
  };

  const onUploadFileUrl = async (url, file) => {
    debugLog("url: ", url);
    try {
      const userData = { userId: user.userId, avatarUrl: url };
      await updateUser(userData, authUser);
      dispatch(setUser({ ...user, ...userData }));
    } catch (error) {
      debugLog("error: ", error);
    }
  };

  const handleClose = () => {
    if (onClose) onClose();
    setLoading(false);
    setName("");
    setLastName("");
    setEmail("");
    setEntity("");
    setDependence("");
    setLevel("");
    setPosition("");
    setLoading(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setLoading(true);

    const userData = {
      name,
      lastName,
      email,
      entity,
      dependence,
      level,
      position,
    };

    if (userType === "users") {
      userData.userId = user?.userId;
      if (user) {
        await updateUser(userData, authUser);
        dispatch(updateUserToStore(userData));
      } else {
        await addUser(userData, authUser);
        dispatch(addUserToStore(userData));
      }
    } else if (userType === "people") {
      userData.peopleId = user?.peopleId;
      if (user) {
        await updatePeople(userData, authUser);
        dispatch(updatePeopleToStore(userData));
      } else {
        await addPeople(userData, authUser);
        dispatch(addPeopleToStore(userData));
      }
    }

    if (onSubmit) onSubmit(userData);
    handleClose();
  };

  useEffect(() => {
    if (user) {
      setName(user.name);
      setLastName(user.lastName);
      setEmail(user.email);
      setEntity(user.entity);
      setDependence(user.dependence);
      setLevel(user.level);
      setPosition(user.position);
    }
  }, [user]);

  return (
    <Container maxWidth="md">
      <UserPasswordModal
        open={showUserPasswordModal}
        onClose={() => setShowUserPasswordModal(false)}
      />
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 2, mb: 2 }}>
        <Grid container spacing={1}>
          {showPhoto ? (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" alignItems="center">
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    <UploadFile
                      accept={"image/*"}
                      handleFileUpload={handleFileUpload}
                    />
                  }
                >
                  <Avatar
                    alt="Travis Howard"
                    src={user.avatarUrl}
                    sx={{ width: 100, height: 100 }}
                  />
                </Badge>
                <ProgressUploadFile
                  file={file}
                  onUploadFileUrl={onUploadFileUrl}
                />
              </Box>
            </Grid>
          ) : null}

          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Nombres"
                required
                value={name}
                disabled={!editInfo}
                onChange={(evt) => setName(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Apellidos"
                required
                value={lastName}
                disabled={!editInfo}
                onChange={(evt) => setLastName(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Correo electrónico"
                required
                value={email}
                disabled={!editInfo}
                onChange={(evt) => setEmail(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Entidad"
                value={entity}
                disabled={!editInfo}
                onChange={(evt) => setEntity(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <TextField
                label="Dependencia"
                value={dependence}
                disabled={!editInfo}
                onChange={(evt) => setDependence(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Nivel"
                value={level}
                disabled={!editInfo}
                onChange={(evt) => setLevel(evt.target.value)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth>
              <TextField
                label="Posición"
                value={position}
                disabled={!editInfo}
                onChange={(evt) => setPosition(evt.target.value)}
              />
            </FormControl>
          </Grid>
          {editInfo ? (
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ marginLeft: 1 }}
              >
                {loading ? <CircularProgress size={24} /> : "Guardar"}
              </Button>
            </Grid>
          ) : null}
          <Button
            variant="contained"
            sx={{ mt: 2, ml: 1 }}
            onClick={() => setShowUserPasswordModal(true)}
          >
            Cambiar contraseña
          </Button>
        </Grid>
      </Box>
    </Container>
  );
};

export default UserProfile;
