import React, { useEffect, useState } from "react";
import {
  AppBar, Box, Dialog, IconButton, Toolbar, Typography, Slide,
  CircularProgress
} from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DocumentPreviewModal = ({ open, flow, onClose }) => {
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(open);
  }, [open]);

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Documento: {flow?.subject}
          </Typography>
        </Toolbar>
      </AppBar>
      {flow && open
        && <>
          {loading && <Box textAlign='center' mt={1} ><CircularProgress size={40} sx={{ mr: 1 }} /></Box>}
          <iframe
            title="Previsualización de documento"
            src={`${process.env.REACT_APP_CONTAINER}/Document/handlePdfPreview?flowId=${flow.flowId}&token=${user.token}`}
            width="100%"
            height="100%"
            frameBorder={0}
            allowFullScreen={true}
            onLoad={() => setLoading(false)}
          />
        </>
      }
    </Dialog>
  );
};

export default DocumentPreviewModal;
