import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Dialog, FormControlLabel, Switch, Typography } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import CircularProgress from "../generic/CircularProgress";
import ProcessesCard from '../generic/ProcessesCard';
import { deleteProcess, getProcesses, getProcessesTemplates } from "../../services/backend/ProcessService";
import { setProcessTemplates } from "../../redux/slices/processSlice";
import { PERMISSIONS } from "../../constants";

const ProcessesListModal = ({ open, onClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const processesTemplate = useSelector((state) => state.process.processTemplates);
  const [processes, setProcesses] = useState();
  const [showPublic, setShowPublic] = useState(true);

  const handleCardClick = async (process, action) => {
    switch (action) {
      case "config":
        if (user.permissions.includes(PERMISSIONS.PROCESS.INSTANCE)) {
          if (!processesTemplate) return;
          const template = processesTemplate.find(pt => pt.processTemplateId === process.processTemplateId)
          if(template.legacy) {}
          navigate(`/process/${process.processId}`);
        }
        break;
      case "delete":
        if (user) {
          const responseJSON = await deleteProcess(process.processId, user);
          if (responseJSON.result) {
            // setInfoSnack({ open: true, message: "Flow Eliminado con éxito" });
            let newProcesses = [...processes];
            const index = newProcesses.findIndex((process1) => {
              return process1.processId === process.processId;
            });
            if (index > -1) {
              newProcesses.splice(index, 1);
              setProcesses(newProcesses);
            }
          }
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    (async () => {
      if (open && user && !processes) {
        if (!processesTemplate) {
          getProcessesTemplates({ filter: "ALL" }, user).then(
            (response) => dispatch(setProcessTemplates(response))
          );
        }

        const body = {
          filter: "FIELD",
          label: "userId",
          value: user.userId,
        };
        const responseJSON = await getProcesses(body, user);
        setProcesses(responseJSON);
      }
    })();
  }, [open]); // eslint-disable-line

  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      fullWidth
      maxWidth='xl'
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          <Typography variant='h4' marginY={3}>Procesos disponibles</Typography>
          <FormControlLabel sx={{ ml: 3 }} control={
            <Switch defaultChecked onChange={(evt) => setShowPublic(evt.target.checked)} />
          } label="Mostrar procesos públicos" />
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
          {!processes && <CircularProgress />}
          {processes && processes
              .filter((process) => process.userId === user.userId || showPublic || !process.isPublic)
              .map((process, idx) => (
            <Box key={idx} margin={2}>
              <ProcessesCard process={process} onCardClick={handleCardClick} />
            </Box>
          ))}
        </Box>
      </Box>
    </Dialog>
  );
};

export default ProcessesListModal;
