import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "expedient",
  initialState: {
    searchText: '',
    expedients: undefined,
    expedientTypes: undefined
  },
  reducers: {
    setSearch: (state, action) => {
      state.searchText = action.payload.searchText;
      state.expedients = action.payload.expedients;
    },
    setExpedientTypes: (state, action) => {
      state.expedientTypes = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setSearch, setExpedientTypes } = userSlice.actions;

export default userSlice.reducer;
