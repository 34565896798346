import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Container, Box, Typography, Button, Grid
} from "@mui/material";

import { getProcessesTemplate } from "../../services/backend/ProcessTemplateService";
import ProcessTemplateItem from './actions/ProcessTemplateItem';
import MyBackdrop from "../generic/MyBackdrop";
import { useNavigate } from "react-router-dom";
import { PERMISSIONS } from "../../constants";

const ProcessesTemplate = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [templates, setTemplates] = useState();

  useEffect(() => {
    if (user) {
      if (!user.permissions.includes(PERMISSIONS.TEMPLATE.LIST)) return navigate('/home');
      (async () => {
        const response = await getProcessesTemplate(user);
        setTemplates(response);
      })();
    }
  }, [user, navigate])

  return (
    <Container maxWidth={false}>
      <MyBackdrop open={!templates} />
      <Grid container sx={{ m: 2 }}>
        <Grid item xs={9}>
          <Typography variant="h4">Lista de <i>Plantillas de procesos</i></Typography>
        </Grid>
        <Grid item xs={3}>
          {user.permissions.includes(PERMISSIONS.TEMPLATE.CREATE) ? (
            <Button variant="contained" onClick={() => navigate("/processesTemplate/new")}>
              Crear nueva Plantilla
            </Button>
          ) : null}
        </Grid>
      </Grid>
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {templates?.map((template) => (
          <ProcessTemplateItem key={template.processTemplateId} { ...template} />
        ))}
      </Box>
    </Container>
  );
};

export default ProcessesTemplate;
