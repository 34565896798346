import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box, Button, CircularProgress, Container, Typography,
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper,
  TextField,
  Pagination
} from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';

import { getContracts } from "../../services/backend/ContractService";
import { PERMISSIONS } from "../../constants";
import ContractItem from "./ContractItem";

const PAGESIZE = 10;

const ContractList = ({ userType }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [contracts, setContracts] = useState();
  const [searchText, setSearchText] = useState('');
  const [page , setPage] = useState(0);

  const maxPages = useMemo(() => Math.ceil((contracts?.length || 0) / PAGESIZE), [contracts]);

  const filteredContract = useMemo(() => {
    if (!searchText) return contracts?.filter((__, idx) => idx >= page * PAGESIZE && idx < (page + 1) * PAGESIZE);
    return contracts?.filter((cont) => cont.contractCode.toLowerCase().includes(searchText.toLocaleLowerCase())
      || cont.supervisors?.map(s => `${s.name} ${s.lastName}`.toLowerCase()).join(' ').includes(searchText.toLowerCase())
      || `${cont.contractor?.name} ${cont.contractor?.lastName} ${cont.contractorNuip}`.toLowerCase().includes(searchText.toLowerCase())
    );
  }, [contracts, searchText, page]);

  const getContractsAsync = async () => {
    const response = await getContracts(user, userType);
    setContracts(response);
  };

  useEffect(() => {
    if ((!userType && !user.permissions.includes(PERMISSIONS.CONTRACT.LIST))
      || (userType === 'supervisor' && !user.permissions.includes(PERMISSIONS.SUPERVISOR_CONTRACT.LIST))
      || (userType === 'contractor' && !user.permissions.includes(PERMISSIONS.CONTRACTOR.LIST))) {
        navigate('/');
        return;
      }
    getContractsAsync();
  }, []); // eslint-disable-line

  return (
    <Container maxWidth="lg" style={{ padding: 3, marginTop: 10 }}>
      <Box sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5,
      }}>
        <Typography variant="h4">
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            style={{ margin: 16 }}
            onClick={() => navigate('/')}
          >
            Volver
          </Button>
          Contratos
        </Typography>
        {!userType && user.permissions.includes(PERMISSIONS.CONTRACT.CREATE)
          && <Button variant="contained" onClick={() => navigate('/contracts/new')}>
            Crear Nuevo Contrato
          </Button>
        }
      </Box>

      <TextField
        label="Buscar contrato"
        onChange={(evt) => {
          setSearchText(evt.target.value);
        }}
        sx={{ mb: 3 }}
      />

      {contracts ? (
        contracts.length > 0 ? (
          <>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="Actions">
                <TableHead>
                  <TableRow>
                    <TableCell>Código del contracto</TableCell>
                    <TableCell>Supervisor</TableCell>
                    <TableCell>Contratista</TableCell>
                    <TableCell>Objeto del contrato</TableCell>
                    <TableCell>Duración</TableCell>
                    <TableCell>Acciones</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredContract.map((contract) => (
                    <ContractItem
                      key={contract.contractCode}
                      contract={contract}
                      userType={userType}
                    />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            {maxPages > 1
              && <Pagination
                sx={{ justifyContent: 'center', mt: 1 }}
                count={maxPages}
                // page={page}
                onChange={(__, value) => setPage(value - 1)}
              />
            }
          </>
        ) :(
          <Typography>No tienes ningún contracto registado</Typography>
        )
      ) : (
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <CircularProgress size={36} />
        </Box>
      )}
    </Container>
  )
};

export default ContractList;
