import React, { useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import { storage } from "../../config/ConfigFirebase";
import MySnackbar from "./MySnackbar";
import { debugLog } from "../../functions";

const ProgressUploadFile = (props) => {
  const [progress, setProgress] = useState();
  const [file, setFile] = useState();
  const [infoSnack, setInfoSnack] = useState({ open: false });

  useEffect(() => {
    let file = props.file;
    if (file) {
      if (props.pos) {
        file.pos = props.pos;
      }
      setFile(file);
      const fileName = String(Date.now());
      const storageRef = ref(storage, `/files/${fileName}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      var unsubscribe = uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
          setProgress(progress);
        },
        (error) => {
          debugLog(error);
        },
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(function (downloadURL) {
            setInfoSnack({
              open: true,
              message: "Archivo guardado Correctamente",
            });
            props.onUploadFileUrl(downloadURL, file);
            setFile(null);
          });
        }
      );

      return () => {
        unsubscribe();
      };
    }
  }, [props.file]); // eslint-disable-line

  return (
    <div>
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      {file ? (
        <Box
          display="flex"
          m={1}
          p={1}
          bgcolor="background.paper"
          flexWrap="wrap"
        >
          <Box fontSize="h7.fontSize" m={1}>
            {file.name}
          </Box>
          <Box position="relative" display="inline-flex">
            <CircularProgress
              color="primary"
              variant="determinate"
              {...progress}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography
                variant="caption"
                component="div"
                color="textSecondary"
              >
                {`${Math.round(progress)}%`}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProgressUploadFile;
