import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Avatar,
  Fab,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,  
  ListItemText
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import FolderIcon from "@mui/icons-material/Folder";
import CircularProgress from "../generic/CircularProgress";
import AddKanbanDialog from "./AddKanbanDialog";
import { addkanban, getkanbans } from "../../services/backend/TaskService";
import { debugLog } from "../../functions";

function KanbanConfig() {
  const user = useSelector((state) => state.user.value);
  const [kanbanBoards, setKanbanBoards] = useState(null);
  const [openAddKanban, setOpenAddKanban] = useState({ open: false });

  const handleAddKanban = async (status, data) => {
    debugLog("handleAddKanban: ", status);
    debugLog("handleAddKanban: ", data);
    setOpenAddKanban({ open: false });
    if (status) {
      const addKanban = await addkanban(data, user);
      if (addKanban.result) {
        handlegetKanbans();
      }
    }
  };

  const handlegetKanbans = async () => {
    const filter = {
      filter: "ALL",
    };
    const newKanbans = await getkanbans(filter, user);

    debugLog("newKanbans: ", newKanbans);
    if (newKanbans.result) {
      setKanbanBoards(newKanbans.kanbans);
    }
  };

  useEffect(() => {
    handlegetKanbans();
  }, []); // eslint-disable-line

  return (
    <>
      <Fab size="small" color="primary" aria-label="add">
        <AddIcon onClick={() => setOpenAddKanban({ open: true })} />
      </Fab>
      {kanbanBoards ? (
        <List>
          {kanbanBoards.map((kanban, ids) => (
            <ListItem
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <DeleteIcon />
                </IconButton>
              }
            >
              <ListItemAvatar>
                <Avatar>
                  <FolderIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText
                primary={kanban.name}
                secondary={kanban.description}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <CircularProgress />
      )}
      <AddKanbanDialog
        dialogInfo={openAddKanban}
        handleClose={handleAddKanban}
      />
    </>
  );
}

export default KanbanConfig;
