import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Card,
  CardActionArea,
  CardContent,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PublicIcon from '@mui/icons-material/Public';

import { debugLog } from "../../functions";
import { PERMISSIONS } from "../../constants";

const ProcessesCard = ({ process, onCardClick }) => {
  const user = useSelector((state) => state.user.value);
  const [menuState, setMenuState] = useState(null);

  const handleDelete = () => {
    debugLog("Eliminando tarjeta con índice:");
    onCardClick(process, "delete");
    setMenuState(null);
  };

  const cardStyle = {
    width: "60mm",
    height: "84mm",
    margin: "auto",
    boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between", // Asegura que los elementos se alineen al inicio y al final
    transition: "background-color 0.3s", // Suaviza la transición del color de fondo
  };

  const actionAreaStyle = {
    height: "100%", // Hace que CardActionArea ocupe toda la altura de la tarjeta
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  };

  // Ajuste de estilo para que el título aparezca en dos líneas
  const titleStyle = {
    fontSize: "4.5mm",
    fontWeight: "bold",
    lineHeight: "1.2", // Aumenta el interlineado para que las dos líneas no estén muy juntas
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "flex", // Usa flex para permitir el control del espacio interno
    flexDirection: "column", // Organiza los elementos secundarios en una columna
    justifyContent: "center", // Centra el texto verticalmente
    alignItems: "center", // Centra el contenido horizontalmente
    textAlign: "center", // Asegura que el texto esté centrado horizontalmente
    height: "9mm", // Establece una altura fija que pueda contener dos líneas
    margin: "2mm 0", // Agrega margen superior e inferior si es necesario
    padding: "0 1mm", // Asegúrate de tener algo de espacio en los lados
    whiteSpace: "pre-wrap", // Permite que el texto se ajuste y respete los saltos de línea
    wordWrap: "break-word", // Asegura que las palabras se rompan si es necesario
  };
  const bodyTextStyle = {
    fontSize: "4mm",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  // Asegúrese de que el footer esté pegado a la parte inferior de la tarjeta
  const footerStyle = {
    position: "absolute", // Posicionamiento absoluto para pegarlo al fondo
    fontWeight: "bold",
    bottom: "1mm", // Espacio desde el borde inferior de la tarjeta
    left: "0",
    right: "0",
    display: "flex",
    justifyContent: "space-between", // Espaciado entre fecha y código
    alignItems: "center", // Centra los elementos verticalmente
    fontSize: "3.5mm", // Ajustar el tamaño de fuente si es necesario
    padding: "0 1mm", // Añade padding horizontal para evitar desbordamiento
  };

  const handleMenuOpen = (event, newProcess) => {
    setMenuState(event.currentTarget);
    event.stopPropagation(); // Evitar que se propague el evento de clic
  };

  return (
    <>
      <Card style={cardStyle}>
        <CardActionArea
          style={actionAreaStyle}
          onMouseOver={(event) =>
            (event.currentTarget.style.backgroundColor = "#f5f5f5")
          } // Color cuando el mouse está sobre la tarjeta
          onMouseOut={(event) =>
            (event.currentTarget.style.backgroundColor = "")
          } // Vuelve al color original
          onClick={() => onCardClick(process, "config")} // Dispara un evento al hacer clic
        >
          <CardContent style={{ position: "relative", height: "100%", width: "80%" }}>
            {/* Icono del menú en la parte superior derecha de la tarjeta */}
            <IconButton
              style={{ position: "absolute", top: "4px", right: "4px" }}
              onClick={(e) => {
                e.stopPropagation(); // Evita que el clic se propague al CardActionArea
                handleMenuOpen(e, process);
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Typography style={titleStyle}>{process?.processName}</Typography>
            <Typography variant="body2" style={bodyTextStyle}>
              {process?.description}
            </Typography>
            <div style={footerStyle}>
              <Typography>{process?.qualityCode}</Typography>
              {process.isPublic && <PublicIcon color='success' />}
            </div>
          </CardContent>
        </CardActionArea>
      </Card>
      <Menu
        anchorEl={menuState}
        open={Boolean(menuState)}
        onClose={(evt) => setMenuState(null)}
      >
        {/*<MenuItem onClick={handleConfigure}>Configurar</MenuItem>
         <MenuItem onClick={handleInit}>Iniciar</MenuItem> */}
        <MenuItem
          onClick={handleDelete}
          disabled={!user.permissions.includes(PERMISSIONS.PROCESS.DELETE) || process.userId !== user.userId}
        >
            Eliminar
        </MenuItem>
      </Menu>
    </>
  );
};

export default ProcessesCard;
