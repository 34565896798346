import React from "react";

import Header from "./Header";
import Process from "../processes/Process";

export default function LayoutViewProcess() {
  return (
    <>
      <Header />
      <Process />
    </>
  );
}
