import { getAuth } from "firebase/auth";
import moment from "moment";

import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";
import { server } from "../../config/ConfigFirebase";
import { debugLog } from "../../functions";

const handleContract = async (token, body) => {
  const response = await fetch("https://" + server + ".cloudfunctions.net/handleContract", {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (response.status === 401) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
  }

  return response;
};

export const addContract = async(contract, user) => {
  try {
    const userBody = { action: "CREATE_CONTRACT", data: contract };
    let response = await handleContract(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getContract = async(contractCode, user) => {
  try {
    const userBody = { action: "GET_CONTRACT", contractCode, userId: user.userId };
    let response = await handleContract(user.token, userBody);
    response = await response.json();
    if (response.result) {
      const contract = {
        ...response.body,
        approvedAt: moment(new Date(response.body.approvedAt)),
        finishedAt: moment(new Date(response.body.finishedAt)),
        startedAt: moment(new Date(response.body.startedAt)),
      }
      if (contract.payments) {
        Object.keys(contract.payments).forEach((key) => {
          contract.payments[key].payAt = moment(new Date(contract.payments[key].payAt));
        });
      }
      return contract;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const updateContract = async(contractCode, data, user) => {
  try {
    const userBody = { action: "UPDATE_CONTRACT", contractCode, data };
    let response = await handleContract(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getContracts = async(user, userType) => {
  try {
    let userBody

    if (!userType) {
      userBody = { action: "GET_CONTRACTS" };
    } else {
      userBody = { action: "GET_CONTRACTS", userType, userId: user.userId };
    }

    let response = await handleContract(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getContractsByField = async(field, value, user) => {
  try {
    const userBody = { action: "CONTRACTS_BY_FIELD", field, value};
    let response = await handleContract(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
