import {
  ViewType, RichEditUnit,
  RibbonButtonItem,
} from 'devexpress-richedit';

const configOptions = {
  setOptions: (options, itemEvents) => {
    options.unit = RichEditUnit.Inch;
    options.view.viewType = ViewType.PrintLayout;
    options.view.simpleViewSettings.paddings = {
        left: 15,
        top: 15,
        right: 15,
        bottom: 15,
    };

    const ribbonTabFile = options.ribbon.getTab(0);
    const ribbonTabHome = options.ribbon.getTab(1);
    const ribbonTabInsert = options.ribbon.getTab(2);
    const ribbonTabPageLayout = options.ribbon.getTab(3);
    options.ribbon.removeTab(4); // reference
    const ribbonTabMailMerge = options.ribbon.getTab(5);
    options.ribbon.removeTab(6); // view
    const ribbonTabDesign = options.ribbon.getTab(9);
    const ribbonTabLayout = options.ribbon.getTab(8);

    ribbonTabFile.title = 'Archivo';
    ribbonTabMailMerge.title = 'Etiquetas';
    ribbonTabHome.title = 'Inicio';
    ribbonTabInsert.title = 'Insertar';
    ribbonTabPageLayout.title = 'Presentación';
    ribbonTabDesign.title = 'Diseño';
    ribbonTabLayout.title = 'Tablas';

    ribbonTabFile.getItem(412).text = 'Nuevo';
    ribbonTabFile.getItem(410).text = 'Abrir';
    ribbonTabFile.getItem(413).text = 'Guardar';
    ribbonTabFile.getItem(414).text = 'Descargar';
    ribbonTabFile.getItem(416).text = 'Imprimir';
    ribbonTabHome.getItem(6).text = 'Deshacer escritura';
    ribbonTabHome.getItem(7).text = 'Repetir escritura';
    ribbonTabHome.getItem(8).text = 'Pegar';
    ribbonTabHome.getItem(9).text = 'Copiar';
    ribbonTabHome.getItem(10).text = 'Cortar';
    ribbonTabInsert.getItem(55).text = 'Tabla';
    ribbonTabInsert.getItem(411).text = 'Imagen';
    ribbonTabInsert.getItem(58).text = 'Marcador';
    ribbonTabInsert.getItem(59).text = 'Hipervínculo';
    ribbonTabInsert.getItem(220).text = 'Encabezado';
    ribbonTabInsert.getItem(221).text = 'Pie de página';
    ribbonTabInsert.getItem(235).text = 'Número de página';
    ribbonTabInsert.getItem(236).text = 'Total de páginas';
    ribbonTabInsert.getItem(368).text = 'Caja de texto';
    
    ribbonTabPageLayout.getItem(434).text = 'Márgenes';
    ribbonTabPageLayout.getItem(435).text = 'Orientación';
    ribbonTabPageLayout.getItem(436).text = 'Tamaño';
    ribbonTabPageLayout.getItem(437).text = 'Columnas';
    ribbonTabPageLayout.getItem(438).text = 'Salto de página';

    ribbonTabMailMerge.getItem(214).text = 'Insertar etiqueta';
    ribbonTabMailMerge.getItem(213).text = 'Ver datos de las etiquetas';
    ribbonTabMailMerge.getItem(200).text = 'Actualizar datos de las etiquetas';

    ribbonTabFile.removeItem(412);
    ribbonTabFile.removeItem(410);
    ribbonTabInsert.removeItem(220);
    ribbonTabInsert.removeItem(221);
    ribbonTabMailMerge.removeItem(442);
    ribbonTabMailMerge.removeItem(186);
    ribbonTabMailMerge.removeItem(187);
    ribbonTabMailMerge.removeItem(209);
    ribbonTabMailMerge.removeItem(210);
    ribbonTabMailMerge.removeItem(211);
    ribbonTabMailMerge.removeItem(212);
    ribbonTabMailMerge.removeItem(216);

    if (itemEvents?.openTagModalEvent) {
      const ribbonOpenTagsModalButton = new RibbonButtonItem(
        "openTagModal",
        "Llenar etiquetas",
        { icon: "bookmark", showText: true, beginGroup: true }
      );
      ribbonTabMailMerge.insertItem(ribbonOpenTagsModalButton, 3);
    }

    if (itemEvents?.openPreviewModalEvent) {
      const ribbonOpenPreviewModalButton = new RibbonButtonItem(
        "openPreviewModal",
        "Previsualizar",
        { icon: "doc", showText: true, beginGroup: true }
      );
      ribbonTabFile.insertItem(ribbonOpenPreviewModalButton, 4);
    }

    options.events.customCommandExecuted = (`function(s, e) {
      switch (e.commandName) {
        case 'openTagModal':
          s.royal.events.openTagModalEvent();
          break;
        case 'openPreviewModal':
          s.royal.events.openPreviewModalEvent();
          break;
      }
    }`);
  },
  setEvents: (options, nameEvent, event) => {
    options.events[nameEvent] = event;
    //  options.events.activeSubDocumentChanged = () => { };
    //  options.events.autoCorrect = () => { };
    //  options.events.calculateDocumentVariable = () => { };
    //  options.events.characterPropertiesChanged = () => { };
    //  options.events.contentInserted = () => { };
    //  options.events.contentRemoved = () => { };
    //  options.events.documentChanged = () => { };
    //  options.events.documentFormatted = () => { };
    //  options.events.documentLoaded = () => { };
    //  options.events.gotFocus = () => { };
    //  options.events.hyperlinkClick = () => { };
    //  options.events.keyDown = () => { };
    //  options.events.keyUp = () => { };
    //  options.events.paragraphPropertiesChanged = () => { };
    //  options.events.lostFocus = () => { };
    //  options.events.pointerDown = () => { };
    //  options.events.pointerUp = () => { };
    //  options.events.saving = (s,e) => { };
    //  options.events.saved = () => { };
    //  options.events.selectionChanged = () => { };
  }
};

export default configOptions;
