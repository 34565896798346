import React from "react";
import { Grid, Box, Typography } from "@mui/material";
import QRCode from "react-qr-code";

const title = {
  internal: "COMUNICACIÓN INTERNA",
  external: "COMUNICACIÓN DESPACHADA",
  received: "COMUNICACIÓN RECIBIDA",
};

const LabelQR = React.forwardRef(({ filed }, ref) => (
  <Grid
    ref={ref}
    container
    alignItems="center"
    sx={{
      border: "thin solid black",
      display: "inline-flex",
      filter: filed.referenceCode ? "none" : "blur(3px)",
      height: 110,
      margin: "auto",
      marginRight: 1,
      verticalAlign: "bottom",
      width: 450,
      "@media print": {
        width: "20cm", // Asegura el ancho para la impresión
        height: "3.6cm", // Asegura la altura para la impresión
        border: "none", // Opcional: elimina el borde para la impresión
        boxShadow: "none", // Opcional: elimina sombras para la impresión
        marginTop: "2mm", // Añade un margen superior para la impresión

        //        margin: 0, // Elimina márgenes para la impresión
        padding: 0, // Elimina padding para la impresión
        whiteSpace: "nowrap", // Mantener el texto en una sola línea
        overflow: "hidden", // Ocultar el desbordamiento de texto
        textOverflow: "ellipsis", // Añadir "..." si el texto es demasiado largo
        maxWidth: "100%", // Asegurar que el texto no exceda el ancho de la etiqueta
        "& .adjustableText": {
          // Clase aplicada al texto para ajustar el tamaño
          fontSize: "22px", // Reducir el tamaño de la fuente para la impresión
          lineHeight: "1", // Ajustar la altura de línea si es necesario
          textAlign: "left", // Alinea el texto a la izquierda solo en impresión
          // Asegurar que el texto no exceda el ancho de la etiqueta
          maxWidth: "100%",
          overflow: "hidden", // Ocultar el desbordamiento de texto
          textOverflow: "clip", // Cortar cualquier texto que se desborde (alternativamente, puedes usar 'ellipsis' para puntos suspensivos)
        },
        "& .adjustableHoraRadicado": {
          // Clase aplicada al texto para ajustar el tamaño
          fontSize: "26px", // Reducir el tamaño de la fuente para la impresión
          lineHeight: "1", // Ajustar la altura de línea si es necesario
          textAlign: "left", // Alinea el texto a la izquierda solo en impresión
          // Asegurar que el texto no exceda el ancho de la etiqueta
          maxWidth: "100%",
          overflow: "hidden", // Ocultar el desbordamiento de texto
          textOverflow: "clip", // Cortar cualquier texto que se desborde (alternativamente, puedes usar 'ellipsis' para puntos suspensivos)
          padding: "10px 0px 10px 0px",
        },
        "& .qrCodePrint": {
          width: "3.5cm !important", // Fuerza el ancho solo en impresión
          height: "3.5cm !important", // Fuerza la altura solo en impresión
        },
      },
    }}
  >
    <Grid item xs={3} alignItems="center">
      <img
        src="https://www.tdea.edu.co/images/tdea/logos/logo-tdea-nuevo.png"
        alt="logo"
        width="100%"
      />
    </Grid>
    <Grid item xs={6}>
      <Box textAlign="center" sx={{ lineHeight: 1 }}>
        <Typography variant="subtitle2" className="adjustableText">
          {title[filed.type]}
        </Typography>
        <Typography variant="body2" className="adjustableText">
          Comunicaciones oficiales
        </Typography>
        <Typography variant="body2" className="adjustableHoraRadicado">
          No. {filed.referenceCode}
        </Typography>
        <Typography variant="caption" className="adjustableText">
          {filed.createdAt?.format("dddd, LL")}
        </Typography>
      </Box>
      <Grid container>
        <Grid item xs={6} textAlign="left">
          <Typography variant="caption" className="adjustableText">
            Hora {filed.createdAt?.format("hh:mm:ss a")}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign="right">
          <Typography variant="caption" className="adjustableHoraRadicado">
            Folios: {filed.folios}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    <Grid item xs={3} textAlign="center">
      <QRCode
        size={80}
        style={{ height: "auto", maxWidth: "85%", width: "85%" }}
        viewBox={`0 0 256 256`}
        value={`${location.origin}/filed/view/${filed.referenceId}`} // eslint-disable-line
        className="qrCodePrint" // Clase para aplicar estilos de impresión
      />
    </Grid>
  </Grid>
));

export default LabelQR;
