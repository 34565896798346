import React, { useState, useEffect } from "react";
// import { getAuth, updatePassword } from "firebase/auth";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  CircularProgress
} from "@mui/material";
import { changeUserPassword } from "../../services/auth/AuthServices";

const UserPasswordModal = ({ open, onClose }) => {
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [validatePassword, setValidatePassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const handleClose = ()  => {
    if (onClose) onClose();
    setLoading(false);
    setOldPassword('');
    setNewPassword('');
    setValidatePassword('');
    setError(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (!handleValidatePassword()) return;
    setLoading(true);
    
    await changeUserPassword(oldPassword, newPassword);

    setLoading(false);
    handleClose();
  };

  const handleValidatePassword = () => {
    if (newPassword !== validatePassword) {
      setError(true);
      return false;
    }

    setError(false);
    return true;
  };

  useEffect(() => {
    // const auth = getAuth();
  }, []);

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Cambiar Contraseña</DialogTitle>

      <Container maxWidth={false}>
        <Box component="form" onSubmit={handleSubmit} sx={{ m: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Contraseña actual"
                  type="password"
                  required
                  value={oldPassword}
                  onChange={(evt) => setOldPassword(evt.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  error={error}
                  label="Nueva contraseña"
                  type="password"
                  required
                  value={newPassword}
                  onChange={(evt) => setNewPassword(evt.target.value)}
                  onFocus={() => setError(false)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  error={error}
                  label="Otra vez nueva contraseña"
                  type="password"
                  required
                  value={validatePassword}
                  helperText={error && "Las contraseña deben coincidir"}
                  onChange={(evt) => setValidatePassword(evt.target.value)}
                  onFocus={() => setError(false)}
                  onBlur={handleValidatePassword}
                />
              </FormControl>
            </Grid>
            
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ marginLeft: 1 }}
              >
                {loading ? <CircularProgress size={24} /> : "Guardar"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};

export default UserPasswordModal;
