import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./slices/userSlice";
import correspondenceReduce from "./slices/correspondenceSlice";
import expedientReduce from "./slices/expedientSlice";
import processReduce from "./slices/processSlice";

export default configureStore({
  reducer: {
    user: userReducer,
    correspondences: correspondenceReduce,
    expedient: expedientReduce,
    process: processReduce
  },
});
