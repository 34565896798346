import React from "react";
import { TableRow, TableCell, Box } from "@mui/material";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const DocumentItem = ({ document, setIsOpenPreview }) => (
  <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
    <TableCell>
      <Box sx={{
        width: 50, height: 60, backgroundColor: 'lightgray', border: 'thin dashed gray', textAlign: 'center'
      }}>
        <img
          alt="preview"
          src={document.thumbUrl ?? '/pdf.png'}
          height={60}
          style={{ backgroundColor: 'white', maxWidth: 50, objectFit: 'contain' }}
          onClick={(evt) => {
            if (!document.thumbUrl) return;
            evt.stopPropagation();
            setIsOpenPreview(document.thumbUrl);
          }}
        />
      </Box>
    </TableCell>
    <TableCell>
      <Box>
        {document.documentName}
        <Box sx={{ display: 'block', color: '#AAA', fontSize: '.8em' }}>{document.documentType}</Box>
      </Box>
    </TableCell>
    <TableCell>
      {document.folios}
    </TableCell>
    <TableCell>
      <a href={document.documentUrl} target="_blank" rel="noreferrer">
        <OpenInNewIcon size={20} />
      </a>
    </TableCell>
  </TableRow>
);

export default DocumentItem;
