import React from "react";

import Header from "./Header";
import ProcessesTemplate from "../processes/ProcessesTemplate";

export default function LayoutViewProcessTemplate() {
  return (
    <>
      <Header />
      <ProcessesTemplate />
    </>
  );
}
