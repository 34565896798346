// ProcessMenu.jsx
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Box, Grid, Button, Typography } from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';

import ProcessesTemplateListModal from "./ProcessesTemplateListModal";
import MySnackbar from "../generic/MySnackbar";
import FlowList from "../flow/FlowList";
import ProcessesListModal from "./ProcessesListModal";

const Processes = () => {
  const navigate = useNavigate();
  const [openProcessTemplate, setOpenProcessTemplate] = useState(false);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [showProcessesListModal, setShowProcessesListModal] = useState(false);

  const handleCloseTemplateList = (status, templateSelected) => {
    setOpenProcessTemplate(false);
    if (status) {
      navigate(`/processTemplates/${templateSelected.processTemplateId}`);
    }
  };

  return (
    <Container sx={{ p: 2 }}>
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5,
      }}>
        <Typography variant='h4'>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            style={{ margin: 16 }}
            onClick={() => navigate(-1)}
          >
            Volver
          </Button>
          Lista de mis procesos
        </Typography>
        <Box>
          <Button
            sx={{ mr: 2 }}
            variant="contained"
            onClick={() => setShowProcessesListModal(true)}
          >
            Instanciar Proceso
          </Button>
          <Button
            variant="contained"
            onClick={() => setOpenProcessTemplate(true)}
          >
            Crear Proceso personalizado
          </Button>
        </Box>
      </Box>

      <Grid container spacing={1}>
        <FlowList />

        <ProcessesListModal
          open={showProcessesListModal}
          onClose={setShowProcessesListModal}
        />
        <ProcessesTemplateListModal
          open={openProcessTemplate}
          onClose={handleCloseTemplateList}
        />
        <MySnackbar
          info={infoSnack}
          handleClose={() => setInfoSnack({ open: false })}
        />
      </Grid>
    </Container>
  );
}

export default Processes;
