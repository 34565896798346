import React, { useMemo } from "react";
import {
  Container,
  Box,
  Dialog,
  DialogTitle,
  List,
  ListItem,
  ListItemButton,
  ListItemText
} from "@mui/material";

const AddTagModal = ({ open, tags, onClick, onClose }) => {
  const tagValues = useMemo(() => {
    const values = [];

    Object.keys(tags).forEach((key) => {
      if (tags[key].anchors) {
        values.push(...Object.keys(tags[key].anchors));
      } else {
        values.push(key);
      }
    });    
    return values;
  }, [tags]);

  const handleClose = ()  => {
    if (onClose) onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Añadir Tag</DialogTitle>

      <Container maxWidth={false}>
        <Box sx={{ m: 2, mb: 2 }}>
          <List>
            {tagValues.map((tag) => {
              return (
                <ListItem key={tag} disablePadding>
                  <ListItemButton>
                    <ListItemText
                      primary={tag}
                      onClick={() => onClick(tag)}
                    />
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>  
        </Box>
      </Container>
    </Dialog>
  );
};

export default AddTagModal;
