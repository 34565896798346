import { server } from "../../config/ConfigFirebase";

export async function createFiledThumb(user, body) {
  try {
    const response = await fetch(
      `https://${server}.cloudfunctions.net/handlePdfThumb`,
      {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${user.token}`
        }
      }
    );
    return await response.json();
  } catch (err) {
    console.log(err);
  }
}