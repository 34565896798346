import React, { useState, useEffect } from "react";
import { Box, Button, CircularProgress, Container, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import GavelIcon from '@mui/icons-material/Gavel';
import EngineeringIcon from '@mui/icons-material/Engineering';
import SchoolIcon from '@mui/icons-material/School';
import CastForEducationIcon from '@mui/icons-material/CastForEducation';
import ArrowBack from '@mui/icons-material/ArrowBack';

import { setExpedientTypes, setSearch } from "../../redux/slices/expedientSlice";
import { getExpedientTypes } from "../../services/backend/ExpedientService";
import DocumentTypeFormModal from "./ExpedientTypeFormModal";
import { PERMISSIONS } from "../../constants";

const getIcon = (name) => {
  switch (name) {
    case 'LocalLibraryIcon': return <LocalLibraryIcon color='primary' fontSize='large' />;
    case 'GavelIcon': return <GavelIcon color='primary' fontSize='large' />;
    case 'EngineeringIcon': return <EngineeringIcon color='primary' fontSize='large' />;
    case 'SchoolIcon': return <SchoolIcon color='primary' fontSize='large' />;
    case 'CastForEducationIcon': return <CastForEducationIcon color='primary' fontSize='large' />;
    default: return null;
  }
}

const ExpedientTypeList = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const expedientTypes = useSelector((state) => state.expedient.expedientTypes);
  const [expedientType] = useState();
  const [openForm, setOpenForm] = useState(false);

  const getExpedientTypesAsync = async () => {
    const response = await getExpedientTypes(user);
      if (response) dispatch(setExpedientTypes(response));
  };

  useEffect(() => {
    if (!user.permissions.includes(PERMISSIONS.EXPEDIENT.VIEW)) return navigate(`/home`);
    dispatch(setSearch({ searchText: '', expedients: undefined }));
    if (!expedientTypes) getExpedientTypesAsync();
  }, []); // eslint-disable-line

  return (
    <Container sx={{ p: 2 }}>
      <Box sx={{
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: 5,
      }}>
        <Typography variant='h4'>
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            style={{ margin: 16 }}
            onClick={() => navigate(-1)}
          >
            Volver
          </Button>
          Tipos de expedientes
        </Typography>
        {user.permissions.includes(PERMISSIONS.EXPEDIENT.CREATE_TYPE)
            && <Button variant="contained" onClick={() => setOpenForm(true)}>
            Crear nuevo tipo
          </Button>
        }
      </Box>

      <DocumentTypeFormModal
        open={openForm}
        expedientType={expedientType}
        onClose={() => setOpenForm(false)}
        onSubmit={() => getExpedientTypesAsync()}
      />      

      <Box sx={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        {expedientTypes?.length === 0 && 'No tienes acceso a ningún tipo de expediente'}
        {expedientTypes ? (
          expedientTypes.map((type) => (
            <Button
              key={type.expedientTypeId}
              onClick={() => navigate(`/expedients/${type.expedientTypeId}`)}
              sx={{
                alignItems: 'center',
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                cursor: 'pointer',
                display: 'flex',
                flexDirection: 'column',
                height: 150,
                justifyContent: 'center',
                margin: "auto",
                p: 2,
                transition: "background-color 0.3s",
                width: 200,
              }}
            >
              {getIcon(type.icon)}
              <Typography color='primary' sx={{ fontWeight: 'bold', mt: 2 }}>
                Expedientes {type.expedientTypeName}
              </Typography>
            </Button>
          ))
        ) :(
          <Box sx={{ textAlign: 'center', width: 1 }}>
            <CircularProgress />
          </Box>
        )}
      </Box>
    </Container>
  );
};

export default ExpedientTypeList;
