import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Avatar, Box, Button, Card, CardActionArea, CardHeader, CircularProgress,
  Container, IconButton, Menu, MenuItem, Typography
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ArrowBack from '@mui/icons-material/ArrowBack';

// import { getExpedientsByValue } from "../../services/backend/ExpedientService";
import ExpedientFormModal from "./ExpedientFormModal";
import { PERMISSIONS } from "../../constants";
import { getExpedients } from "../../services/backend/ExpedientService";

const ExpedientListOwn = () => {
  const navigate = useNavigate();  
  const user = useSelector((state) => state.user.value);
  const [expedients, setExpedients] = useState();
  const [expedientForm, setExpedientForm] = useState();
  const [openForm, setOpenForm] = useState(false);
  const [openMenu, setOpenMenu] = useState();

  const handleMenuOpen = (evt, expedient) => {
    evt.stopPropagation();
    setExpedientForm(expedient);
    setOpenMenu(evt.currentTarget);
  };

  const getExpedientTypesAsync = async () => {
    if (!user.permissions.includes(PERMISSIONS.EXPEDIENT.OWN)) navigate('/home');
    const response = await getExpedients(user, user.userId);
    setExpedients(response);
  };

  useEffect(() => {
    getExpedientTypesAsync();
  }, []); // eslint-disable-line

  return (
    <Container sx={{ p: 2 }}>
      <Box sx={{
        alignItems: "center",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 5,
      }}>
        <Typography variant="h4">
          <Button
            variant="outlined"
            startIcon={<ArrowBack />}
            style={{ margin: 16 }}
            onClick={() => navigate(-1)}
          >
            Volver
          </Button>
          Mis expedientes
        </Typography>
        {user.permissions.includes(PERMISSIONS.EXPEDIENT.CREATE)
          && <Button variant="contained" onClick={() => setOpenForm(true)}>
            Crear Nuevo Expediente
          </Button>
        }
      </Box>

      <ExpedientFormModal
        open={openForm}
        expedient={!!expedientForm}
        expedientTypeId={expedientForm?.expedientTypeId}
        // trd={expedientType?.trd}
        onClose={() => setOpenForm(false)}
      />

      <Menu
        anchorEl={openMenu}
        open={!!openMenu}
        onClose={() => {
          setOpenMenu(null);
          setExpedientForm(null);
        }}
      >
        <MenuItem onClick={() => setOpenForm(true)}>
          Editar
        </MenuItem>
      </Menu>

      {expedients?.length === 0 && <Typography>No se encontraron expedientes a tu nombre</Typography>}
      {expedients ? (
        <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
          {expedients.map((expedient) => (
            <Card
              key={expedient.expedientId}
              sx={{ cursor: 'pointer', m: 1, p: 1, width: 300 }}
            >
              <CardActionArea
                onClick={() => navigate(
                  `/expedients/${expedient.expedientTypeId}/${expedient.expedientId}`,
                  { state: { defaultExpedient: expedient } }
                )}
              >
                <CardHeader
                  avatar={<Avatar>R</Avatar>}
                  title={(
                    <Box sx={{ display: "flex" }}>
                      <Box sx={{ flex: 1 }}>{expedient.expedientId}</Box>
                      <IconButton onClick={(evt) => handleMenuOpen(evt, expedient)}>
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  )}
                />
                <Box>{expedient.description}</Box>
                <Box sx={{ textAlign: 'right', mt: 1 }}>
                  {expedient.createdAt}
                </Box>
              </CardActionArea>
            </Card>
          ))}
        </Box>
      ) : (
        <Box sx={{ textAlign: "center", width: 1 }}>
          <CircularProgress />
        </Box>
      )}
    </Container>
  );
};

export default ExpedientListOwn;
