import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Dialog, Grid, Button, Box, AppBar, Toolbar, IconButton, Typography, Slide, CircularProgress
} from "@mui/material";
import { Editor as EditorMce } from "@tinymce/tinymce-react";

import CloseIcon from '@mui/icons-material/Close';
import { doneActionFlow, getFlow } from "../../services/backend/FlowService";
import RegisterSignature from "./RegisterSignature";
import DialogConfirmation from "../generic/DialogConfirmation";
import { updateTask } from "../../services/backend/TaskService";
import MySnackbar from "../generic/MySnackbar";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TaskReview = ({ open, selectedTask, onClose }) => {
  const [flow, setFlow] = useState();
  const user = useSelector((state) => state.user.value);
  const [task, setTempTask] = useState();
  const [confirmInfo, setConfirmInfo] = useState(false);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [loading, setLoading] = useState(false);

  const handleSigned = async (signed) => {
    const action = { ...task.action };
    action.signed = signed;
    if (signed) {
      action.signedAt = new Date();
      action.status = "done";
    } else {
      action.signedAt = null;
      action.status = "pending";
    }
    setTempTask({ ...task, action });
  };

  const handleCloseConfirmDialogSign = async (result) => {
    setLoading(true);
    setConfirmInfo({ open: false });
    if (result) {
      const body = {
        taskId: task.taskId,
        action: task.action,
        stepId: "done",
        status: "done",
        doneAt: new Date(),
        signedAt: new Date(),
        signed: true,
      };

      await updateTask(body, user);
      const bodyFlow = {
        taskId: task.taskId,
        flowId: task.flowId,
        action: task.action,
      };
      const flowJSON = await doneActionFlow(bodyFlow, user);
      if (flowJSON.result) {
        setInfoSnack({ open: true, message: "Tarea firmada correctamente" });
        onClose();
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    (async () => {
      if (selectedTask) {
        setTempTask(selectedTask);
        const response = await getFlow({ filter: 'BY_ID', value: selectedTask.flowId }, user);
        setFlow(response[0]);
      } else {
        setFlow(undefined);
      }
    })();
  }, [selectedTask]); // eslint-disable-line

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <DialogConfirmation
        confirmInfo={confirmInfo}
        handleCloseDialog={handleCloseConfirmDialogSign}
      />
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      <AppBar sx={{ position: 'relative' }}>
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Completar Tarea: {selectedTask?.action.actionName} {selectedTask?.taskName}
          </Typography>
        </Toolbar>
      </AppBar>
      {flow ? (
        <Grid container sx={{ height: '100%' }}>
          <Grid item xs={4}>
            <Box sx={{ my: 3 }}>
              <RegisterSignature
                actionName={'Elaborar'}
                signed={task && task.action.signed}
                setSigned={handleSigned}
                edit={!loading}
              />
            </Box>
            <Box sx={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                disabled={loading || !task?.action.signed}
                onClick={() => setConfirmInfo({
                  open: true,
                  title: "¿Confirma la finalización de esta tarea?",
                })}
              >
                {loading ? <CircularProgress size={12} /> : 'Finalizar'}
              </Button>
            </Box>
          </Grid>
          <Grid item xs={8}>
            <EditorMce
              key="editor"
              disabled={true}
              selector="textarea"
              apiKey="dpwozqiuvyx46vkj7737jnwbe6z70m3p6r35z6fk8xt9f328"
              value={flow.document.content}
              init={{
                language: "es",
                allow_conditional_comments: true,
                visual: false,
                height: '100%',
                entity_encoding: "raw",
                menu: {
                  file: { title: "Inicio", items: "addpage" },
                },
                menubar: "file edit format royal",
              }}
            />
          </Grid>
        </Grid>
      ) : (
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <CircularProgress size={36} />
        </Box>
      )}
    </Dialog>
  );
};

export default TaskReview;
