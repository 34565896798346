import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Box, Button, Chip, Container, Stack, Typography } from "@mui/material";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  doneFiled,
  getCorrespondenceMigration,
} from "../../services/backend/CorrespondenceService";
import { uploadFile } from "../../services/backend/FileSystemService";
import CircularProgressTitle from "../generic/CircularProgressTitle";

export function MigrationList() {
  const user = useSelector((state) => state.user.value);
  const [files, setFiles] = useState(null);
  const [migrateData, setMigrateData] = useState(null);
  const [migrating, setMigrating] = useState(false);
  const [migrationEnd, setMigrationEnd] = useState(false);

  const getCorrespondenceId = (fileName) => {
    try {
      //getCreatedAt(fileName);
      if (fileName) {
        let correspondenceId = "";
        const fileNameArray = fileName.split("_");
        //CD_20240103-00063
        //['CR', '24', '04', '2022-00002', '78.pdf']
        const yearConsecutivo = fileNameArray[3].split("-");
        correspondenceId =
          fileNameArray[0] +
          "_" +
          yearConsecutivo[0] +
          fileNameArray[2] +
          getMonth(fileNameArray[1]) +
          "-" +
          yearConsecutivo[1];
        //2022-03-25
        const createdAt =
          yearConsecutivo[0] +
          "-" +
          fileNameArray[2] +
          "-" +
          Number(fileNameArray[1]);
        console.log("correspondenceId: ", correspondenceId);
        console.log("createdAt: ", createdAt);

        return { correspondenceId, createdAt };
      } else {
        return false;
      }
    } catch (error) {
      console.log("error: ", error);
      return false;
    }
  };
  const getMonth = (id) => {
    if (id.length === 1) return "0" + id;
    return id;
  };
  const handleRestart = async () => {
    setMigrating(false);
    setFiles(null);
    setMigrationEnd(false);
    setMigrateData(null);
  };
  const handleStartMigration = async () => {
    setMigrating(true);
    let fechaInicio = new Date().getTime();
    for (let index = 0; index < files.length; index++) {
      let migrateData1 = migrateData[index];
      if (migrateData1.correspondenceId) {
        const newMigrateData = [...migrateData];
        newMigrateData[index].migrating = true;
        setMigrateData(newMigrateData);
        let filed = {
          correspondenceId: migrateData1.correspondenceId,
          folios: 1,
        };
        const responseJSON = await getCorrespondenceMigration(
          user.token,
          filed,
          migrateData1.createdAt
        );
        if (responseJSON) {
          filed.filedId = responseJSON.referenceCode;
          filed.referenceId = responseJSON.referenceId;
          const fileUrl = await handleUploadFile(
            files[index],
            migrateData1.correspondenceId
          );
          if (fileUrl) {
            filed.fileUrl = fileUrl;
            await doneFiled(user.token, filed);
            const newMigrateData = [...migrateData];
            newMigrateData[index].done = true;
            setMigrateData(newMigrateData);
          }
        }
      }
    }
    setMigrationEnd(true);
    let fechaFin = new Date().getTime();
    var diff = fechaFin - fechaInicio;
    console.log("Duration: ", diff / 1000);
  };
  const handleUploadFile = async (localFile, correspondenceId) => {
    try {
      const metadata = {
        dato1: "dato1",
      };
      const bucket = process.env.REACT_APP_STORAGE_BUCKET_FILED;
      const token = user.token;
      const response = await uploadFile(
        token,
        localFile,
        correspondenceId,
        metadata,
        bucket,
        correspondenceId + ".pdf",
        true
      );
      return response.downloadUrl;
    } catch (error) {
      console.log("error: ", error);
      return null;
    }
  };

  const handleFileUpload = async (event) => {
    console.log("event.target.files: ", event.target.files);
    const newFiles = [...event.target.files];
    if (newFiles) {
      let newMigrateData = [];
      newFiles.forEach((file) => {
        const { correspondenceId, createdAt } = getCorrespondenceId(file.name);
        newMigrateData.push({
          correspondenceId: correspondenceId,
          createdAt: createdAt,
        });
      });
      setMigrateData(newMigrateData);
      console.log("newFiles: ", newFiles);
      setFiles(newFiles);
    }
  };

  useEffect(() => {}, []);

  return (
    <Container maxWidth="md">
      <Stack spacing={2} margin={10}>
        <label htmlFor="upload-image">
          <Button variant="contained" component="span" disabled={migrating}>
            3. Upload
          </Button>
          <input
            id="upload-image"
            multiple="multiple"
            hidden
            accept="application/pdf"
            type="file"
            onChange={handleFileUpload}
          />
        </label>
        {migrateData ? (
          <Box
            sx={{
              p: 1,
              display: "flex",
              border: "1px dashed grey",
              "&::-webkit-scrollbar": {
                width: 3,
              },
              "&::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                //backgroundColor: "orange",
              },
              "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,.1)",
                outline: "1px solid slategrey",
              },
            }}
          >
            <Stack spacing={0.5}>
              <Box sx={{ typography: "subtitle1" }}>
                {"Files para ser migrados : " + migrateData.length}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                {migrateData?.map((file, i) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box
                      sx={{
                        marginRight: 1,
                      }}
                    >
                      <PictureAsPdfIcon />
                    </Box>
                    <Box>
                      <Typography
                        sx={{ display: "inline", marginRight: 1 }}
                        component="span"
                        variant="body2"
                        color="text.primary"
                      >
                        {file.name}
                      </Typography>
                      {file.correspondenceId ? (
                        <Chip
                          label={file.correspondenceId}
                          color="success"
                          variant="outlined"
                        />
                      ) : (
                        <Chip
                          label="No Cumple el nombramiento"
                          color="error"
                          variant="outlined"
                        />
                      )}
                    </Box>
                    <Box
                      sx={{
                        marginLeft: 2,
                      }}
                    >
                      {file.migrating ? (
                        file.done ? (
                          <DoneAllIcon color="primary" />
                        ) : (
                          <CircularProgressTitle title="migrando..." />
                        )
                      ) : null}
                    </Box>
                  </Box>
                ))}
              </Box>
            </Stack>
          </Box>
        ) : null}
        {migrationEnd ? (
          <Button
            variant="contained"
            component="span"
            //disabled={migrating}
            onClick={handleRestart}
          >
            Reiniciar
          </Button>
        ) : (
          <Button
            variant="contained"
            component="span"
            disabled={migrating}
            onClick={handleStartMigration}
          >
            Iniciar Migración
          </Button>
        )}
      </Stack>
    </Container>
  );
}
