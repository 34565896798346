export const PERMISSIONS = {
  FILED: {
    LIST: 'filed.list', 
    CREATE: 'filed.create',
    READ: 'filed.read',
    UPDATE: 'filed.update',
    DELETE: 'filed.delete',
    CANCEL: 'filed.cancel'
  },
  REQUESTSFILED: {
    LIST: 'requestsFiled.list',
    CREATE: 'requestsFiled.create',
    READ: 'requestsFiled.read',
    UPDATE: 'requestsFiled.update',
    DELETE: 'requestsFiled.delete'
  },
  TASK: {
    LIST: 'task.list'
  },
  PROCESS: {
    LIST: 'process.list',
    CREATE: 'process.create',
    READ: 'process.read',
    UPDATE: 'process.update',
    DELETE: 'process.delete',
    INSTANCE: 'process.instance',
    READ_FLOW: 'process.read_flow',
    CANCEL_FLOW: 'process.cancel_flow',
    VIEW_INPROGRESS: 'process.view_inprogress'
  },
  EXPEDIENT: {
    VIEW: 'expedient.view',
    OWN: 'expedient.own',
    CREATE_TYPE: 'expedient.create_type',
    UPDATE_TYPE: 'expedient.update_type',
    DELETE_TYPE: 'expedient.delete_type',
    LIST: 'expedient.list',
    CREATE: 'expedient.create',
    READ: 'expedient.read',
    UPDATE: 'expedient.update',
    DELETE: 'expedient.delete',
    CREATE_DOCUMENT: 'expedient.create_document'
  },  
  CONTRACT: {
    LIST: 'contract.list',
    CREATE: 'contract.create',
    READ: 'contract.read',
    UPDATE: 'contract.update',
    DELETE: 'contract.delete'
  },
  SUPERVISOR_CONTRACT: {
    LIST: 'supervisor_contract.list',
    APPROVE: 'supervisor_contract.approve'
  },
  CONTRACTOR: {
    LIST: 'contractor.list',
    UPDATE: 'contractor.update'
  },
  REPORT: {
    LIST: 'report.list'
  },
  TEMPLATE: {
    LIST: 'template.list',
    CREATE: 'template.create',
    READ: 'template.read',
    UPDATE: 'template.update',
    DELETE: 'template.delete'
  },
  LAB: {
    LIST: 'lab.list'
  },
  CONFIG: {
    LIST: 'config.list'
  }
};

export const ROLES = {
  ADMIN: Object.keys(PERMISSIONS).map((k) => Object.values(PERMISSIONS[k])).flat(),
  ARCHIVER: [
    ...Object.values(PERMISSIONS.FILED).filter((p) => p !== 'filed.cancel'),
    ...Object.values(PERMISSIONS.REQUESTSFILED),
    ...Object.values(PERMISSIONS.TASK),
    ...Object.values(PERMISSIONS.PROCESS).filter((p) => ![
      'process.cancel_flow',
      'process.view_inprogress'
    ].includes(p)),
    ...Object.values(PERMISSIONS.EXPEDIENT),
    ...Object.values(PERMISSIONS.REPORT)
  ],
  USER: [
    ...Object.values(PERMISSIONS.REQUESTSFILED),
    ...Object.values(PERMISSIONS.TASK),
    ...Object.values(PERMISSIONS.PROCESS).filter((p) => ![
      'process.cancel_flow',
      'process.view_inprogress'
    ].includes(p)),
    PERMISSIONS.EXPEDIENT.OWN
  ],
  ADMIN_CONTRACT: [
    ...Object.values(PERMISSIONS.CONTRACT),
  ],
  SUPERVISOR_CONTRACT: [
    ...Object.values(PERMISSIONS.SUPERVISOR_CONTRACT),
    PERMISSIONS.CONTRACT.READ
  ],
  CONTRACTOR: [
    ...Object.values(PERMISSIONS.CONTRACTOR),
    PERMISSIONS.CONTRACT.READ
  ]
};

export const ARL = {
  L1: { value: "0.00522", text: "Riesgo I" },
  L2: { value: "0.01044", text: "Riesgo II" },
  L3: { value: "0.02436", text: "Riesgo III" },
  L4: { value: "0.04350", text: "Riesgo IV" }
};

export const SALARIO_MINIMO = 1300000;
export const UVT = 47065;

export const dependencies = [
  'Coordinación de Aseguramiento de la Calidad',
  'Dirección Administrativa y Financiera',
  'Dirección de Bienestar',
  'Dirección de Control Interno',
  'Dirección de Extensión',
  'Dirección de Internacionalización',
  'Dirección de Planeación',
  'Oficina de Comunicaciones',
  'Secretaría General',
  'Vicerrectoría Académica'
];
