import React, { useState } from "react";
import { Box, CircularProgress, Grid, IconButton, TextField, Typography, Link } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from '@mui/icons-material/Visibility';

import { DeleteFile, UploadFile } from "../../services/backend/UploadFileService";
import { genString } from "../../functions";

const ChargeAccountActivity = ({
  idx,
  title,
  description,
  annexes,
  contractCode,
  updateActivity,
  removeActivity,
  editing,
  readOnly,
  setEditing
}) => {
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);

  /**
   * Handle annexes upload
   */
  const handleUploadAnnexed = async (evt) => {
    setLoading(true);
    const file = evt.target.files[0];
    const ext = file.name.split('.').at(-1);
    const name = genString();

    const response = await UploadFile(
      user,
      file,
      process.env.REACT_APP_STORAGE_BUCKET_CONTRACTS,
      `${contractCode}/annexe_${name}.${ext}`
    );

    if (response?.result) {
      updateActivity(idx, { annexes: [ ...annexes, {
        id: `${name}.${ext}`,
        name: file.name,
        url: response.downloadUrl
      } ]});
    }

    setLoading(false);
  };

  /**
   * Handle annexe delete
   */
  const handleDeleteAnnexe = async (annIdx) => {
    const ann = [...annexes];
    const delAnn = ann.splice(annIdx, 1);
    updateActivity(idx, { annexes: ann });
    DeleteFile(
      user,
      process.env.REACT_APP_STORAGE_BUCKET_CONTRACTS,
      `${contractCode}/annexe_${delAnn[0].id}`
    );
  };

  return (
    <Grid container spacing={1} sx={editing ? {
      m: 1,
      p: 1,
      backgroundColor: "#ffeb3b0f",
      border: "thin dashed lightgray",
      position: "relative"
    } : undefined}>
      <Grid item xs={editing ? 8 : 12 } sx={{ pr: editing ? 1 : 0 }}>
        {editing
          && <IconButton
            onClick={() => setEditing(undefined)}
            sx={{
              position: "absolute",
              right: 0,
              top: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        }
        <Box style={{ display: "flex", alignItems: "center" }}>
          <Box sx={{
            color: "gray",
            mb: 1,
            mr: 1,
            p: 1,
            border: "thin solid lightgray",
            borderRadius: 2,
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "100%"
          }}>
            {title}
          </Box>
          {!editing
            && <>
              <IconButton onClick={() => setEditing(idx)}>
                {readOnly ? <VisibilityIcon /> : <EditIcon color={description ? "success" : "action"} />}
              </IconButton>
              {!readOnly
                && <IconButton onClick={() => removeActivity(idx)}>
                  <DeleteIcon />
                </IconButton>
              }
            </>
          }
        </Box>
        {editing
          && <TextField
            fullWidth
            multiline
            size="small"
            rows={3}
            placeholder="Ingresa la descripción de la actividad realizada"
            sx={{ backgroundColor: "white" }}
            value={description}
            InputProps={{ readOnly }}
            onChange={(evt) => updateActivity(idx, { description: evt.target.value })}
          />
        }
      </Grid>
      {editing
        && <Grid item xs={4} sx={{ borderLeft: "thin solid lightgray", pl: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography variant="h6">Anexos</Typography>
            {loading && <CircularProgress size={16} sx={{ ml: 2 }} />}
            {!readOnly && !loading
              && <label htmlFor="upload-annexed">
                <AttachFileIcon color='disabled' sx={{ ml: 2, cursor: "pointer" }} />
                <input
                  id="upload-annexed"
                  hidden
                  accept="*"
                  type="file"
                  onChange={handleUploadAnnexed}
                />
              </label>
            }
          </Box>
          <Box>
            {annexes?.map((annexe, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ maxWidth: '100%', overflow: 'hidden', textOverflow: 'ellipsis', flex: 1 }}>
                  <Link
                    component="a"
                    href={annexe.url}
                    target="_blank"
                  >
                    {annexe.name}
                  </Link>
                </Box>
                <IconButton onClick={() => handleDeleteAnnexe(idx)}>
                  <DeleteIcon size="small" />
                </IconButton>
              </Box>
            ))}
          </Box>
        </Grid>
      }
    </Grid>
  )
};

export default ChargeAccountActivity;
