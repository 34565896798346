import { createBrowserRouter, Navigate } from "react-router-dom";
import LayoutMyTasks from "./components/layout/LayoutMyTasks";
import LayoutUpload from "./components/layout/LayoutUpload";
import LayoutFiled from "./components/layout/LayoutFiled";
import LayoutFiledList from "./components/layout/LayoutFiledList";
import LayoutRequestsFiledList from "./components/layout/LayoutRequestsFiledList";
import LayoutHome from "./components/layout/LayoutHome";
import LayoutViewFiled from "./components/layout/LayoutViewFiled";

import Login from "./components/login/Login";
import LayoutConfig from "./components/layout/LayoutConfig";
import PasswordReset from "./components/login/PasswordReset";
import ProtectedRoute from "./services/security/ProtectedRoute";
import LayoutProcesses from "./components/layout/LayoutProcesses";
import LayoutViewProcess from "./components/layout/LayoutViewProcess";
import LayoutViewProcessTemplate from "./components/layout/LayoutViewProcessTemplate";
import LayoutViewProcessesTemplate from "./components/layout/LayoutViewProcessesTemplate";
import LayoutViewFlow from "./components/layout/LayoutViewFlow";
import LayoutTask from "./components/layout/LayoutTask";
import LayoutUserProfile from "./components/layout/LayoutUserProfile";
import LayoutExpedients from "./components/layout/LayoutExpedients";
import LayoutMigration from "./components/layout/LayoutMigration";
import LayoutReports from "./components/layout/LayoutReports";
import LayoutContracts from "./components/layout/LayoutContracts";
import LayoutRichEditor from "./components/layout/LayoutRichEditor";

export const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/",
    element: <ProtectedRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="/home" replace />,
      },
      {
        path: "/home",
        element: <LayoutHome />,
      },
      {
        path: "/processes",
        element: <LayoutProcesses />,
      },
    ],
  },
  {
    path: "/home",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/home",
        element: <LayoutHome />,
      },
    ],
  },
  {
    path: "/flow",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/flow/:flowId",
        element: <LayoutViewFlow />,
      },
    ],
  },
  {
    path: "/process",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/process/:processId",
        element: <LayoutViewProcess />,
      },
    ],
  },
  {
    path: "/processTemplates",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/processTemplates/:processTemplateId",
        element: <LayoutViewProcessTemplate instance={true} />,
      },
    ],
  },
  {
    path: "/processesTemplate",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/processesTemplate",
        element: <LayoutViewProcessesTemplate />,
      },
      {
        path: "/processesTemplate/:processTemplateId",
        element: <LayoutViewProcessTemplate />,
      },
    ],
  },
  {
    path: "/expedients",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/expedients",
        element: <LayoutExpedients types={true} />,
      },
      {
        path: "/expedients/own",
        element: <LayoutExpedients own={true} />,
      },
      {
        path: "/expedients/:expedientTypeId",
        element: <LayoutExpedients />,
      },
      {
        path: "/expedients/:expedientTypeId/:expedientId",
        element: <LayoutExpedients instance={true} />,
      },
    ],
  },
  {
    path: "/mytasks",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/mytasks",
        element: <LayoutMyTasks />,
      },
    ],
  },
  {
    path: "/task",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/task/:taskId",
        element: <LayoutTask />,
      },
    ],
  },
  {
    path: "/migration",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/migration",
        element: <LayoutMigration />,
      },
    ],
  },
  {
    path: "/filed",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/filed/:reference",
        element: <LayoutFiled />,
      },
      {
        path: "/filed",
        element: <LayoutFiled />,
      }
    ]
  },
  {
    path: "/filed/view/:hash",
    element: <LayoutViewFiled />,
  },
  {
    path: "/filedlist",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/filedlist",
        element: <LayoutFiledList />,
      },
      {
        path: "/filedlist/requests",
        element: <LayoutRequestsFiledList />,
      },
    ],
  },
  {
    path: "/reports",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/reports",
        element: <LayoutReports />,
      },
    ],
  },
  {
    path: "/contracts",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/contracts",
        element: <LayoutContracts list={true} />,
      },
      {
        path: "/contracts/:contractId",
        element: <LayoutContracts />,
      },
      {
        path: "/contracts/new",
        element: <LayoutContracts  />,
      },
      {
        path: "/contracts/supervisor",
        element: <LayoutContracts list={true} userType='supervisor' />,
      },
      {
        path: "/contracts/contractor",
        element: <LayoutContracts list={true} userType='contractor' />,
      },
      {
        path: "/contracts/:contractId/view",
        element: <LayoutContracts view={true}  />,
      }
    ],
  },
  {
    path: "/config",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/config",
        element: <LayoutConfig />,
      },
    ],
  },
  {
    path: "/labs",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/labs",
        element: <LayoutUpload />,
      },
    ],
  },
  {
    path: "/password-reset",
    element: <PasswordReset />,
  },
  {
    path: "/profile",
    element: <ProtectedRoute />,
    children: [
      {
        path: "/profile",
        element: <LayoutUserProfile />,
      },
    ],
  },
  {
    path: "/richeditor",
    element: <LayoutRichEditor />
  },
  {
    path: "*",
    element: <Navigate to="/home" replace />,
  }
]);
