import * as React from "react";
import Header from "./Header";
import TaskList from "../task/TaskList";

export default function LayoutMyTasks() {
  return (
    <>
      <Header />
      <TaskList />      
    </>
  );
}
