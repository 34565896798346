import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  CircularProgress,
  Box,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  FormControlLabel,
  Switch,
  TextField,
  Typography
} from "@mui/material";

import { getFlowsOnSnapshot } from "../../services/backend/FlowService";
import FlowItem from "./FlowItem";
import { PERMISSIONS } from "../../constants";

const FlowList = () => {
  const user = useSelector((state) => state.user.value);
  const [flows, setFlows] = useState();
  const [filter, setFilter] = useState({ inProgress: true, done: false, cancelled: false });
  const [searchText, setSearchText] = useState();
  const [showOthers, setShowOthers] = useState(false);

  useEffect(() => {
    const unsubscribe = getFlowsOnSnapshot(showOthers ? null : user.userId, setFlows);
    return () => unsubscribe();
  }, [user, showOthers]);

  return (
    <Box sx={{ my: 2, width: '100%' }}>
      <Box sx={{ alignItems: 'center', display: 'flex' }}>
        <TextField
          placeholder="Buscar flujo de proceso"
          value={searchText}
          onChange={(evt) => setSearchText(evt.target.value)}
        />
        <FormControlLabel sx={{ my: 3, ml: 3 }} control={
          <Switch defaultChecked onChange={(evt) => setFilter({ ...filter, inProgress: evt.target.checked })} />
        } label="En progreso" />
        <FormControlLabel sx={{ my: 3 }} control={
          <Switch onChange={(evt) => setFilter({ ...filter, done: evt.target.checked })} />
        } label="Completados" />
        <FormControlLabel sx={{ my: 3 }} control={
          <Switch onChange={(evt) => setFilter({ ...filter, cancelled: evt.target.checked })} />
        } label="Cancelados" />
        {user.permissions.includes(PERMISSIONS.PROCESS.VIEW_INPROGRESS)
          && <FormControlLabel sx={{ my: 3 }} control={
            <Switch onChange={(evt) => {
              setShowOthers(evt.target.checked);
              setFlows(undefined)
            }} />
          } label="Todos En progreso" />
        }
      </Box>

      {flows ? (
        flows.length > 0 ? (
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="Actions">
              <TableHead>
                <TableRow>
                  <TableCell>Nombre del proceso</TableCell>
                  <TableCell>Estado</TableCell>
                  <TableCell>Acciones</TableCell>
                  <TableCell>Creado en</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flows.filter((flow) => 
                  (
                    (filter.inProgress || flow.status !== 'inProgress')
                    && (filter.done || flow.status !== 'done')
                    && (filter.cancelled || flow.status !== 'cancelled')
                  )
                  && (!searchText || (flow.subject || flow.subjet).includes(searchText)))?.map((flow) => (
                  <FlowItem key={flow.flowId} flow={flow} showDone={filter.done} showOthers={showOthers} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) :(
          <Typography>No tienes ningún proceso instanciado</Typography>
        )
      ) : (
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <CircularProgress size={36} />
        </Box>
      )}
    </Box>
  );
};

export default FlowList;
