import { useMemo } from "react";
import { TableRow, TableCell, Avatar, Box, Tooltip } from "@mui/material";
import { useNavigate } from "react-router-dom";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import CircleIcon from '@mui/icons-material/Circle';
import VisibilityIcon from '@mui/icons-material/Visibility';


const TaskItem = ({ task, showDone, setTask }) => {
  const navigate = useNavigate();
  const canComplete = useMemo(() => !['elaborate', 'sign'].includes(task.action.type) && !task.signed, [task]);
  const owners = useMemo(() => task.actions?.map((action) => ({
    ...action.owners[0],
    actionName: action.actionName,
    signed: action.signed
  })), [task]);

  return (
    <TableRow
      key={task.action.actionName}
      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
    >
      <TableCell component="th" scope="row">
        <strong>{task.action.actionName}:</strong> {task.taskName}
        {process.env.NODE_ENV && <div><small>{task.taskId} : {task.flowId}</small></div>}
      </TableCell>
      <TableCell>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {owners?.map((owner, idx) => (
            <Tooltip key={idx} title={`${owner.actionName}: ${owner.name}`}>
              <Avatar
                alt={owner.name}
                style={{ width: "30px", height: "30px", marginRight: 5, filter: owner.signed ? 'none':'grayscale(1)' }}
                src={owner.avatarUrl || owner.name || 'AA'}
              />
            </Tooltip>
          ))}
        </Box>
      </TableCell>
      <TableCell>
        <VisibilityIcon
          sx={{ cursor: canComplete ? 'pointer' : 'initial' }}
          size={20}
          color={canComplete ? 'success' : 'disabled' }
          onClick={canComplete ? () => setTask(task) : undefined}
        />
      </TableCell>
      <TableCell>
        <OpenInNewIcon sx={{ cursor: 'pointer' }} size={20} onClick={() => navigate(`/task/${task.taskId}`)} />
      </TableCell>
      {showDone
        && <TableCell>
          <CircleIcon fontSize='small' sx={{ color: task.status === 'done' ? 'green' : 'gray' }} />
        </TableCell>
      }
    </TableRow>
  );
};

export default TaskItem;
