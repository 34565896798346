import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  addProcess,
  getProcesses,
  getProcessesTemplates,
} from "../../services/backend/ProcessService";
import { addFlow, updateFlow } from "../../services/backend/FlowService";
import ActionItem from "./actions/ActionItem";
import ProcessHeader from "./ProcessHeader";
import MySnackbar from "../generic/MySnackbar";
import MyBackdrop from "../generic/MyBackdrop";
import { setUsers } from "../../redux/slices/userSlice";
import { getReciversUser } from "../../services/backend/UserService";
import { debugLog } from "../../functions";
import { PERMISSIONS } from "../../constants";
import { copyFileInStorage } from "../../services/backend/EditorServices";

export default function Process() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { processId, processTemplateId } = useParams();
  const user = useSelector((state) => state.user.value);
  const users = useSelector((state) => state.user.users);
  const [process, setProcess] = useState(null);
  const [action, setAction] = useState({});
  const [openAddAction, setOpenAddAction] = useState(false);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const canUpdate = useMemo(() => processTemplateId || user?.userId === process?.userId, [user, process, processTemplateId]); 

  const handleSaveProcess = async (e) => {
    const tProcess = {...process};
    delete tProcess.template; // Using template from processTemplate
    const responseJSON = await addProcess(tProcess, user);
    if (responseJSON.result) {
      setInfoSnack({
        open: true,
        message: process.processId
          ? "Proceso guardado con éxito"
          : "Proceso creado con éxito",
      });
      navigate(`/processes`);
    }
  };

  const handleUpdateAction = (option, newAction) => {
    let newProcess = { ...process };
    switch (option) {
      case "update":
        newProcess.actions[newAction.index] = newAction;
        break;
      case "delete":
        newProcess.actions.splice(newAction.index, 1);
        break;

      default:
        break;
    }

    debugLog("newProcess: ", newProcess);
    setProcess(newProcess);
  };

  const handleChangeAction = (e) => {
    setAction({
      ...action,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddAction = async (result) => {
    debugLog("result: ", result);
    setOpenAddAction(!openAddAction);
    if (result) {
      const newAction = { actionName: action.actionName };
      let newProcess = { ...process };
      if (newProcess.actions) {
        newProcess.actions.push(newAction);
      } else {
        newProcess.actions = [newAction];
      }
      setProcess(newProcess);
    } else {
      setAction({});
    }

    debugLog("flow: ", process);
  };

  const handleGetProcessTemplate = async () => {
    if (user) {
      const body = {
        filter: "BY_ID",
        value: processTemplateId,
      };
      try {
        let responseJSON = await getProcessesTemplates(body, user);
        responseJSON[0].actions[0].owners = [user];
        setProcess(responseJSON[0]);
      } catch (error) {
        debugLog("Error: ", error);
      }
    }
  };

  const handleGetProcess = async () => {
    if (user) {
      const body = {
        filter: "BY_ID",
        value: processId,
      };
      const responseJSON = await getProcesses(body, user);
      if (user.userId !== responseJSON[0].userId && !responseJSON[0].isPublic) return navigate('/');
      setProcess(responseJSON[0]);
    }
  };

  const handleInitFlow = async () => {
    if (user) {
      setOpenBackdrop(true);
      const response = await getProcessesTemplates({ filter: 'BY_ID', value: process.processTemplateId}, user);
      const template = response[0].template;
      const flow = await addFlow({ ...process, template }, user);

      if (template.file?.url) {
        const url = await copyFileInStorage(template.file.url, `documents/flows/${flow.id}`);
        const file = {
          name: flow.id,
          path: `flows/${flow.id}`,
          url
        }
        updateFlow({ flowId: flow.id, document: { file } }, user);
      }

      setOpenBackdrop(false);
      navigate(`/processes`);
    }
  };

  useEffect(() => {
    if (processTemplateId) {
      if (!user.permissions.includes(PERMISSIONS.PROCESS.CREATE)) return navigate('/processes');
      handleGetProcessTemplate();
    } else if (processId) {
      if (!user.permissions.includes(PERMISSIONS.PROCESS.INSTANCE) || !user.permissions.includes(PERMISSIONS.PROCESS.UPDATE)) {
        return navigate('/processes');
      }
      handleGetProcess();
    }
  
    if (!users) {
      (async () => {
        const response = await getReciversUser(user.token);
        dispatch(setUsers(response.users));
      })();
    }
  }, []); // eslint-disable-line

  return (
    <Container maxWidth="md" style={{ padding: 3, marginTop: 10 }}>
      {process ? (
        <>
          <ProcessHeader
            editTemplate={false}
            editSubject={processTemplateId ? undefined : true}
            process={process}
            setProcess={setProcess}
          />
          <Grid container spacing={1} sx={{ mb: 2 }}>
            <Grid item xs={12}>
              {openAddAction ? (
                <Box
                  sx={{
                    p: 1,
                    border: "1px dashed grey",
                    "&::-webkit-scrollbar": {
                      width: 3,
                    },
                    "&::-webkit-scrollbar-track": {
                      "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.00)",
                      //backgroundColor: "orange",
                    },
                    "&::-webkit-scrollbar-thumb": {
                      backgroundColor: "rgba(0,0,0,.1)",
                      outline: "1px solid slategrey",
                    },
                  }}
                >
                  <Stack direction="row">
                    <TextField
                      id="actionName"
                      name="actionName"
                      label="Nombre de la Acción"
                      variant="outlined"
                      fullWidth
                      onChange={handleChangeAction}
                    />
                    <Stack direction="column">
                      <DoneIcon
                        color="primary"
                        onClick={(evt) => handleAddAction(true)}
                      />
                      <CloseIcon
                        color="primary"
                        onClick={(evt) => setOpenAddAction(!openAddAction)}
                      />
                    </Stack>
                  </Stack>
                </Box>
              ) : null}
            </Grid>
            <Grid item xs={12}>
              {process?.actions?.map((action, index) => (
                <ActionItem
                  key={action.actionName}
                  item={{ ...action, index }}
                  handleUpdateItem={handleUpdateAction}
                />
              ))}
            </Grid>
            {process.actions?.length === 1 ? (
              ""
            ) : (
              <>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row-reverse",
                    p: 1,
                    marginRight: 1,
                  }}
                >
                  <Button
                    variant="contained"
                    onClick={handleSaveProcess}
                    disabled={!canUpdate || !process?.description || process.subject}
                  >
                    {process.processId ? "Guardar Proceso" : "Crear Proceso"}
                  </Button>
                </Box>
                {process.processId && process.subject ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row-reverse",
                      p: 1,
                    }}
                  >
                    <Button variant="contained" onClick={handleInitFlow}>
                      Iniciar Proceso
                    </Button>
                  </Box>
                ) : null}
              </>
            )}
          </Grid>
        </>
      ) : (
        <CircularProgress />
      )}
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      <MyBackdrop open={openBackdrop} />
    </Container>
  );
}
