import React, { useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  Box, Chip, Dialog, Grid, IconButton, TableCell, TableRow, Tooltip, Avatar
} from "@mui/material";
import CircleIcon from '@mui/icons-material/Circle';

const FlowItem = ({ flow, showDone, showOthers }) => {
  const users = useSelector((state) => state.user.users);
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const flowUser = useMemo(() => users?.find(u => u.userId === flow.userId), [flow, users]);

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <img src={flow.filed?.thumbUrl} alt="preview" width={520} style={{ maxWidth: '100%' }} />
      </Dialog>
      <TableRow
        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
      >
        <TableCell>
          {flow.subject || flow.subjet}
        </TableCell>
        <TableCell>
          <Chip
            size="small"
            variant={flow.status === 'done' ? 'contained' : 'outlined'}
            label={flow.status === 'inProgress' ? 'En Progreso' : flow.status === 'done' ? 'Finalizado' : 'Cancelado'}
            color={flow.status === 'inProgress' || flow.status === 'done' ? 'success' : 'error'}
            onClick={() => navigate(`/flow/${flow.flowId}`)}
          />
        </TableCell>
        <TableCell>
          <Grid container spacing={1}>
            {flow.actions.map((action, idx) => (
              <Grid item key={idx} xs={12 / flow.actions.length} sx={{ textAlign: 'center' }}>
                <Tooltip title={(
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <Avatar
                      alt={action.owners[0]?.name}
                      style={{ width: "30px", height: "30px", marginRight: 5 }}
                      src={action.owners[0]?.avatarUrl || action.owners[0]?.name || 'AA'}
                    />
                    <Box sx={{ textTransform: 'capitalize' }}>
                      {action.owners[0]?.name} {action.owners[0]?.lastName}
                    </Box>
                  </Box>
                )}>
                  <Box><small>{action.actionName}</small></Box>
                </Tooltip>
                {action.tasks ? (
                  action.tasks?.map((task, idx) => (
                    <IconButton key={idx} onClick={() => {
                      if (task.taskId) navigate(`/task/${task.taskId}`);
                    }}>
                      <CircleIcon
                        fontSize='small'
                        sx={{
                          cursor: 'pointer',
                          color: task.status === 'done'
                            ? 'green' : task.status === 'pending'
                            ? 'yellow' : task.status === 'inProgress'
                            ? 'orange' : 'red'
                        }}
                      />
                    </IconButton>
                  ))
                ) : (
                  <IconButton>
                    <CircleIcon
                      fontSize='small'
                      sx={{ color: 'lightgrey' }}
                    />
                  </IconButton>
                )}
              </Grid>
            ))}
          </Grid>
        </TableCell>
        <TableCell>{flow.createdAt.format('YYYY-MM-DD HH:mm:ss')}</TableCell>
        {showDone
          && <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <CircleIcon fontSize='small' sx={{ color: flow.status === 'done' ? 'green' : 'gray' }} />
              {flow.filed?.thumbUrl
                && <img
                  alt="preview"
                  src={flow.filed?.thumbUrl}
                  style={{ width: 80, cursor: 'pointer' }}
                  onClick={() => setIsOpen(true)}
                />
              }
            </Box>
          </TableCell>
        }
        {showOthers
          && <TableCell>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Tooltip title={flowUser.name}>
              <Avatar alt={flowUser.name} src={flowUser.avatarUrl} />
            </Tooltip>
            </Box>
          </TableCell>
        }
      </TableRow>
    </>
  );
};

export default FlowItem;
