import * as React from "react";
import { useSelector } from "react-redux";
import Header from "./Header";
import UserProfile from "../generic/UserProfile";

export default function LayoutUserProfile() {
  const user = useSelector((state) => state.user.value);
  return (
    <>
      <Header />
      <UserProfile user={user} editInfo={false} showPhoto={true} />
    </>
  );
}
