import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Editor as EditorMce } from "@tinymce/tinymce-react";

import { updateFlow, createPDF } from "../../services/backend/FlowService";
import FormTagsModal from "../generic/FormTagsModal";
import TagChangeModal from "../generic/TagChangeModal";
import AddTagModal from "../generic/AddTagModal";
import MyBackdrop from "../generic/MyBackdrop";
import { Box } from "@mui/material";

export default function Editor({
  flow,
  disabled,
  mustFinish,
  setValidatedEditor,
  eHeight = "92vh"
}) {
  const navigate = useNavigate();
  const editorRef = useRef();
  const template = flow.template; //templates[flow.type];
  const user = useSelector((state) => state.user.value);
  const [value, setValue] = useState(
    flow.document?.content ?? template.content
  );
  const [tagValues, setTagValues] = useState(flow.document);
  const [tagToChange, setTagToChange] = useState();
  const [openAddTagModal, setOpenAddTagModal] = useState(false);
  const [saving, setSaving] = useState();
  const [openFormTagsModal, setOpenFormTagsModal] = useState(false);
  const [finishing, setFinishing] = useState(false);
  const [lastHeight, setLastHeight] = useState();
  const [savingLabel, setSavingLabel] = useState();

  const breakPage = (pagesDiv, currentPage, forward) => {
    const pages = pagesDiv.querySelectorAll(".page");
    if (!pages[currentPage]) return;

    const currentMain = pages[currentPage].querySelector(".maincontent");

    if (forward) {
      if (currentMain?.clientHeight > 800) {
        if (!pages[currentPage + 1]) {
          pagesDiv.innerHTML = `${pagesDiv.innerHTML}${template.newpage}`;
          breakPage(pagesDiv, currentPage, true);
          return;
        }

        while (currentMain?.clientHeight > 800) {
          pages[currentPage + 1]
            .querySelector(".maincontent")
            .prepend(currentMain.lastElementChild);
        }

        breakPage(pagesDiv, currentPage + 1, true);
      }
    } else {
      if (currentMain?.clientHeight < 800) {
        if (!pages[currentPage + 1]) return;
        // TODO: Avoid infinite recursive due to change in currentMain

        // while (currentMain?.clientHeight < 800) {
        //   currentMain.append(
        //     pages[currentPage + 1]
        //       .querySelector('.maincontent').firstElementChild
        //   )
        // }

        //   breakPage(pagesDiv, currentPage + 1, false);
      }
    }
  };

  const onChange = (val) => {
    if (disabled) return;

    const pagesDiv = document.createElement("div");
    pagesDiv.innerHTML = val;

    if (pagesDiv.innerHTML === value || !lastHeight) return;

    const pages = editorRef.current.dom.select(".page");
    let node = editorRef.current.selection.getNode();
    let currentPage;
    let currentHeight;

    while (node?.classList && !node.classList.contains("page")) {
      node =
        node === editorRef.current.dom.doc.body ? undefined : node.parentNode;
    }

    if (!node) return;
    setValidatedEditor(false);

    pages.forEach((page, idx) => {
      if (page === node) {
        currentPage = idx;
        currentHeight = page.querySelector(".maincontent").clientHeight;
      }
    });

    document.body.appendChild(pagesDiv);

    if (currentHeight === lastHeight) {
    } else if (currentHeight > lastHeight) {
      breakPage(pagesDiv, currentPage, true);
    } else {
      breakPage(pagesDiv, currentPage, false);
    }

    setValue(pagesDiv.innerHTML);
    pagesDiv.remove();

    clearTimeout(saving);
    setSavingLabel(undefined);
    setSaving(
      setTimeout(() => {
        onSave(pagesDiv.innerHTML);
        setSaving(undefined);
      }, 2000)
    );
  };

  const onPostProcess = (evt) => {
    const pagesDiv = document.createElement("div");
    pagesDiv.innerHTML = evt.content;
    evt.content = pagesDiv.innerHTML;
  };

  const onSave = async (value, tags) => {
    setSavingLabel(true);
    const flowBody = {
      flowId: flow.flowId,
      document: {
        ...(tags || tagValues),
        content: value,
      },
    };
    await updateFlow(flowBody, user);
    if (setValidatedEditor) {
      const hasTags = value.search(/(>{{[a-z_]*}}<)/g) <= 0;
      const hasReference = value.includes('{{referenceId}}');
      const hasQRCode = value.includes('{{qrcode}}');
      let message;
      if (!hasTags) {
        message = "Debe llenar todos los Tags del documento y/o esperar que guarde el documento";
      } else if (!hasReference) {
        message = "El documento no tiene la referencia de Radicado, por favor insertela en menú Inicio"
      } else if (!hasQRCode) {
        message = "El documento no tiene la referencia de Firma, por favor insertela en menú Inicio"
      }
      setValidatedEditor(message);

    }
    setSavingLabel(false);
  };

  const removePage = (evt) => {
    evt.currentTarget.parentElement.remove();
    setValue(editorRef.current.iframeElement.contentDocument.body.innerHTML);
  };

  useEffect(() => {
    if (!editorRef.current) return;
    const removePageButtons =
      editorRef.current.iframeElement.contentDocument.querySelectorAll(
        ".btn-removepage"
      );
    removePageButtons.forEach((btnElement) =>
      btnElement.addEventListener("click", removePage)
    );

    if (value.search(/({{}})/g) > 0) setOpenAddTagModal(true);

    return () => {
      removePageButtons.forEach((btnElement) =>
        btnElement.removeEventListener("click", removePage)
      );
    };
  }, [editorRef.current, value]); // eslint-disable-line

  useEffect(() => {
    if (mustFinish) {
      setFinishing(
        "Iniciando creación de PDF. Puedes consultarlo en un momento.."
      );
      createPDF(flow, user);
      setTimeout(() => {
        setFinishing(false);
        navigate("/filedlist");
      }, 2000);
    }
  }, [mustFinish]); // eslint-disable-line

  useEffect(() => {
    const hasTags = value.search(/(>{{[a-z_]*}}<)/g) <= 0;
    const hasReference = value.includes('{{referenceId}}');
    const hasQRCode = value.includes('{{qrcode}}');
    setOpenFormTagsModal(!hasTags);
    let message;
    if (!hasTags) {
      message = "Debe llenar todos los Tags del documento y/o esperar que guarde el documento";
    } else if (!hasReference) {
      message = "El documento no tiene la referencia de Radicado, por favor insertela en menú Inicio"
    } else if (!hasQRCode) {
      message = "El documento no tiene la referencia de Firma, por favor insertela en menú Inicio"
    }
    if (setValidatedEditor) setValidatedEditor(message);
  }, []); // eslint-disable-line

  return (
    <>
      <MyBackdrop open={finishing} title={finishing} />
      <FormTagsModal
        defaultValue={tagValues}
        open={!disabled && openFormTagsModal}
        tags={template.tags}
        onSubmit={(values) => {
          var elem = document.createElement("div");
          elem.innerHTML = value;
          const keys = Object.keys(values);
          for (let i = 0; i < keys.length; i++) {
            if (!values[keys[i]]) continue;
            const elems = elem.querySelectorAll(
              `strong[data-mce-tag="${keys[i]}"]`
            );

            for (let j = 0; j < elems.length; j++) {
              elems[j].innerHTML = values[keys[i]];
            }
          }
          setValue(elem.innerHTML);
          setTagValues({ ...tagValues, ...values });
          clearTimeout(saving);
          onSave(elem.innerHTML, { ...tagValues, ...values });
        }}
        onClose={() => setOpenFormTagsModal(false)}
      />
      <TagChangeModal
        open={!!tagToChange}
        elem={tagToChange}
        onClose={(changed, value) => {
          if (changed) {
            onChange(
              editorRef.current.iframeElement.contentDocument.body.innerHTML
            );
            const tags = { ...tagValues };
            tags[tagToChange.getAttribute("data-mce-tag")] = value;
            setTagValues(tags);
          }
          setTagToChange(undefined);
        }}
      />
      <AddTagModal
        open={openAddTagModal}
        tags={template.tags}
        onClick={(tag) => {
          setValue(
            value.replace(
              /({{}})/g,
              `<strong class='mceNonEditable' data-mce-tag='${tag}'>${tag}</strong>`
            )
          );
          setOpenAddTagModal(false);
        }}
        onClose={() => setOpenAddTagModal(false)}
      />
      {flow?.filed?.fileUrl ? (
        <iframe
          title="iframe"
          src={flow.filed.fileUrl}
          width="100%"
          height="92%"
          seamless="seamless"
          scrolling="no"
          frameBorder="1"
          allowFullScreen={true}
        />
      ) : (
        <>
          <Box
            sx={{
              color: "gray",
              fontStyle: "italic",
              padding: "8px",
              position: "absolute",
              right: "1em",
              textAlign: "right",
              zIndex: 10,
            }}
          >
            {savingLabel !== undefined &&
              ((savingLabel && "Guardando...") || "Guardado")}
          </Box>
          <EditorMce
            key="editor"
            disabled={disabled}
            selector="textarea"
            apiKey="dpwozqiuvyx46vkj7737jnwbe6z70m3p6r35z6fk8xt9f328"
            value={value}
            init={{
              language: "es",
              allow_conditional_comments: true,
              visual: false,
              height: eHeight,
              entity_encoding: "raw",
              menu: {
                file: { title: "Inicio", items: "addpage addsign addreference" },
              },
              menubar: "file edit format royal",
              setup: (editor) => {
                editor.ui.registry.addMenuItem("addpage", {
                  text: "Agregar página",
                  icon: "page-break",
                  onAction: () => {
                    setValue(`${editor.getContent()}${template.newpage}`);
                  },
                });
                editor.ui.registry.addMenuItem("addsign", {
                  text: "Insertar Firma",
                  icon: "select-all",
                  onAction: () => {
                    editorRef.current.execCommand('mceInsertContent', false, "<p>Atentamente,</p><p><strong class=\"mceNonEditable unchangeable\" style=\"display:block; border: thin solid lightgray; height: 200px;\" data-mce-tag=\"{{qrcode}}\">[[qrcode]]</strong></p>")
                  },
                });
                editor.ui.registry.addMenuItem("addreference", {
                  text: "Insertar Radicado",
                  icon: "visualblocks",
                  onAction: () => {
                    editorRef.current.execCommand('mceInsertContent', false, "<p style=\"text-align: right;\"><strong class=\"mceNonEditable unchangeable\" data-mce-tag=\"{{referenceId}}\">[[radicado]]</strong></p>")
                  },
                });
              },
            }}
            onInit={(__, editor) => (editorRef.current = editor)}
            onEditorChange={onChange}
            onPostProcess={onPostProcess}
            onNodeChange={(evt) => {
              let node = evt.element;
              while (
                node?.classList &&
                !node.classList.contains("maincontent")
              ) {
                node =
                  node === editorRef.current.dom.doc.body
                    ? undefined
                    : node.parentNode;
              }
              if (node) setLastHeight(node.clientHeight);
            }}
            onClick={(evt) => {
              if (
                evt.target.tagName === "STRONG" &&
                evt.target.classList.contains("mceNonEditable")
              ) {
                if (evt.target.classList.contains("changeable"))
                  setTagToChange(evt.target);
                else if (!evt.target.classList.contains("unchangeable"))
                  setOpenFormTagsModal(true);
              }
            }}
          />
        </>
      )}
    </>
  );
}
