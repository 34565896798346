import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Button, Box, Typography } from "@mui/material";

import QrCodeIcon from "@mui/icons-material/QrCode";
import SplitscreenIcon from "@mui/icons-material/Splitscreen";
import SnippetFolderIcon from "@mui/icons-material/SnippetFolder";
import BarChartIcon from "@mui/icons-material/BarChart";
import SettingsIcon from "@mui/icons-material/Settings";
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import CodeIcon from "@mui/icons-material/Code";
import ScienceIcon from "@mui/icons-material/Science";
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import ContactPageIcon from '@mui/icons-material/ContactPage';

import { PERMISSIONS } from "../../constants";

export function Home() {
  const navigate = useNavigate();  
  const user = useSelector((state) => state.user.value);

  const handleRadicar = () => {
    navigate("/filedlist");
  };

  const handleRequestsFiled = () => {
    navigate("/filedlist/requests");
  };

  const handleTasks = () => {
    navigate("/mytasks");
  };

  const handleProcesses = () => {
    navigate("/processes");    
  };

  const handleProcessesTemplate = () => {    
    navigate("/processesTemplate");
  };

  const handleExpedients = (own) => {
    navigate(own ? "/expedients/own": "/expedients");
  };

  const handleContracts = () => {
    navigate("/contracts");
  };

  const handleSupervisorContracts = () => {
    navigate("/contracts/supervisor");
  };

  const handleContractorContracts = () => {
    navigate("/contracts/contractor");
  };

  const handleReports = () => {
    navigate("/reports");
  };

  const handleLabs = () => {
    navigate("/labs");
  };

  const handleSettings = () => {    
    navigate("/config");
  };

  const MenuItem = ({ icon, label, onClick }) => {
    return (
      <Button
        variant="contained"
        color="primary"
        style={{
          textTransform: "none",
          width: "128px",
          height: "128px",
          borderRadius: "8px",
          padding: 0,
        }}
        onClick={onClick}
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
        >
          {icon}
          <Typography variant="caption" style={{ marginTop: 4 }}>
            {label}
          </Typography>
        </Box>{" "}
      </Button>
    );
  };

  return (
    <Box display="flex" flexDirection="row" flexWrap="wrap" gap={2} p={2}>
      {user.permissions.includes(PERMISSIONS.FILED.LIST)
        && <MenuItem
          icon={<QrCodeIcon fontSize="large" />}
          label="Radicar"
          onClick={handleRadicar}
        />
      }
      {user.permissions.includes(PERMISSIONS.REQUESTSFILED.LIST)
      && <MenuItem
          icon={<AssignmentIndIcon fontSize="large" />}
          label="Mis radicados"
          onClick={handleRequestsFiled}
        />
      }
      {user.permissions.includes(PERMISSIONS.TASK.LIST)
        && <MenuItem
          icon={<SplitscreenIcon fontSize="large" />}
          label="Tareas"
          onClick={handleTasks}
        />
      }
      {user.permissions.includes(PERMISSIONS.PROCESS.LIST)
        && <MenuItem
          icon={<MiscellaneousServicesIcon fontSize="large" />}
          label="Procesos"
          onClick={handleProcesses}
        />
      }
      {user.permissions.includes(PERMISSIONS.EXPEDIENT.VIEW)
        && <MenuItem
          icon={<SnippetFolderIcon fontSize="large" />}
          label="Expedientes"
          onClick={() => handleExpedients(false)}
        />
      }
      {user.permissions.includes(PERMISSIONS.EXPEDIENT.OWN)
        && <MenuItem
          icon={<FolderSpecialIcon fontSize="large" />}
          label="Mis Expedientes"
          onClick={() => handleExpedients(true)}
        />
      }
      {user.permissions.includes(PERMISSIONS.CONTRACT.LIST)
        && <MenuItem
          icon={<ContactPageIcon fontSize="large" />}
          label="Contratos"
          onClick={handleContracts}
        />
      }
      {user.permissions.includes(PERMISSIONS.SUPERVISOR_CONTRACT.LIST)
        && <MenuItem
          icon={<ContactPageIcon fontSize="large" />}
          label="Supervisar Contratos"
          onClick={handleSupervisorContracts}
        />
      }
      {user.permissions.includes(PERMISSIONS.CONTRACTOR.LIST)
        && <MenuItem
          icon={<ContactPageIcon fontSize="large" />}
          label="Mis Contratos"
          onClick={handleContractorContracts}
        />
      }
      {user.permissions.includes(PERMISSIONS.TEMPLATE.LIST)
        && <MenuItem
          icon={<CodeIcon fontSize="large" />}
          label="Plantillas"
          onClick={handleProcessesTemplate}
        />
      }
      {user.permissions.includes(PERMISSIONS.REPORT.LIST)
        && <MenuItem
          icon={<BarChartIcon fontSize="large" />}
          label="Reportes"
          onClick={handleReports}
        />
      }
      {user.permissions.includes(PERMISSIONS.LAB.LIST)
        && <MenuItem
          icon={<ScienceIcon fontSize="large" />}
          label="laboratorio"
          onClick={handleLabs}
        />
      }
      {user.permissions.includes(PERMISSIONS.CONFIG.LIST)
        && <MenuItem
          icon={<SettingsIcon fontSize="large" />}
          label="Configuración"
          onClick={handleSettings}
        />
      }
    </Box>
  );
}
