import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { Link as RouterLink } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { resetPasswordWhithEmail } from "../../services/auth/AuthServices";
import { debugLog } from "../../functions";


function PasswordReset() {
    const [email, setEmail] = useState('');

    const handleChange = (event) => {
        setEmail(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Aquí puedes agregar la lógica para enviar el correo de restablecimiento de contraseña
        debugLog(`Enviando enlace de restablecimiento a: ${email}`);
        // Por ejemplo, si estás usando Firebase:
        // firebase.auth().sendPasswordResetEmail(email).then(...).catch(...);
        resetPasswordWhithEmail(email);
    };

    return (
        <Container component="main" maxWidth="xs">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 8
                }}
            >
                <Box sx={{ mb: 3 }}>
                    <LockOutlinedIcon style={{ fontSize: 40, color: 'blue' }} />
                </Box>

                <Typography component="h1" variant="h5">
                    Restablecer contraseña
                </Typography>

                <Box component="form" onSubmit={handleSubmit} mt={3} width="100%">
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Correo Electrónico"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={handleChange}
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Enviar enlace de restablecimiento
                    </Button>

                    <Box mt={2} textAlign="center">
                        <Link variant="body2" component={RouterLink} to="/login">
                            ¿Ya tienes una cuenta? Inicia sesión
                        </Link>
                    </Box>
                </Box>
            </Box>
        </Container>
    );
}

export default PasswordReset;
