import * as React from "react";
import Header from "./Header";
import FiledRequestsList from "../filed/FiledRequestsList";

export default function LayoutHome() {
  return (
    <>
      <Header />
      <FiledRequestsList />
    </>
  );
}
