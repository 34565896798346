import * as React from "react";
import Header from "./Header";
import FiledList from "../filed/FiledList";

export default function LayoutHome() {
  return (
    <>
      <Header />
      <FiledList />
    </>
  );
}
