import React, { useState } from 'react';
import {
  Box, Grid, TextField, Select, MenuItem, Typography, Button, IconButton
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

import AddNewNameModal from "../actions/AddNewNameModal";

const types = {
  string: false,
  users: true,
  people: true
};

const TagItem = ({
  name, type, label, anchors, onChange, onRemove
}) => {
  const [showTagModal, setShowTagModal] = useState(false);

  const handleChange = (value, key) => {
    const tag = { name, type, label, anchors }
    tag[key] = value;
    if (onChange) onChange(tag);
  };

  return (
    <>
      <AddNewNameModal
        title='Anchor'
        open={showTagModal}
        onClose={() => setShowTagModal(false)}
        onSubmit={(name) => {
          const newAnchors = { ...anchors };
          newAnchors[name] = '';
          handleChange(newAnchors, 'anchors');
        }}
      />
      <Grid container spacing={1} sx={{
        border: 'thin dashed gray',
        p: 2,
        my: 1
      }}>
        <Grid item xs={4}>
          <TextField
            label="Nombre"
            defaultValue={name}
            required
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={3}>
          <Select
            labelId="filed-type-label"
            label="Tipo"
            defaultValue={type || ''}
            required
            fullWidth
            onChange={(evt) => handleChange(evt.target.value, 'type')}
          >
            {Object.keys(types).map((type) => (
              <MenuItem key={type} value={type}>{type}</MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Label"
            defaultValue={label}
            fullWidth
            onChange={(evt) => handleChange(evt.target.value, 'label')}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton aria-label="delete" onClick={() => onRemove(name)}>
            <DeleteIcon />
          </IconButton>
        </Grid>

        {types[type] // if type has anchors
          && <Grid container spacing={1} sx={{ m: 1 }}>
            <Grid item xs={12} sx={{ mt: 2 }}>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between'
              }}>
                <Typography variant="h6">Anchors</Typography>
                <Button
                  color='secondary'
                  variant="contained"
                  size='small'
                  onClick={() => setShowTagModal(true)}
                >+</Button>
              </Box>
            </Grid>
            {!anchors && 'Agrega nuevos anchors'}
            {anchors && Object.keys(anchors).map((key) => (
              <React.Fragment key={key}>
                <Grid item xs={6}>
                  <TextField
                    label="name"
                    value={key}
                    fullWidth
                    disabled
                  />
                </Grid>
                <Grid item xs={5}>
                  <TextField
                    label="reference"
                    value={anchors[key]}
                    fullWidth
                    onChange={(evt) => {
                      const newAnchors = { ...anchors };
                      newAnchors[key] = evt.target.value; 
                      handleChange(newAnchors, 'anchors');
                    }}
                  />
                </Grid>
                <Grid item xs={1}>
                  <IconButton aria-label="delete">
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </React.Fragment>
            ))}
          </Grid>
        }
      </Grid>
    </>
  );
};

export default TagItem;
