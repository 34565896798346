import * as React from "react";
import Header from "./Header";
import ExpedientTypeList from "../expedient/ExpedientTypeList";
import ExpedientList from "../expedient/ExpedientList";
import ExpedientListOwn from "../expedient/ExpedientListOwn";
import Expedient from "../expedient/Expedient";

export default function LayoutExpedients({ types, instance, own }) {
  return (
    <>
      <Header />
      {types ? <ExpedientTypeList /> : instance ?
        <Expedient /> : own ? <ExpedientListOwn /> : <ExpedientList />}
    </>
  );
}
