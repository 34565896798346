import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import {
  deleteFile,
  getExpedients,
  uploadFile,
} from "../../services/backend/FileSystemService";
import {
  doneFiled,
  generateReferenceFiled,
  getFiled,
  getFiledFilter,
  getFiledOnSnapshot,
  getRequestFiled,
  updateFiled,
} from "../../services/backend/CorrespondenceService";
import { getPeople } from "../../services/backend/UserService";
import { debugLog } from "../../functions";

export function UploadFile() {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const ref = useRef(null);
  const [imageUrl, setImageUrl] = useState(null);
  const [documentCode, setDocumentCode] = useState(null);
  const [urlFile, setUrlFile] = useState(null);
  const [responseUrl, setResponseUrl] = useState(null);
  const [filed, setFiled] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [subject, setSubject] = useState("");

  const handleFileUpload = async (event) => {
    debugLog("event.target.files: ", event.target.files);
    let localFile = event.target.files[0];
    debugLog("file: ", localFile);
    debugLog("user: ", user);
    if (localFile) {
      const correspondenceId = documentCode.referenceCode;
      const metadata = {
        dato1: "dato1",
        dato2: "dato2",
        dato3: "dato3",
      };
      const bucket = process.env.REACT_APP_STORAGE_BUCKET_FILED;
      const token = user.token;
      const response = await uploadFile(
        token,
        localFile,
        correspondenceId,
        metadata,
        bucket,
        correspondenceId + ".pdf",
        true
      );
      //let responseJSON = await response.json();
      debugLog("response: ", response);
      setResponseUrl(response);
      if (localFile.type === "application/pdf") {
        setUrlFile(response.downloadUrl);
      }
      if (localFile.type === "image/jpeg") {
        setImageUrl(response.downloadUrl);
      }
      //handleThumbnailImage();
      //convertPdfToImages(localFile);
    }
  };

  const handleDeleteUpload = async () => {
    //const urlFile = "https://storage.googleapis.com/royal_test001/001/Google%20Cloud%20Storage%20Client%20Library.pdf"
    //setUrlFile("localFile"localFile.name);
    const localFile =
      documentCode.referenceCode + "/" + documentCode.referenceCode + ".pdf";
    debugLog("file: ", localFile);
    debugLog("user: ", user);
    const bucket = process.env.REACT_APP_STORAGE_BUCKET_FILED;
    const token = user.token;
    const response = await deleteFile(token, bucket, localFile);
    debugLog("response: ", response);
  };

  const handleGetPeople = async () => {
    debugLog("user.token: ", user.token);
    await getPeople(user.token);
  };

  const handleLogin = () => {
    debugLog("handleLogin: ");
    navigate("/Login");
  };

  const handleMigration = async () => {
    console.log("handleMigration: ");
    navigate("/migration");
  };

  const handleGetFiles = async () => {
    const response = await getExpedients("expedients_students", user);
    const responseJSON = await response.json();
    debugLog(responseJSON);
  };

  const getDocumentCode = async () => {
    const start = Date.now();
    debugLog("start: ", start);
    const responseJSON = await generateReferenceFiled(user.token, {
      correspondenceId: "received",
      folios: 5,
    });
    setDocumentCode(responseJSON);
  };

  const handleSaveFiled = async () => {
    const body = {
      filedId: documentCode.referenceCode,
      subject,
    };
    debugLog("user.token: ", user.token);
    await updateFiled(user.token, body);
  };

  const handleFilterFiled = async () => {
    if (startDate && endDate) {
      let startDate1 = startDate.toDate();
      let endDate1 = endDate.toDate();
      endDate1.setHours(23, 59, 59);
      debugLog("startDate: ", startDate1);
      debugLog("endDate: ", endDate1);
      const body = {
        filter: "CREATED_AT",
        startDate: startDate1,
        endDate: endDate1,
      };
      await getFiledFilter(user.token, body);
    }
  };

  const handleDoneFiled = async () => {
    const body = {
      filedId: documentCode.referenceCode,
      referenceId: documentCode.referenceId,
      ...responseUrl,
    };
    debugLog("user.token: ", user.token);
    await doneFiled(user.token, body);
  };

  const handleGetRequestFiled = async () => {
    debugLog("user.token: ", user.token);
    await getRequestFiled(
      user.token,
      documentCode.referenceId
    );
  };

  const handleGetFiled = async () => {
    await getFiled(user.token, documentCode.referenceCode);
  };

  const handleFiled = (document) => {
    try {
      const end = Date.now();
      debugLog(`Execution time: ${end - startDate} ms`);
    } catch (error) {}
    setFiled(document);
  };

  useEffect(() => {
    if (documentCode) {
      debugLog("documentCode: ", documentCode);
      let unsubscribeTasks = getFiledOnSnapshot(
        "filed",
        documentCode.referenceCode,
        handleFiled
      );
      return () => {
        unsubscribeTasks();
      };
    }
  }, [documentCode]); // eslint-disable-line

  return (
    <Container maxWidth="md">
      <Stack spacing={2} margin={10}>
        <Button variant="contained" component="span" onClick={handleLogin}>
          1. Login
        </Button>
        <Stack direction="row" spacing={2}>
          <Button
            variant="contained"
            component="span"
            onClick={getDocumentCode}
          >
            2. Generar Radicado
          </Button>
          {documentCode && (
            <Typography variant="h6" gutterBottom>
              {documentCode.referenceCode}
            </Typography>
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <label htmlFor="upload-image">
            <Button variant="contained" component="span">
              3. Upload
            </Button>
            <input
              id="upload-image"
              hidden
              accept="application/pdf"
              type="file"
              onChange={handleFileUpload}
            />
          </label>
          {urlFile ? (
            <Button
              variant="contained"
              component="span"
              onClick={handleDeleteUpload}
            >
              DeleteFile
            </Button>
          ) : null}
        </Stack>
        {urlFile ? (
          <div id="pdfFile" ref={ref}>
            <iframe src={urlFile} title="iframe" />
          </div>
        ) : null}
        <div id="pdfFile1" ref={ref}>
          <iframe
            title="iframe"
            src={
              "https://storage.googleapis.com/royal_test001/CR_20231128-00325/CR_20231128-00325.pdf"
            }
          />
        </div>
        {filed && (
          <TextField
            id="outlined-textarea"
            label="Contenido del documento"
            placeholder="Placeholder"
            multiline
            maxRows={5}
            value={filed.documentText}
            disabled
          />
        )}
        <Stack direction="row" spacing={2}>
          <TextField
            id="Asunto"
            label="Asunto"
            placeholder="Asunto"
            value={subject}
            onChange={(evt) => setSubject(evt.target.value)}
          />
          <Button
            variant="contained"
            component="span"
            onClick={handleSaveFiled}
          >
            4. SAVE_FILED
          </Button>
        </Stack>

        <Button variant="contained" component="span" onClick={handleGetFiled}>
          5. GET FILED
        </Button>
        <Button variant="contained" component="span" onClick={handleDoneFiled}>
          6. DONE FILED
        </Button>
        <Button
          variant="contained"
          component="span"
          onClick={handleGetRequestFiled}
        >
          7. GET REQUEST
        </Button>
        <Stack direction="row" spacing={2}>
          <DatePicker
            label="Fecha Inicial"
            slotProps={{ textField: { required: true } }}
            value={startDate}
            onChange={(newValue) => setStartDate(newValue)}
          />
          <DatePicker
            label="Fecha Final"
            slotProps={{ textField: { required: true } }}
            value={endDate}
            onChange={(newValue) => setEndDate(newValue)}
          />
          <Button
            variant="contained"
            component="span"
            onClick={handleFilterFiled}
          >
            8. Filter
          </Button>
        </Stack>
        <Button variant="contained" component="span" onClick={() => debugLog('Get Image')}>
          9. Get Image
        </Button>
        <Button variant="contained" component="span" onClick={handleGetPeople}>
          10. Get people
        </Button>
        {imageUrl && <img src={imageUrl} alt="Uploaded" height="300" />}

        <Button variant="contained" component="span" onClick={handleGetFiles}>
          11. Get Files
        </Button>
        <Button variant="contained" component="span" onClick={handleMigration}>
          Migration
        </Button>
        <Button variant="contained" component="span" onClick={() => navigate('/richeditor')}>
          Rich Editor
        </Button>
      </Stack>
    </Container>
  );
}
