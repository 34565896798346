import React from "react";
import { useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  IconButton,
  TextField,
} from "@mui/material";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ChecklistIcon from "@mui/icons-material/Checklist";
import GradingIcon from "@mui/icons-material/Grading";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";

const ActionItem = ({ item, handleUpdateItem }) => {
  const users = useSelector((state) => state.user.users);

  const handleAutocompleteOwners = (e, owners) => {
    handleUpdateItem("update", { ...item, owners: [owners] });
  };
  const handleDeleteAction = () => {
    handleUpdateItem("delete", item);
  };

  return (
    <Box
      sx={{
        p: 0.3,
      }}
    >
      <Box
        alignItems="center"
        sx={{
          border: "thin solid lightgray",
          borderRadius: 1,
          boxShadow: "0px 0px 2px #ccc",
          display: "flex",
          flexDirection: "row",
          p: 1,
        }}
      >
        {item.type === "create" ? (
          <BorderColorIcon color="primary" sx={{ fontSize: 23 }} />
        ) : item.type === "elaborate" ? (
          <EditNoteIcon color="primary" sx={{ fontSize: 30 }} />
        ) : item.type === "approve" ? (
          <GradingIcon color="primary" sx={{ fontSize: 25 }} />
        ) : item.type === "review" ? (
          <ChecklistIcon color="primary" sx={{ fontSize: 25 }} />
        ) : item.type === "sign" ? (
          <DoneAllIcon color="primary" sx={{ fontSize: 25 }} />
        ) : (
          <BorderColorIcon color="primary" sx={{ fontSize: 30 }} />
        )}
        <Box
          sx={{
            ml: 2,
            display: "flex",
            width: "10%",
          }}
        >
          {item.actionName}
        </Box>
        <Box
          sx={{
            ml: 2,
            display: "flex",
            width: "90%",
          }}
        >
          {item.type === "create" ? (
            <Chip
              sx={{ cursor: "pointer" }}
              label={item.owners[0].name + " " + item.owners[0].lastName}
            />
          ) : (
            <FormControl fullWidth>
              <Autocomplete
                id="size-small-outlined-multi"
                size="medium"
                options={users ? users : []}
                getOptionLabel={(option) => `${option.name} ${option.lastName} <<${option.email}>>`}
                value={item.owners[0]}
                isOptionEqualToValue={(option, value) =>
                  option.userId === value.userId
                }
                onChange={(event, newValue) => {
                  handleAutocompleteOwners(event, newValue);
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Responsables"
                    placeholder="Responsables"
                  />
                )}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      sx={{ cursor: "pointer" }}
                      label={option.name + " " + option.name}
                      {...getTagProps({ index })}
                    />
                  ))
                }
              />
            </FormControl>
          )}
        </Box>
        {item.type !== "create" ? (
          item.type !== "sign" ? (
            <IconButton onClick={handleDeleteAction}>
              <DeleteOutlineIcon color="primary" />
            </IconButton>
          ) : null
        ) : null}
      </Box>
    </Box>
  );
};

export default ActionItem;
