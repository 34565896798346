import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    value: null,
    token: null,
    users: null,
    people: null
  },
  reducers: {
    setUser: (state, action) => {
      state.value = action.payload;
    },
    setUserToken: (state, action) => {
      state.value.token = action.payload;
    },
    setCredentials: (state, action) => {
      const { accesToken } = action.payload;
      state.token = accesToken;
    },
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    addUser: (state, action) => {
      state.users = [...state.users, action.payload];
    },
    updateUsers: (state, action) => {
      const users = [...state.users];
      for (let i = 0; i < users.length; i++) {
        if (users[i].userId === action.payload.userId) {
          users[i] = action.payload;
        }
      }
      state.users = users;
    },
    setPeople: (state, action) => {
      state.people = action.payload;
    },
    addPeople: (state, action) => {
      state.people = [...state.people, action.payload];
    },
    updatePeople: (state, action) => {
      const people = [...state.people];
      for (let i = 0; i < people.length; i++) {
        if (people[i].peopleId === action.payload.peopleId) {
          people[i] = action.payload;
        }
      }
      state.people = people;
    },
    logout: (state) => {
      state.token = null;
      state.value = null;
    }
  }
});

// Action creators are generated for each case reducer function
export const {
  setUser, setUserToken, setCredentials, setUsers, addUser,
  updateUsers, setPeople, addPeople, updatePeople, logout
} = userSlice.actions;

export default userSlice.reducer;

export const selectCurrentToken = (state) => state.user.token;
