import { getAuth } from "firebase/auth";
import moment from "moment/moment";

import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";
import { server } from "../../config/ConfigFirebase";
import { debugLog } from "../../functions";

const handleProcess = async (token, body, retry = false) => {
  const response = await fetch(
    "https://" + server + ".cloudfunctions.net/handleProcess",
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response.status === 401 && !retry) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
    return await handleProcess(token, body, true);
  }

  return response;
};
export const deleteProcess = async (processId, user) => {
  const processesBody = {
    processId,
    action: "DELETE_PROCESS",
  };
  //debugLog("createVault chatBody: ", chatBody);
  try {
    let response = await handleProcess(user.token, processesBody);
    //debugLog("response.ok: ", response.ok);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const getProcesses = async (filter, user) => {
  const processesBody = {
    ...filter,
    action: "GET_PROCESSES",
  };
  try {
    let response = await handleProcess(user.token, processesBody);
    let responseJSON = await response.json();
    responseJSON.forEach((element) => {
      element.createdAt = moment(element.createdAt);
      element.qualityDate = element.qualityDate
        ? new Date(element.qualityDate)
        : undefined;
    });
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const addProcessTemplate = async (body, user) => {
  const createdAt = new Date();
  const processesBody = {
    ...body,
    createdAt,
    action: "ADD_PROCESS_TEMPLATE",
  };
  try {
    let response = await handleProcess(user.token, processesBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const addProcess = async (body, user) => {
  const createdAt = new Date();
  const processesBody = {
    ...body,
    createdAt,
    action: "CREATE_PROCESS",
    userId: user.userId,
  };
  try {
    let response = await handleProcess(user.token, processesBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const getProcessesTemplates = async (filter, user) => {
  const processesBody = {
    ...filter,
    action: "GET_PROCESSES_TEMPLATES",
  };
  try {
    let response = await handleProcess(user.token, processesBody);
    let responseJSON = await response.json();
    responseJSON.forEach((element) => {
      element.qualityDate = element.qualityDate
        ? new Date(element.qualityDate)
        : undefined;
    });
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
