import React, { useState } from "react";
import Header from "./Header";
import RichEditor from "../generic/rich-editor/index";
import { Button } from "@mui/material";

export default function LayoutTask() {
  const [data, setData] = useState();

  const file = {
    "name": "myfilebase64",
    "path": "templates/myfilebase64",
    "url": "https://firebasestorage.googleapis.com/v0/b/royalplusdev.appspot.com/o/documents%2Ftemplates%2Fmyfilebase64?alt=media&token=629926fb-4b8b-48bf-8556-3ac5ff77f7c9"
  };

  const tags = {
    "{{subject}}": {
      "type": "string"
    },
    "{{recipient}}": {
      "type": "contractor",
      "anchors": {
        "{{firstname}}": "name",
        "{{lastname}}": "lastName",
        "{{email}}": "email"
      }
    }
  }

  const handleClick = () => {
    setData({
      "{{subject}}": "Hola a todos",
      "{{firstname}}": "Jhon",
      "{{lastname}}": "Doe",
      "{{email}}": "jhon@doe.com"
    })
  };

  return (
    <>
      <Header />
      <Button onClick={handleClick}>Llenar tags</Button>
      <RichEditor file={file} tags={tags} data={data} />
    </>
  );
}
