import React from "react";
import { Snackbar, Alert } from "@mui/material";

const MySnackbar = ({ info, handleClose }) => (
  <Snackbar
    open={info.open}
    autoHideDuration={info.duration ? info.duration : 3000}
    onClose={handleClose}
    anchorOrigin={{
      horizontal: info.horizontal ? info.horizontal : "center",
      vertical: info.vertical ? info.vertical : "top",
    }}
  >
    <Alert severity={info.severity ? info.severity : "success"}>
      {info.message}
    </Alert>
  </Snackbar>
);

export default MySnackbar;
