import { server } from "../../config/ConfigFirebase";
import { ROLES } from "../../constants";
import { debugLog } from "../../functions";

const handleUser = async (token, body) => {
  return await fetch("https://" + server + ".cloudfunctions.net/user-handle", {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getUser = async (token, userUID) => {
  try {
    const userBody = { action: "GET_USER", userUID };
    const response = await handleUser(token, userBody);
    const responseJSON = await response.json();
    if (responseJSON.result) {
      responseJSON.user.token = token;
      responseJSON.user = getUserPermissions(responseJSON.user);
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getUserPermissions = (user) => {
  const permissions = new Set(user.permissions);
  user.roles?.forEach((role) => ROLES[role]?.forEach(permissions.add, permissions));
  user.permissions = Array.from(permissions);
  return user;
};

export const getReciversUser = async (token) => {
  try {
    const userBody = { action: "INTERNAL_RECEIVER" };
    let response = await handleUser(token, userBody);
    let responseJSON = await response.json();
    if (responseJSON.result) {
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
export const getPeople = async (token) => {
  try {
    const peopleBody = { action: "EXTERNAL_RECEIVER" };
    let response = await handleUser(token, peopleBody);
    let responseJSON = await response.json();
    if (responseJSON.result) {
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
export const addUser = async (bodyUser, user) => {
  try {
    const userBody = { action: "ADD_USER", ...bodyUser };
    let response = await handleUser(user.token, userBody);
    let responseJSON = await response.json();
    if (responseJSON.result) {
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
export const addPeople = async (bodyPeople, user) => {
  try {
    const peopleBody = { action: "ADD_PEOPLE", ...bodyPeople };
    let response = await handleUser(user.token, peopleBody);
    let responseJSON = await response.json();    
    if (responseJSON.result) {
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
export const updateUser = async (bodyUser, user) => {
  try {
    const userBody = { action: "UPDATE_USER", ...bodyUser };

    let response = await handleUser(user.token, userBody);
    let responseJSON = await response.json();
    if (responseJSON.result) {
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
export const updatePeople = async (bodyPeople, user) => {
  try {
    const peopleBody = { action: "UPDATE_PEOPLE", ...bodyPeople };

    let response = await handleUser(user.token, peopleBody);
    let responseJSON = await response.json();
    if (responseJSON.result) {
      return responseJSON;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
