import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Box,  
  Container,
  Typography,
  Grid,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,  
  RadioGroup,
  FormControlLabel,
  Radio
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ArrowBack from '@mui/icons-material/ArrowBack';

import MyBackdrop from "../generic/MyBackdrop";
import MySnackbar from "../generic/MySnackbar";
import { generateReport } from "../../services/backend/ReportService";
import ReportList from "./ReportList";

const Reports = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value)
  const [action, setAction] = useState('');
  const [startAt, setStartAt] = useState(null);
  const [endAt, setEndAt] = useState(null);
  const [infoSnack, setInfoSnack] = useState({ open: false });
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [filter, setFilter] = useState('');

  const handleGenerate = async () => {
    let message;
    if (!startAt || !endAt) message = 'Ingrese un rango de fechas válido';
    if (startAt > endAt) message = 'La fecha de inicio no puede ser mayor a la fecha final';
    if (!action) message = 'Seleccione la fuente del reporte';

    if (message) {
      setInfoSnack({ open: true, message, severity: 'warning' });
      return;
    }

    setOpenBackdrop(true);
    await generateReport({
      action,
      startAt: startAt.format(),
      endAt: endAt.endOf('day').format(),
      ...(filter ? { filter: { field: 'correspondenceId', value: filter } } : {})
    }, user);

    setAction('');
    setStartAt(null);
    setEndAt(null);
    setFilter('');
    setOpenBackdrop(false);
  };

  return (
    <Container maxWidth="md" style={{ padding: 3, marginTop: 10 }}>
      <Typography variant="h4">
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          style={{ margin: 16 }}
          onClick={() => navigate(-1)}
        >
          Volver
        </Button>
        Reportes
      </Typography>
      <Box sx={{ m: 1, backgroundColor: '#f9f9f9', p: 3, borderRadius: 2 }}>
        <Grid container spacing={1} sx={{ mb: 3 }}>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <InputLabel id="type-label">Fuente del reporte</InputLabel>
              <Select
                required
                labelId="type-label"
                label="Fuente del reporte"
                value={action}
                onChange={(evt) => setAction(evt.target.value)}
              >
                <MenuItem value="FILED_REPORT">Radicados</MenuItem>
                {/* <MenuItem value="EXPEDIENT_REPORT">Expedientes</MenuItem>
                <MenuItem value="FLOW_REPORT">Flujos</MenuItem>
                <MenuItem value="CONTRACT_REPORT">Contratos</MenuItem> */}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <DatePicker
                label="Fecha inicio"
                value={startAt}
                onChange={(momt) => setStartAt(momt)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth>
              <DatePicker
                label="Fecha final"
                value={endAt}
                onChange={(momt) => setEndAt(momt)}
              />
            </FormControl>
          </Grid>
        </Grid>
        {action === 'FILED_REPORT'
          && <Grid item xs={12} sx={{
            alignItems:'center',
            backgroundColor: 'rgba(200, 200, 200, .1)',
            borderRadius: 2,
            display: 'flex',
            my: 3,
            p: 1
          }}>
            <Typography sx={{ mr: 4 }}>Filtrar por comunicación:</Typography>
            <RadioGroup
              row
              value={filter}
              onChange={(evt) => setFilter(evt.target.value)}
            >
              <FormControlLabel
                value='internal'
                control={<Radio size="small" />}
                label='Interna'
              />
              <FormControlLabel
                value='external'
                control={<Radio size="small" />}
                label='Despachada'
              />
              <FormControlLabel
                value='received'
                control={<Radio size="small" />}
                label='Recibida'
              />
            </RadioGroup>
          </Grid>
        }
        <Button
          variant="contained"
          onClick={handleGenerate}
          disabled={openBackdrop.open}
        >Generar reporte</Button>
      </Box>

      <ReportList />

      <MyBackdrop open={openBackdrop} />
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
    </Container>
  );
};

export default Reports;
