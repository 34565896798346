import * as React from "react";
import Header from "./Header";
import Reports from "../reports/Reports";

export default function LayoutReport() {
  return (
    <>
      <Header />
      <Reports />
    </>
  );
}
