import { ref, getDownloadURL, uploadString } from "firebase/storage";

import { storage } from "../../config/ConfigFirebase";
import { debugLog } from "../../functions";

/**
   * Converting blob from Storage to base64 string
   */
const blobToBase64 = (blob) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result.split(',')[1]);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

/**
 * Getting file (blob) from Storage
 * @param {string} sourceUrl 
 * 
 * @returns File in base64
 */
export const getFile = async (sourceUrl) => {
  try {
    const response = await fetch(sourceUrl);
    const blob = await response.blob();
    return await blobToBase64(blob);
  } catch (error) {
    debugLog('Failed to fetch the file:', error);
  }
};

export const saveFile = async (path, base64) => {
  const storageRef = ref(storage, `documents/${path}`);
  await uploadString(storageRef, base64, 'base64');
  const url = await getDownloadURL(storageRef);
  debugLog('Download URL:', url);
  return url;
};

/**
 * Copy file in base64 to destionation path
 * @param {string} sourceUrl 
 * @param {string} destination 
 * 
 * @returns Destination file url
 */
export const copyFileInStorage = async (sourceUrl, destination) => {
  try {
    const response = await fetch(sourceUrl);
    const blob = await response.blob();
    const destinationRef = ref(storage, destination);
    const base64 = await blobToBase64(blob);
    await uploadString(destinationRef, base64, 'base64');
    const destinationUrl = await getDownloadURL(destinationRef);
    return destinationUrl;
  } catch (err) {
    debugLog(err)
  }
};