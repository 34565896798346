import * as React from "react";
import Header from "./Header";
import FiledView from "../filed/FiledView";

export default function LayoutHome() {
  return (
    <>
      <Header />
      <FiledView />
    </>
  );
}
