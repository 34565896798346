import React, { useState, useEffect } from "react";
import {
  Autocomplete, Box, Button, CircularProgress, Dialog, FormControl, Grid, InputLabel,
  MenuItem, Select, TextField, Typography
} from "@mui/material";
import { useSelector } from "react-redux";
import AttachFileIcon from "@mui/icons-material/AttachFile";

import { getExtensionFile, uploadFile } from "../../services/backend/FileSystemService";
import { addDocument, updateDocument } from "../../services/backend/ExpedientService";
import SelectUser from "../generic/SelectUser";
import { debugLog } from "../../functions";
import { createFiledThumb } from "../../services/backend/CommonServices";

const defaultFields = {
  documentName: "",
  documentType: null,
  documentFormat: "PDF",
  documentUrl: "",
  documentContent: "",
  folios: 0,
  metadata: {},
  keywords: [],
  owners: []
};

const DocumentFormModal = ({
  open, expedient, document, onClose, onSubmit
}) => {
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [localFile, setLocalFile] = useState();
  const [fields, setFields] = useState(defaultFields);

  const handleUploadDocument = async (documentName) => {
    const extensionFile = getExtensionFile(localFile.name);

    if (localFile) {
      const correspondenceId = expedient.expedientTypeId;
      const bucket = process.env.REACT_APP_STORAGE_BUCKET_EXPEDIENTS;
      const token = user.token;
      const fileName = `${expedient.expedientId}/${documentName}${extensionFile}`;
      try {
        const response = await uploadFile(
          token,
          localFile,
          correspondenceId,
          { dato1: "dato1", dato2: "dato2", dato3: "dato3" },
          bucket,
          fileName,
          false
        );
        if (response.result) return response.downloadUrl;
        return null;
      } catch (error) {
        debugLog(error);
      }
    }
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setLoading(true);
    let response = await addDocument(fields, user);
    if (response) {
      setFields({ ...fields, documentId: response.documentId });
      const documentUrl = await handleUploadDocument(response.documentId);

      const thumb = await createFiledThumb(user, {
        filed: {
          referenceCode: `${expedient.expedientId}/${response.documentId}`,
          url: documentUrl,
          noUpdate: true,
          bucket: process.env.REACT_APP_STORAGE_BUCKET_EXPEDIENTS,
          correspondenceId: expedient.expedientTypeId
        }
      });

      response = await updateDocument({
        documentId: response.documentId, documentUrl, thumbUrl: thumb?.downloadUrl
      }, user);

      if (response) {
        setFields({ ...fields, documentUrl, thumbUrl: thumb?.downloadUrl });
        if (onSubmit) onSubmit({ ...fields, documentUrl, thumbUrl: thumb?.downloadUrl });
      }
    }
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (open) {
      setFields({
        ...fields,
        expedientId: expedient.expedientId,
        expedientTypeId: expedient.expedientTypeId
      });
    }    
  }, [expedient, open]); // eslint-disable-line

  useEffect(() => {
    if (document) setFields(document);
  }, [document]);

  useEffect(() => {
    if (localFile && !fields.documentName) {
      let documentName = localFile.name;
      const index = localFile.name.lastIndexOf(".");
      if (index > -1) documentName = localFile.name.substring(0, index);
      setFields({ ...fields, documentName });
    };
  }, [localFile]); // eslint-disable-line

  useEffect(() => {
    if (!open) {
      setFields(defaultFields);
      setLocalFile(null);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {document ? "Modificar documento" : "Crear documento"}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                label="Expediente"
                value={fields.expedientId}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                label="Tipo de expediente"
                value={fields.expedientTypeId}
                disabled={true}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl required fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="document-format">Formato de documento</InputLabel>
              <Select
                labelId="document-format"
                label="Formato de documento"
                value={fields.documentFormat}
                disabled={true}
                onChange={(evt) => setFields({ ...fields, documentFormat: evt.target.value })}
              >
                {["PDF", "WORD", "EXCEL", "IMAGE"].map((format) => (
                  <MenuItem key={format} value={format}>{format}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                required
                label="Nombre del documento"
                name="documentName"
                value={fields.documentName}
                onChange={(evt) => setFields({ ...fields, documentName: evt.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={4} sx={{ display: "flex", alignItems: "center" }}>
            {loading ? (
              <CircularProgress />
            ) : (
              <>
                <label htmlFor="upload-document" style={{ alignItems: 'center', display: 'flex', width: '100%' }}>
                  <Box sx={{ flex: 1, overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                    {localFile?.name || 'No ha seleccionado ningún archivo'}
                  </Box>
                  <AttachFileIcon
                    color="primary"
                    fontSize="large"
                  />
                </label>
                <input
                  id="upload-document"
                  hidden
                  accept="application/pdf"
                  type="file"
                  onChange={(evt) => setLocalFile(evt.target.files[0])}
                />
              </>
            )}
          </Grid>
          <Grid item xs={9}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <Autocomplete
                size="medium"
                options={expedient.trd.subserie.filetypes}
                value={fields.documentType}
                getOptionLabel={(option) => option}
                onChange={(__, value) => setFields({ ...fields, documentType: value})}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Tipo de documento"
                    placeholder="Tipo de documento"
                    required={true}
                  />
                )}
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                required
                label="Folios"
                name="folios"
                value={fields.folios}
                type='number'
                onChange={(evt) => setFields({ ...fields, folios: evt.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                multiline
                rows={5}
                label="Metadatos"
                name="metadata"
                defaultValue={fields.metadata && Object.keys(fields.metadata).map(
                  (key) => `${key}: ${fields.metadata[key]}`
                ).join("\n")}
                onChange={(evt) => {
                  const lines =  evt.target.value.split("\n").map((data) => {
                    const d = {};
                    d[data.split(":")[0]] = data.split(":")[1];
                    return d;
                  });
                  const metadata = {};
                  lines.forEach((data) => Object.assign(metadata, data));
                  setFields({ ...fields, metadata });
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <Autocomplete
                freeSolo
                multiple={true}
                options={[]}
                value={fields.keywords}
                renderInput={(params) => (<TextField {...params} label="Palabras claves" />)}
                onChange={(__, keywords) => setFields({ ...fields, keywords })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <SelectUser
                noedit={true}
                userType="users"
                placeholder="Permisos de acceso"
                multiple
                value={fields.owners}
                onChange={(__, owners) => setFields({ ...fields, owners })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={!fields.documentName || !fields.documentFormat || !fields.documentType || !localFile || loading}
            >
              {loading ? (
                <CircularProgress size={26} />
              ) : (
                <>
                  {document ? "Modificar" : "Crear"} documento
                </>
              )}
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default DocumentFormModal;
