import React, { useEffect, useState, useMemo } from "react";
import {
  Modal, Box, Typography, FormControl, TextField,
  Button, Grid, CircularProgress, Link
} from "@mui/material";
import { useSelector } from "react-redux";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';

import { UploadFile } from "../../services/backend/UploadFileService";
import { updateContract } from "../../services/backend/ContractService";
import MoneyInput from "../generic/MoneyInput";
import MySnackbar from "../generic/MySnackbar";

const modalStyle = {
  bgcolor: "background.paper",
  boxShadow: 24,
  left: "50%",
  maxWidth: "980px",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%"
};

const certs = {
  form: 'planilla de seguridad social',
  payroll: 'pago de planilla',
  transaction: 'transacción del pago',
  pensioner: 'resolución de pensionado'
};

const SocialSecurityModal = ({
  contract, payment, open, setContract, onClose
}) => {
  const user = useSelector((state) => state.user.value);
  const [contribution, setContribution] = useState();
  const [payroll, setPayroll] = useState('');
  const [payAt, setPayAt] = useState(null);
  const [certificates, setCertificates] = useState({ payroll: null, form: null, transaction: null, pensioner: null });
  const [socialApproved, setSocialApproved] = useState();
  const [loading, setLoading] = useState(false);
  const [infoSnack, setInfoSnack] = useState({ open: false });

  const isContractor = useMemo(() => contract?.contractor?.userId === user.userId, [contract, user]);

  const handleUploadFile = async (evt, key) => {
    if (evt.target.files.length === 0) return;
    setLoading(true);

    const response = await UploadFile(
      user,
      evt.target.files[0],
      process.env.REACT_APP_STORAGE_BUCKET_CONTRACTS,
      `${contract.contractCode}/certificate_${payment}_${key}.pdf`
    );

    if (response.result) {
      let payments = {};
      if (contract.payments) payments = {...contract.payments};
      if (!payments[payment]) payments[payment] = { certificates };
      if (!payments[payment].certificates) payments[payment].certificates = certificates;
      payments[payment].certificates[key] = response.downloadUrl;

      await updateContract(contract.contractCode, { payments }, user);
      setContract({ ...contract, payments });
      setCertificates({ ...certificates, [key]: response.downloadUrl });
    }

    setLoading(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setLoading(true);
    let payments = {};
    if (contract.payments) payments = {...contract.payments};
    if (!payments[payment]) payments[payment] = {};
    payments[payment].contribution = contribution;
    payments[payment].payroll = payroll;
    payments[payment].payAt = payAt;
    payments[payment].certificates = certificates;

    await updateContract(contract.contractCode, { payments }, user);
    setContract({ ...contract, payments });
    setLoading(false);
  };

  const handleCloseSocial = async (evt) => {
    evt.preventDefault();
    
    let message;
    if (!contribution) message = 'Ingrese el valor de los aportes';
    if (!payroll) message = 'Ingrese el número de la planilla';
    if (!payAt) message = 'Ingrese la fecha de pago';
    if (!certificates.form) message = 'Debe cargar la planilla de pago de Seguridad social';
    if (!certificates.payroll) message = 'Debe cargar el comprobante de pago de la planilla';
    if (!certificates.transaction) message = 'Debe cargar el comprobante de la transacción';

    if (message) {
      setInfoSnack({ open: true, message, severity: 'warning' });
      return;
    }

    setLoading(true);
    
    let payments = {};
    if (contract.payments) payments = {...contract.payments};
    if (!payments[payment]) payments[payment] = {};
    payments[payment].contribution = contribution;
    payments[payment].payroll = payroll;
    payments[payment].payAt = payAt;
    payments[payment].certificates = certificates;
    payments[payment].socialApproved = false;

    await updateContract(contract.contractCode, { payments }, user);
    setContract({ ...contract, payments });
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (payment) {
      let pay = {};
      if (contract.payments && contract.payments[payment]) {
        pay = contract.payments[payment];
      }

      setContribution(pay.contribution);
      setPayroll(pay.payroll || '');
      setPayAt(pay.payAt || null);
      if (pay.certificates) setCertificates(pay.certificates);
      setSocialApproved(pay.socialApproved);
    } else {
      setContribution(undefined);
      setPayroll('');
      setPayAt(null);
      setCertificates({ form: null,  payroll: null, transaction: null, pensioner: null });
      setSocialApproved(undefined);
    }
  }, [payment]); // eslint-disable-line

  return (
    <>
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      <Modal
        open={open}
        onClose={onClose}
      >
        <Box component="form" onSubmit={handleSubmit} sx={modalStyle}>
          <Typography variant="h5">Registro Seguridad Social</Typography>
          <Grid container spacing={1} sx={{ my: 3 }}>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <MoneyInput
                  label="Valor de los aporte"
                  value={contribution}
                  onChange={(evt) => setContribution(evt.target.value)}
                  readOnly={socialApproved !== undefined || !isContractor}
                />
              </FormControl>

            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <TextField
                  label=" N° Planilla"
                  value={payroll}
                  onChange={(evt) => setPayroll(evt.target.value)}
                  InputProps={{ readOnly: socialApproved !== undefined || !isContractor }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={3}>
              <FormControl fullWidth>
                <DatePicker
                  label="Fecha de pago"
                  value={payAt}
                  onChange={(val) => setPayAt(val)}
                  readOnly={socialApproved !== undefined || !isContractor}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6} mt={1}>
              {Object.keys(certificates).map((key) => (
                <Box key={key} sx={{ mb: 1, mt: key === 'pensioner' ? 4 : 'initial' }}>
                  {key === 'pensioner' && <Typography color="GrayText" sx={{ mt: 4 }}>Solo si aplica</Typography>}
                  {certificates[key] ? (
                    <Link
                      component="a"
                      href={certificates[key]}
                      target="_blank"
                    >
                      <Button
                        component="label"
                        role={undefined}
                        variant="contained"
                        startIcon={<DownloadIcon />}
                        disabled={loading}
                      >
                        Ver Comprobante {certs[key]}
                      </Button>
                    </Link>
                  ) : (
                    <Button
                      component="label"
                      role={undefined}
                      variant="outlined"
                      startIcon={<CloudUploadIcon />}
                      disabled={loading || !isContractor}
                    >
                      {loading && <CircularProgress size={26} sx={{ mr: 1 }} />} Comprobante {certs[key]}
                      <input
                        hidden
                        accept="*"
                        type="file"
                        onChange={(evt) => handleUploadFile(evt, key)}
                      />
                    </Button>
                  )}
                </Box>
              ))}
            </Grid>
            {isContractor && socialApproved === undefined
              && <Grid item xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{ mt: 5 }}
                  disabled={loading}
                >
                  {loading && <CircularProgress size={26} sx={{ mr: 1 }} />} Actualizar
                </Button>
                {/* <Button
                  variant="outlined"
                  sx={{ mt: 5, ml: 3 }}
                  disabled={loading}
                  onClick={handleCloseSocial}
                >
                  {loading && <CircularProgress size={26} sx={{ mr: 1 }} />} Enviar para aprobación
                </Button> */}
              </Grid>
            }
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default SocialSecurityModal;
