import React from "react";
import { Box, Backdrop, CircularProgress } from "@mui/material";

const MyBackdrop = ({ open, title }) => (
  <Backdrop
    sx={{ zIndex: (theme) => 1310 }}
    open={open}
  >
    {title ? (
      <Box style={{
        alignItems: 'center',
        backgroundColor: 'white',
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        padding: 16
      }}>
        <CircularProgress />
        <Box style={{ marginLeft: 20 }}>{title}</Box>
      </Box>
    ) : (
      <CircularProgress style={{ color: 'white' }} />
    )}
  </Backdrop>
);

export default MyBackdrop;
