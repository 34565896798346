import React from "react";
import { Box, Switch, Stack, Typography } from "@mui/material";

export default function RegisterSignature({
  actionName,
  edit,
  signed,
  setSigned,
}) {
  const handleChange = (event) => {
    setSigned(event.target.checked);
  };

  return (
    <Box
      sx={{
        p: 1,
        boxShadow: 1,
        borderRadius: 3,
        marginX: 1,
      }}
    >
      <Stack
        spacing={2}
        alignContent={"center"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Typography variant="h6" gutterBottom>
          Registrar Firma Electrónica
        </Typography>
        <Stack direction="row" spacing={2}>
          <Switch
            checked={signed}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            disabled={!edit}
          />
          <Typography variant="body2" gutterBottom>
            Mediante la activación de este botón, usted manifiesta que está
            totalmente de acuerdo con el contenido de este documento.
            Por lo tanto autoriza continuar el trámite y registrar
            electrónicamente que fue <strong>{actionName?.toUpperCase()}</strong> por usted.            
          </Typography>
        </Stack>
      </Stack>
    </Box>
  );
}
