import { useState, useEffect } from "react";
import { Outlet, Navigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAuth } from "firebase/auth";

import CircularProgress from "../../components/generic/CircularProgress";
import { getUserDB } from "../auth/AuthServices";
import { getReciversUser } from "../backend/UserService";
import { setUser, setUsers } from "../../redux/slices/userSlice";
import { sortAsc } from "../../functions";

export default function ProtectedRoute() {
  const dispatch = useDispatch();
  const userAuth = useSelector((state) => state.user.value);
  const [checkingAuth, setCheckingAuth] = useState(true);
  const location = useLocation();

  useEffect(() => {
    if (!userAuth) {
      const unsubscribe = getAuth().onAuthStateChanged(async (loginUser) => {
        if (loginUser) {
          let response = await getUserDB(loginUser);
          dispatch(setUser(response.user));
          response = await getReciversUser(response.user.token);
          dispatch(setUsers(response.users.sort(sortAsc('name'))));
        }
        setCheckingAuth(false);
      });
      return () => unsubscribe();
    } else {
      setCheckingAuth(false);
    }
  }, [dispatch, userAuth]);

  if (checkingAuth) {
    return <CircularProgress />;
  }

  if (userAuth) {
    // Si el usuario está en la ruta de login o cualquier otra no definida, redirige a dashboard
    const shouldRedirect =
      location.pathname === "/login" || location.pathname === "*";
    return shouldRedirect ? <Navigate to="/dashboard" replace /> : <Outlet />;
  }

  return <Navigate to="/login" replace />;
}
