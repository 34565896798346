import { firestore, server } from "../../config/ConfigFirebase";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import { debugLog } from "../../functions";

const handleTask = async (token, body) => {
  return await fetch("https://" + server + ".cloudfunctions.net/handleTask", {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getTasksOnSnapshot = (userId, kanbanId, handleTask) => {
  debugLog("userUID: ", userId);
  let queryTasks = query(
    collection(firestore, "tasks"),
    where("kanbanId", "==", kanbanId)
  );
  return onSnapshot(queryTasks, (snapshot) => {
    let tasks = [];
    snapshot.forEach((doc) => {
      const newTask = {
        taskId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate(),
      };
      //debugLog("newTask: ", newTask);
      tasks.push(newTask);
    });
    tasks.sort((a, b) => b.createdAt - a.createdAt);
    handleTask(tasks);
  });
};

export const getTasksByUserOnSnapshot = (userId, handleTasks) => {
  let queryTasks = query(collection(firestore, "tasks"), where("userId", "==", userId));
  return onSnapshot(queryTasks, (snapshot) => {
    let tasks = [];
    snapshot.forEach((doc) => {
      tasks.push({
        taskId: doc.id,
        ...doc.data(),
        createdAt: doc.data().createdAt.toDate()
      });
    });
    tasks.sort((a, b) => b.createdAt - a.createdAt);
    handleTasks(tasks);
  });
};

export const getTaskOnSnapshot = (taskId, handleTask) => {
  debugLog("taskId: ", taskId);
  let querySteps = doc(firestore, "tasks", taskId);
  return onSnapshot(querySteps, (doc) => {
    //debugLog("steps: ", steps);
    handleTask(doc.data());
  });
};
export const getStepsOnSnapshot = (userId, kanbanId, handleStep) => {
  debugLog("kanbanBoard: ", kanbanId);
  let querySteps = doc(firestore, "kanbanBoards", kanbanId);
  return onSnapshot(querySteps, (doc) => {
    let steps = [];
    steps = doc.data().steps;
    if (steps) {
      steps.forEach((step, index) => {
        step.step = index + 1;
        step.kanbanId = kanbanId;
        //steps[index] = step;
      });
      steps.sort((a, b) => a.step - b.step);
    }

    //debugLog("steps: ", steps);
    handleStep(steps);
  });
};

export const createTask = async (task, kanbanId, user) => {
  debugLog("createVault user: ", user);
  const taskBody = {
    ...task,
    operation: "CREATE_TASK",
    createdAt: new Date(),
    userId: user.userId,
    stepId: "init",
    kanbanId,
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const createStep = async (step, kanbanId, user) => {
  const taskBody = {
    ...step,
    operation: "CREATE_STEP",
    kanbanId,
    createdAt: new Date(),
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const updateStep = async (step, user) => {
  const taskBody = {
    ...step,
    operation: "UPDATE_STEP",
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const updateTask = async (task, user) => {
  const taskBody = {
    ...task,
    operation: "UPDATE_TASK",
    userId: user.userId,
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const updateFieldTask = async (task, user) => {
  const taskBody = {
    ...task,
    operation: "UPDATE_FIELD_TASK",
    userId: user.userId,
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const deleteStep = async (step, user) => {
  const taskBody = {
    ...step,
    operation: "DELETE_STEP",
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const getkanbans = async (filter, user) => {
  const kanbanBody = {
    ...filter,
    operation: "GET_KANBAN",
  };
  try {
    let response = await handleTask(user.token, kanbanBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const addkanban = async (bodyKanban, user) => {
  const kanbanBody = {
    ...bodyKanban,
    operation: "ADD_KANBAN",
    createdAt: new Date(),
  };
  try {
    let response = await handleTask(user.token, kanbanBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
export const getTask = async (filter, user) => {
  const taskBody = {
    ...filter,
    operation: "GET_TASK",
  };
  try {
    let response = await handleTask(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
