import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Dialog, Box, TextField, CircularProgress, Typography, Card, CardActionArea, CardHeader,
  Avatar, Container, Button
} from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';

import { getExpedientTypes, getExpedientsByValue } from "../../services/backend/ExpedientService";
import { setExpedientTypes } from "../../redux/slices/expedientSlice";

let timeout;

const ExpedientModal = ({ open, onClose, onSelect }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const expedientTypes = useSelector((state) => state.expedient.expedientTypes);
  const [expedientType, setExpedientType] = useState();
  const [searchText, setSearchText] = useState('');
  const [expedients, setExpedients] = useState();

  const getExpedientsByValueAsync = async (value) => {
    if (!value) return setExpedients(undefined);

    setExpedients(null);
    const response = await getExpedientsByValue(value, expedientType.expedientTypeId, user);
    if (response) setExpedients(response);
    else setExpedients(undefined);
  };

  useEffect(() => {
    if (!expedientTypes) {
      getExpedientTypes(user).then((response) => dispatch(setExpedientTypes(response)));
    }

    if (!open) {
      setExpedients(undefined);
      setSearchText('');
    }
  }, [open]); // eslint-disable-line

  return (
    <Dialog
      maxWidth={false}
      open={open}
      onClose={onClose}
    >
      {expedientType ? (
        <Container maxWidth={false}>
          <Typography variant="h6">
            <Button
              variant="outlined"
              startIcon={<ArrowBack />}
              style={{ margin: 16 }}
              onClick={() => setExpedientType(undefined)}
            >
              Volver
            </Button>
            {expedientType.expedientTypeName}
          </Typography>
          <Box sx={{ textAlign: 'center', py: 3 }}>
            <TextField
              placeholder="Buscar expediente"
              value={searchText}
              onChange={(evt) => {
                setSearchText(evt.target.value);
                if (timeout) clearTimeout(timeout);
                timeout = setTimeout(() => getExpedientsByValueAsync(evt.target.value), 2000);
              }}
              onKeyDown={(evt) => {
                if (evt.key === 'Enter') {
                  if (timeout) clearTimeout(timeout);
                  getExpedientsByValueAsync(searchText);
                }
              }}
            />
          </Box>
          <Box sx={{ p: 1 }}>
            {expedients?.length === 0 && <Typography>No se encontraron expedientes con el criterio de búsqueda</Typography>}
            {expedients ? (
              <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", justifyContent: "space-around" }}>
                {expedients.map((expedient) => (
                  <Card
                    key={expedient.expedientId}
                    sx={{ cursor: 'pointer', m: 1, p: 1, width: 260 }}
                    onClick={() => onSelect(expedient)}
                  >
                    <CardActionArea>
                      <CardHeader
                        avatar={<Avatar>R</Avatar>}
                        title={expedient.expedientId}
                      />
                      <Box>{expedient.description}</Box>
                    </CardActionArea>
                  </Card>
                ))}
              </Box>
            ) : expedients === null ? (
              <Box sx={{ textAlign: "center", width: 1 }}>
                <CircularProgress />
              </Box>
            ) : (
              <Box sx={{ textAlign: "center", width: 1 }}>
                Realice una búsqueda para mostrar los expedientes que coincidan con la búsqueda
              </Box>
            )}
          </Box>
        </Container>
      ) : (
        <Container>
          {expedientTypes ? (
            <>
              <Typography sx={{ my: 3, textAlign: 'center' }}>
                Seleccione el tipo de expediente donde desea realizar la búsqueda
              </Typography>
              <Box sx={{ display: 'flex', my: 2 }}>
                {expedientTypes.map((type) => (
                  <Button
                    key={type.expedientTypeId}
                    onClick={() => setExpedientType(type)}
                    sx={{
                      alignItems: 'center',
                      boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
                      cursor: 'pointer',
                      display: 'flex',
                      flexDirection: 'column',
                      height: 120,
                      justifyContent: 'center',
                      margin: "auto",
                      p: 2,
                      transition: "background-color 0.3s",
                      width: 200,
                    }}
                  >
                    <Typography color='primary' sx={{ fontWeight: 'bold', mt: 2 }}>
                      Expedientes {type.expedientTypeName}
                    </Typography>
                  </Button>
                ))}
              </Box>
            </>
          ) :(
            <Box sx={{ textAlign: 'center', width: 1 }}>
              <CircularProgress />
            </Box>
          )}
        </Container>
      )}
    </Dialog>
  );
};

export default ExpedientModal;
