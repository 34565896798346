import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { loginUser } from "../../services/auth/AuthServices";
import { setUser } from "../../redux/slices/userSlice";
import { useNavigate, Link as RouterLink } from "react-router-dom";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

//importar hoja de estilos
import "./Login.css";

// Importa MUI components
import {
  Checkbox,
  Container,
  FormControlLabel,
  Typography,
  TextField,
  Button,
  Alert,
  CircularProgress,
} from "@mui/material";

import { debugLog } from "../../functions";

export default function Login() {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [keepSesion, setKeepSesion] = useState(true);
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Obtener la función navigate
  const [loading, setLoading] = useState(false); // Nuevo estado para controlar la carga
  // const [isAuthenticated, setIsAuthenticated] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Comenzar la carga

    debugLog("Email:", email);
    debugLog("Password:", password);
    setTimeout(async () => {
      // lógica de autenticación

      try {
        const response = await loginUser({ email, password, keepSesion });
        dispatch(setUser(response.user));
        setError("");
        // setIsAuthenticated(true);
        debugLog("Iniciado sesión exitosamente");
        navigate("/dashboard"); // Navegar al home autenticado
        setLoading(false); // Finalizar la carga
      } catch (error) {
        handleFirebaseError(error);
      }

      // const response = await loginUser({ email, password, keepSesion });
      // if (response == null) {
      //   alert("repuesta response: " + response);
      //   setLoading(false);
      //   return;
      // }
      //   if (response !== null && response.result) {
      //     dispatch(setUser(response.user));
      //     setError("");
      //     setIsAuthenticated(true);
      //     debugLog("Iniciado sesión exitosamente");
      //     navigate("/"); // Navegar al home autenticado
      //   } else {
      //     setError("Usuario o contraseña incorrectos");
      //     setLoading(false);
      //     debugLog("error en la autenticacion: ", response);
      //   }
      //   debugLog("response: ", response);
      //   setLoading(false); // Finalizar la carga
    }, 1000); // 2 segundos de delay para simular la validación
  };

  const handleFirebaseError = (error) => {
    let errorMessage;
    console.error(error.code);

    switch (error.code) {
      case "auth/invalid-email":
        errorMessage = "El formato del email es incorrecto.";
        break;
      case "auth/user-disabled":
        errorMessage = "Este usuario ha sido deshabilitado.";
        break;
      case "auth/user-not-found":
        errorMessage = "No existe un usuario con este correo electrónico.";
        break;
      case "auth/wrong-password":
        errorMessage = "La contraseña es incorrecta.";
        break;
      default:
        errorMessage = "Ha ocurrido un error al intentar autenticarse.";
    }
    setError(errorMessage);
    setLoading(false); // Finalizar la carga

    console.error(errorMessage);
  };

  return (
    <div className="container">
      <Container component="div" maxWidth="xs" className="form-container">
        <img
          src={`${process.env.PUBLIC_URL}/${process.env.REACT_APP_LOGOTIPO_LOGIN}`}
          alt="Logo de la App"
          className="app-logo"
        />

        <Typography component="h1" variant="h5">
          Iniciar Sesión
        </Typography>
        {error && (
          <Alert severity="error" className="error-alert">
            {error}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Correo Electrónico"
            name="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            name="password"
            label="Contraseña"
            type="password"
            id="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={keepSesion}
                size="small"
                onChange={(e) => setKeepSesion(e.target.checked)}
              />
            }
            label="Mantener Sesión"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            disabled={loading} // Deshabilitar el botón durante la carga
          >
            {loading ? <CircularProgress size={24} /> : "Ingresar"}
          </Button>
        </form>
        <Box mt={3} textAlign="center">
          <Link component={RouterLink} to="/password-reset">
            ¿Olvidaste tu contraseña?
          </Link>
        </Box>
      </Container>
    </div>
  );
}
