import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  Button,
  ButtonGroup,
  FormControl,
  Grid,
  Switch,
  TextField,
  Tooltip,
} from "@mui/material";
import ArrowBack from '@mui/icons-material/ArrowBack';
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

import { PERMISSIONS } from "../../constants";

const FiledItemFilter = ({ handleFilter, hasButton }) => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [filterCustom, setFilterCustom] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const handleChange = async () => {
    setFilterCustom(!filterCustom);
  };

  return (
    <Grid container margin={2} sx={{ alignItems: "center" }}>
      <Grid item sx={{ display: "flex", alignItems: "center" }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          style={{ margin: 16 }}
          onClick={() => navigate(-1)}
        >
          Volver
        </Button>
        <TextField
          id="filterbyFild"
          label="Buscar"
          variant="outlined"
          placeholder="Buscar Documentos"
          autoComplete="off"
          style={{ minWidth: 300 }}
        />
      </Grid>
      <Grid item>
        {filterCustom ? (
          <>
            <Grid item xs={4} marginRight={1}>
              <FormControl fullWidth label="Inicio">
                <DatePicker
                  label="Inicio"
                  defaultValue={(startDate && moment(startDate)) || undefined}
                  onChange={(momt) => setStartDate(momt)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={4}>
              <FormControl fullWidth label="Inicio">
                <DatePicker
                  label="Fin"
                  defaultValue={(endDate && moment(endDate)) || undefined}
                  onChange={(momt) => setEndDate(momt)}
                />
              </FormControl>
            </Grid>
            <Tooltip title="Buscar">
              <FilterAltIcon
                size="small"
                color="primary"
                onClick={() => handleFilter("CUSTOM", startDate, endDate)}
              />
            </Tooltip>
          </>
        ) : (
          <ButtonGroup variant="text" aria-label="text button group">
            <Button onClick={() => handleFilter("TODAY")}>Hoy</Button>
            <Button onClick={() => handleFilter("WEEK")}>Esta Semana</Button>
            <Button onClick={() => handleFilter("MONTH")}>Este Mes</Button>
            <Button onClick={() => handleFilter("LAST_MONTH")}>
              Mes Pasado
            </Button>
          </ButtonGroup>
        )}
        <Switch
          size="small"
          color="primary"
          checked={filterCustom}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Grid>
      {hasButton && user.permissions.includes(PERMISSIONS.FILED.CREATE) ? (
        <Grid item>
          <Button variant="contained" onClick={() => navigate("/filed")}>
            Radicar Nuevo Documento
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
};

export default FiledItemFilter;
