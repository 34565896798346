import { getAuth } from "firebase/auth";

import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";
import { server } from "../../config/ConfigFirebase";

const handleUploadFile = async (user, formData, retry) => {
  const response = await fetch("https://" + server + ".cloudfunctions.net/handleUploadFile", {
    method: "post",
    body: formData,
    headers: {
      Authorization: "Bearer " + user.token,
    },
  });

  if (response.status === 401 && !retry) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
    return await handleUploadFile(user, formData, true);
  }

  return response;
};

export const UploadFile = async (
  user,
  file,
  bucket,
  filePath,
  makeDocumentAI
) => {
  const formData = new FormData();
  formData.append("file", file);
  formData.append("bucket", bucket);
  formData.append("filePath", filePath);
  if (makeDocumentAI) formData.append("makeDocumentAI", "true");
  const response = await handleUploadFile(user, formData);
  if (response.status === 200) return await response.json();
  else return null;
};

export const DeleteFile = async (
  user,
  bucket,
  filePath
) => {
  const formData = new FormData();
  formData.append("action", "delete");
  formData.append("bucket", bucket);
  formData.append("filePath", filePath);
  const response = await handleUploadFile(user, formData);
  if (response.status === 200) return await response.json();
  else return null;
};
