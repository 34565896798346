import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Box,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography
} from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";

import {
  getCorrespondences,
  getRequestsFiledFilter,
  getRequestsFiledListOnSnapshot,
} from "../../services/backend/CorrespondenceService";
import FiledItem from "./FiledItem";
import FiledItemFilter from "./FiledItemFilter";
import { setCorrespondence } from "../../redux/slices/correspondenceSlice";
import { PERMISSIONS } from "../../constants";

let onsnapshot;
export default function FiledRequestsList() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const correspondences = useSelector((state) => state.correspondences.value);
  const [filedList, setFiledList] = useState(null);
  const [filedListFilter, setFiledListFilter] = useState(null);
  const [correspondenceSelected, setCorrespondenceSelected] = useState("all");

  const handleChangeCorrespondence = (event) => {
    setCorrespondenceSelected(event.target.value);
    updateFilter(filedList, event.target.value);
  };

  const updateFilter = (filedList, selected) => {
    if (selected === "all") {
      setFiledListFilter([...filedList]);
    } else {
      let filteredFiled = filedList.filter((filed) => {
        return filed.filedType === selected;
      });
      setFiledListFilter(filteredFiled);
    }
  };

  const handleCorrespondences = async () => {
    if (correspondences) return;
    const responseJSON = await getCorrespondences(user.token);
    dispatch(setCorrespondence(responseJSON));
  };

  const getListByDateFilter = async (filter, startDate1, endDate1) => {
    setFiledListFilter(null);
    const nowDate = new Date();
    let startDate = startDate1 ? startDate1.toDate() : new Date();
    let endDate = endDate1 ? endDate1.toDate() : new Date();
    if (onsnapshot) onsnapshot();
    switch (filter) {
      case "TODAY":        
        onsnapshot = getRequestsFiledListOnSnapshot(user, (fileds) => {
          setFiledList(fileds);
          updateFilter(fileds, correspondenceSelected);          
        });
        return;
      case "CUSTOM":
        break;
      case "WEEK":
        startDate = new Date(
          nowDate.setDate(nowDate.getDate() - nowDate.getDay())
        );
        endDate = new Date(
          nowDate.setDate(nowDate.getDate() - nowDate.getDay() + 6)
        );
        break;
      case "MONTH":
        startDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 1);
        endDate = new Date(nowDate.getFullYear(), nowDate.getMonth() + 1, 0);
        break;
      case "LAST_MONTH":
        startDate = new Date(nowDate.getFullYear(), nowDate.getMonth() - 1, 1);
        endDate = new Date(nowDate.getFullYear(), nowDate.getMonth(), 0);
        break;

      default:
        break;
    }

    startDate.setHours(0, 0, 59);
    endDate.setHours(23, 59, 59);
    const body = { filter: "CREATED_AT", startDate, endDate };

    const responseJSON = await getRequestsFiledFilter(user, body);

    setFiledList([...responseJSON]);
    updateFilter(responseJSON, correspondenceSelected);
  };

  useEffect(() => {
    if (!user.permissions.includes(PERMISSIONS.REQUESTSFILED.LIST)) return navigate(`/home`);
    if (!filedList) getListByDateFilter("TODAY");
    handleCorrespondences();
  }, []); // eslint-disable-line

  return (
    <Container maxWidth={false}>
      <FiledItemFilter handleFilter={getListByDateFilter} />
      
      {filedList?.length > 0 && (
        <Box sx={{ justifyContent: "space-between" }}>
          <FormControl sx={{ minWidth: 120 }}>
            <RadioGroup
              row
              name="controlled-radio-buttons-group"
              value={correspondenceSelected}
              onChange={handleChangeCorrespondence}
            >
              <FormControlLabel
                key="all"
                value="all"
                control={<Radio size="small" />}
                label="Todos"
              />
              {correspondences?.map((correspondence) => (
                <FormControlLabel
                  key={correspondence.correspondenceId}
                  value={correspondence.correspondenceId}
                  control={<Radio size="small" />}
                  label={correspondence.subserieName}
                />
              ))}
            </RadioGroup>
          </FormControl>
          <Badge badgeContent={filedListFilter?.length} color="primary">
            <DescriptionIcon color="action" />
          </Badge>
        </Box>
      )}
      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
        {filedListFilter ? (
          filedListFilter.length > 0 ? (
            filedListFilter.map((item) => (
              <FiledItem key={item.filedId} item={item} />
            ))
          ) : (
            <Typography>No hay ningún radicado con el filtro seleccionado</Typography>
          )
        ) : (
          <CircularProgress sx={{ margin: 'auto' }} />
        )}
      </Box>
    </Container>
  );
}
