import { getAuth } from "firebase/auth";

import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";
import { server } from "../../config/ConfigFirebase";
import { debugLog } from "../../functions";

const handleReport = async (token, body) => {
  const response = await fetch("https://" + server + ".cloudfunctions.net/handleReport", {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (response.status === 401) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
  }

  return response;
};

export const generateReport = async(data, user) => {
  try {
    const body = { action: data.action, data };
    let response = await handleReport(user.token, body);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getReports = async(user) => {
  try {
    const body = { action: "GET_REPORTS" };
    let response = await handleReport(user.token, body);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
