import React, { useState } from "react";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  TextField
} from "@mui/material";

const TagChangeModal = ({ open, elem, onClose }) => {
  const [value, setValue] = useState(elem?.innerHTML);

  const handleClose = (changed)  => {
    if (onClose) onClose(changed, value);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    const changed = elem.innerHTML !== value;
    elem.innerHTML = value;
    handleClose(changed, value);
  };

  return (
    <Dialog open={open} onClose={() => handleClose(false, value)}>
      <DialogTitle>Cambiar valor de {elem?.getAttribute('data-mce-tag')}</DialogTitle>

      <Container maxWidth={false}>
        <Box component="form" onSubmit={handleSubmit} sx={{ m: 2, mb: 2 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <TextField
                  label="Ingrese valor"
                  defaultValue={elem?.innerHTML}
                  onChange={(evt) => setValue(evt.target.value)}
                />
              </FormControl>
            </Grid>

            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={() => handleClose(false, value)}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: 1 }}
              >
                Cambiar valor
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};

export default TagChangeModal;
