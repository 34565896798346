import { firestore, server } from "../../config/ConfigFirebase";
import { collection, doc, onSnapshot, query, where } from "firebase/firestore";
import moment from "moment/moment";
import { debugLog } from "../../functions";

const handleFlow = async (token, body) => {
  return await fetch("https://" + server + ".cloudfunctions.net/handleFlow", {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });
};

export const getFlow = async (filter, user) => {
  const processesBody = {
    ...filter,
    operation: "GET_FLOWS",
  };
  debugLog("getFlow processesBody: ", processesBody);
  try {
    let response = await handleFlow(user.token, processesBody);
    let responseJSON = await response.json();
    responseJSON.forEach((flow) => {
      flow.createdAt = moment(flow.createdAt);
      flow.qualityDate = flow.qualityDate
        ? new Date(flow.qualityDate)
        : undefined;
      flow.actions?.forEach((action) => {
        action.signedAt = action.signedAt ? moment(action.signedAt) : undefined;
      });
    });
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const getFlowOnSnapshot = (flowId, handleFlow) => {
  let queryFlow = doc(firestore, "flows", flowId);
  return onSnapshot(queryFlow, (doc) => {
    if (doc.data()) {
      let actions = doc.data().actions;
      actions.forEach((action) => {
        action.signedAt = action.signedAt ?
          moment(typeof action.signedAt === 'string' ? action.signedAt : action.signedAt.toDate())
          : undefined;        
      });
      const flow = {
        flowId,
        ...doc.data(),
        createdAt: doc.data().createdAt?.toDate(),
        qualityDate: doc.data().qualityDate?.toDate(),
        actions,
      };
      //debugLog("FLOW: ", flow);
      handleFlow(flow);
    }
  });
};

export const getFlowsOnSnapshot = (userId, handleFlow) => {
  let queryFlows;
  if (userId) {
    queryFlows = query(
      collection(firestore, "flows"),
      where("userId", "==", userId)
    );
  } else {
    queryFlows = query(
      collection(firestore, "flows"),
      where("status", "==", "inProgress")
    );
  }

  return onSnapshot(queryFlows, (snapshot) => {
    let flows = [];
    snapshot.forEach((doc) => {
      let actions = doc.data().actions;
      actions.forEach((action) => {
        action.signedAt = action.signedAt ?
          moment(typeof action.signedAt === 'string' ? action.signedAt : action.signedAt.toDate())
          : undefined;        
      });
      const newFlow = {
        flowId: doc.id,
        ...doc.data(),
        actions,
        createdAt: moment(doc.data().createdAt.toDate()),
        qualityDate: moment(doc.data().qualityDate.toDate()),
      };
      //debugLog("newFlow: ", newFlow);
      flows.push(newFlow);
    });
    flows.sort((a, b) => b.createdAt - a.createdAt);
    handleFlow(flows);
  });
};

export const addFlow = async (body, user) => {
  const createdAt = new Date();
  const processesBody = {
    ...body,
    createdAt,
    status: "inProgress",
    operation: "CREATE_FLOW",
    userId: user.userId,
  };
  try {
    let response = await handleFlow(user.token, processesBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const updateFlow = async (flow, user) => {
  const taskBody = {
    operation: "UPDATE_FLOW",
    ...flow,
  };
  try {
    let response = await handleFlow(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const cancelFlow = async (flow, user) => {
  const taskBody = {
    ...flow,
    operation: "CANCEL_FLOW" // Override operation in flow
  };
  try {
    let response = await handleFlow(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const deleteFlow = async (flowId, user) => {
  const taskBody = {
    flowId,
    operation: "DELETE_FLOW",
  };
  try {
    let response = await handleFlow(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const doneActionFlow = async (flow, user) => {
  const taskBody = {
    ...flow,
    operation: "DONE_ACTION_FLOW",
    userId: user.userId,
    doneAt: new Date(),
  };
  try {
    let response = await handleFlow(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const rejectActionFlow = async (flow, user) => {
  const taskBody = {
    ...flow,
    operation: "REJECT_ACTION_FLOW",
    userId: user.userId,
    doneAt: new Date(),
    rejectedAt: new Date(),
  };
  try {
    let response = await handleFlow(user.token, taskBody);
    let responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const createPDF = async (flow, user) => {
  const body = {
    flowId: flow.flowId,
    userId: user.userId,
  };

  try {
    const response = await fetch(
      "https://" + server + ".cloudfunctions.net/handlePdfProcess",
      {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    );
    const responseJSON = await response.json();
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const handlePdfProcess = async (flow, user) => {
  const body = { flowId: flow.flowId };

  try {
    const response = await fetch(
      `${process.env.REACT_APP_CONTAINER}/Document/handlePdfProcess`,
      {
        method: "post",
        body: JSON.stringify(body),
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + user.token,
        },
      }
    );
    const responseJSON = await response.json();
    debugLog(responseJSON);
    return responseJSON;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
