import React from "react";
import { Checkbox, CircularProgress, FormControl, FormControlLabel, Grid, TextField } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";

export default function ProcessHeader({
  editTemplate,
  editSubject,
  process,
  setProcess,
}) {
  const handleChangeProcess = (e) => {
    setProcess({
      ...process,
      [e.target.name]: e.target.value,
    });
  };

  return process ? (
    <Grid container spacing={1} sx={{ mb: 2 }}>
      <Grid item xs={6}>
        <TextField
          id="processName"
          name="processName"
          label="Nombre del Proceso"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={process.processName}
          onChange={handleChangeProcess}
          disabled={!editTemplate}
        />
      </Grid>
      <Grid item xs={3}>
        <TextField
          id="qualityCode"
          name="qualityCode"
          label="Código Calidad"
          variant="outlined"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={process.qualityCode}
          onChange={handleChangeProcess}
          disabled={!editTemplate}
        />
      </Grid>
      <Grid item xs={3}>
        <FormControl fullWidth label="Inicio">
          <DatePicker
            label="Fecha Calidad"
            defaultValue={
              (process.qualityDate && moment(process.qualityDate)) || undefined
            }
            onChange={(momt) =>
              setProcess({
                ...process,
                qualityDate: momt,
              })
            }
            disabled={!editTemplate}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12}>
        <TextField
          id="description"
          name="description"
          label="Descripción"
          variant="outlined"
          multiline
          rows={2}
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={process.description}
          onChange={handleChangeProcess}
          disabled={!editTemplate && editSubject !== undefined}
        />
      </Grid>
      {(!process.subject && editSubject !== false)
        && <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="isPublic"
                name="isPublic"
                onChange={(evt) => handleChangeProcess({ target: {
                  name: evt.target.name, value: evt.target.checked
                } })}
                defaultChecked={process.isPublic}
              />
            }
            label="Hacerlo público"
          />
        </Grid>
      }
      {editSubject !== undefined
        && <Grid item xs={12}>
          <TextField
            id="subject"
            name="subject"
            label="Objetivo"
            variant="outlined"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={process.subject || process.subjet}
            onChange={handleChangeProcess}
            required
            disabled={!editSubject}
          />
        </Grid>
      }
    </Grid>
  ) : (
    <CircularProgress />
  );
}
