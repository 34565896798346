import { useEffect, useState} from "react";
import {
  TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Paper,
  CircularProgress, Container, Box
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import moment from 'moment';
import { onSnapshot, collection } from "firebase/firestore";

import { firestore } from "../../config/ConfigFirebase";
import { sortDesc } from "../../functions";

const sources = {
  FILED_REPORT: 'RADICADOS'
};

const ReportList = () => {
  const [reports, setReports] = useState();

  useEffect(() => {
    const unsuscribe = onSnapshot(collection(firestore, 'reports'), (querySnapshot) => {
      const rows = [];
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        rows.push({
          ...data,
          createdAt: data.createdAt.toDate(),
          startAt: data.startAt.toDate(),
          endAt: data.endAt.toDate()
        });
      });
      setReports(rows);
    });

    return () => unsuscribe();
  }, []);

  return (
    <Container>
      {!reports && <Box sx={{ textAlign: "center" }}><CircularProgress /></Box>}
      {reports && reports.length === 0 && "No se ha generado ningún reporte"}
      {reports && reports.length > 0
        && <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Fuente</TableCell>
                <TableCell>Fecha de generación</TableCell>
                <TableCell>Rango de fecha</TableCell>
                <TableCell>Filas</TableCell>
                <TableCell>Descargar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reports.sort(sortDesc("createdAt")).map((item, idx) => (
                <TableRow key={idx} sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
                  <TableCell>
                    {sources[item.action]}
                  </TableCell>
                  <TableCell>
                    {moment(item.createdAt).format('LL')}
                  </TableCell>
                  <TableCell>
                    {moment(item.startAt).format('LL')} - {moment(item.endAt).format('LL')}
                  </TableCell>
                  <TableCell>
                    {item.rows}
                  </TableCell>
                  <TableCell>
                    <a href={item.downloadUrl} download>
                      <CloudDownloadIcon color='primary' size={20} />
                    </a>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    </Container>
  );
};

export default ReportList;