import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTasksByUserOnSnapshot } from "../../services/backend/TaskService";
import {
  CircularProgress,
  Container,
  Box,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Switch,
  Typography,
  FormControlLabel
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBack from '@mui/icons-material/ArrowBack';

import TaskItem from "./TaskItem";
import TaskReview from "./TaskReview";

const TaskList = () => {
  const navigate = useNavigate();
  const user = useSelector((state) => state.user.value);
  const [tasks, setTasks] = useState();
  const [showDone, setShowDone] = useState(false);
  const [selectedTask, setSelectedTask] = useState();

  useEffect(() => {
    const subscribe = getTasksByUserOnSnapshot(user.userId, setTasks);
    return () => subscribe();
  }, []); // eslint-disable-line

  return (
    <Container sx={{ my: 2 }}>
      <Typography variant='h4'>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          style={{ margin: 16 }}
          onClick={() => navigate(-1)}
        >
          Volver
        </Button>
        Mis tareas
      </Typography>
      <TaskReview open={!!selectedTask} selectedTask={selectedTask} onClose={() => setSelectedTask(undefined)} />
      <FormControlLabel sx={{ my: 3 }} control={
        <Switch onChange={(evt) => setShowDone(evt.target.checked)} />
      } label="Mostrar tareas completadas" />

      {tasks ? (
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="Actions">
            <TableHead>
              <TableRow>
                <TableCell>Objetivo</TableCell>
                {/* <TableCell>Area</TableCell> */}
                <TableCell>Responsable</TableCell>
                {/* <TableCell>Prioridad</TableCell> */}
                <TableCell>Completar</TableCell>
                <TableCell>Ir a Tarea</TableCell>
                {showDone && <TableCell>Completeada</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.filter((task) => showDone || task.status !== 'done')?.map((task) => (
                <TaskItem key={task.taskId} task={task} showDone={showDone} setTask={setSelectedTask} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Box sx={{ textAlign: 'center', mt: 3 }}>
          <CircularProgress size={36} />
        </Box>
      )}
    </Container>
  );
};

export default TaskList;
