import * as React from "react";
import Header from "./Header";
import Task from "../task/Task";

export default function LayoutTask() {
  return (
    <>
      <Header />
      <Task />
    </>
  );
}
