import React, { useMemo } from "react";
import {
  Modal, Box, Typography, Grid, FormControl, TextField,
  Select, MenuItem, IconButton, InputLabel,
  Tooltip
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MoneyInput from "../generic/MoneyInput";
import moment from "moment";
import currency from "currency.js";
import InfoIcon from '@mui/icons-material/Info';
import AutoModeIcon from "@mui/icons-material/AutoMode";

import { ARL, SALARIO_MINIMO, UVT } from "../../constants";

const modalStyle = {
  bgcolor: "background.paper",
  boxShadow: 24,
  left: "50%",
  maxWidth: "980px",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%"
};

const PaymentPlanModal = ({
  contract, setPaymentPlan, setArlLevel, open, onClose
}) => {
  const balance = useMemo(
    () => contract.contractBudget - contract.paymentPlan?.reduce((a, b) => (a = a + b), 0),
    [contract.contractBudget, contract.paymentPlan]
  );

  const getRangeDate = (idx) => {
    let startedAt = contract.startedAt;
    
    let finishedAt;
    if (idx === 0) {
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    } else if (idx === contract.paymentPlan.length - 1) {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = contract.finishedAt;
    } else {
      startedAt = moment(new Date(startedAt.year(), startedAt.month() + idx, 1))
      finishedAt = moment(new Date(startedAt.year(), startedAt.month() + 1, 0));
    }

    return `${startedAt.format('MMM Do')} - ${finishedAt.format('MMM Do')}`;
  };

  const UpdatePaymentPlan = () => {
    const startedAt = new Date(contract.startedAt)

    const payment = [];
    if (contract.salary) {
      payment.push((30 - startedAt.getDate() + 1) * (contract.salary / 30));
      while (payment.reduce((a, b) => a = a + b, 0) + contract.salary < contract.contractBudget) {
        payment.push(contract.salary);
      }
      if (payment.length < 12) {
        payment.push(contract.contractBudget - payment.reduce((a, b) => a = a + b, 0));
      }
    }

    setPaymentPlan(payment);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: 'flex' }}>
          <Typography variant="h5" sx={{ flex: 1 }}>Plan de pagos a contratista</Typography>
          {contract.status !== 'APPROVED'
            && <IconButton onClick={UpdatePaymentPlan}>
              <AutoModeIcon />
            </IconButton>
          }
        </Box>
        <Box sx={{ color: "gray" }}>
          Objeto del contrato: {contract.contractCode}
        </Box>
        <Grid container spacing={1} sx={{ my: 3 }}>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <DatePicker
                label="Fecha de inicio"
                defaultValue={contract.startedAt}
                readOnly
              />
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <DatePicker
                label="Fecha de terminación"
                defaultValue={contract.finishedAt}
                readOnly
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <TextField
                required
                type="number"
                label="Duración (En días)"
                defaultValue={contract.contractPeriod}
                InputProps={{ readOnly: true }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <MoneyInput
                readOnly
                label="Valor de contrato"
                value={contract.contractBudget}
              />
            </FormControl>
          </Grid>
          <Grid item xs={2}>
            <FormControl fullWidth>
              <InputLabel id="arl-level">Riesgo ARL</InputLabel>
              <Select
                labelid="arl-level"
                label="Riesgo ARL"
                value={contract.arlLevel}
                onChange={(evt) => setArlLevel(evt.target.value)}
              >
                {Object.keys(ARL).map((key) => (
                  <MenuItem key={key} value={key}>{ARL[key].text}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 1, backgroundColor: "#efefef", borderRadius: 1 }}>
              <Typography variant="h6" sx={{
                color: balance < 0 ? "#af635d"
                  : balance > 1 ? "#a78316" : "#5e8d5e"
              }}>
                Balance: {currency(balance, { precision: 0 }).format()}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container rowSpacing={2} style={{ maxHeight: 480, overflowY: 'auto' }}>
          {contract.paymentPlan?.map((pay, idx) => {
            const payment = (pay * 0.4) > SALARIO_MINIMO ? (pay * 0.4) : SALARIO_MINIMO;

            return (
              <React.Fragment key={idx}>
                <Grid item xs={2}>
                  <Typography sx={{ whiteSpace: "nowrap", mr: 2 }}>Mes {idx + 1}: </Typography>
                  <Typography sx={{ color: "gray", fontSize: "14px" }}>({getRangeDate(idx)})</Typography>
                </Grid>
                <Grid item xs={3}>
                  <MoneyInput
                    label="Ingrese el valor de pago correspondiente"
                    value={pay}
                    readOnly={contract.status === 'APPROVED'}
                    onChange={(evt) => {
                      const plan = [...contract.paymentPlan];
                      plan[idx] = evt.target.value;
                      setPaymentPlan(plan);
                    }}
                  />
                </Grid>
                <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                  <MoneyInput
                    label="Pago Seguridad Social"
                    value={payment * (0.125 + 0.16 + parseFloat(ARL[contract.arlLevel].value))}
                    disabled
                  />
                  <Tooltip
                    title={(
                      <Grid container sx={{ p: 1, fontSize: 16 }}>
                        <Grid item xs={6}>Salud</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(payment * 0.125, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>Pensión</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(payment * 0.16, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>ARL</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(payment * parseFloat(ARL[contract.arlLevel].value), { precision: 0 }).format()}
                        </Grid>
                      </Grid>
                    )}
                  >
                    <IconButton sx={{ marginLeft: -4 }}>
                      <InfoIcon sx={{ color: "gray", fontSize: 20 }}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid item xs={3} sx={{ display: "flex", alignItems: "center" }}>
                  <MoneyInput
                    label="Estampillas"
                    value={pay * (0.004 + (pay > 145 * UVT ? 0.01 : 0) + 0.02 + 0.006 + 0.004 + (pay > 300 * UVT ? 0.004 : 0))}
                    disabled
                  />
                  <Tooltip
                    title={(
                      <Grid container sx={{ p: 1, fontSize: 16 }}>
                        <Grid item xs={6}>D59 (IU Env)</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(pay * 0.004, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>D63 (Pro Hosp)</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {pay > 145 * UVT ? currency(pay * 0.01, { precision: 0 }).format() : 0}
                        </Grid>
                        <Grid item xs={6}>D87 (Pro Bien)</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(pay * 0.02, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>D88 (Pro Dllo)</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(pay * 0.006, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>D89 (Politécnico)</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {currency(pay * 0.004, { precision: 0 }).format()}
                        </Grid>
                        <Grid item xs={6}>D124 (IU Digital)</Grid>
                        <Grid item xs={6} sx={{ textAlign: "right" }}>
                          {pay > 300 * UVT ? currency(pay * 0.004, { precision: 0 }).format() : 0}
                        </Grid>
                      </Grid>
                    )}
                  >
                    <IconButton sx={{ marginLeft: -4 }}>
                      <InfoIcon sx={{ color: "gray", fontSize: 20 }}/>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </React.Fragment>
            )
          })}
        </Grid>
      </Box>
    </Modal>
  );

};

export default PaymentPlanModal;
