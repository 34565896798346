import * as React from "react";
import Header from "./Header";
import { MigrationList } from "../migration/MigrationList";

export default function LayoutMigration() {
  return (
    <>
      <Header />
      <MigrationList />
    </>
  );
}
