import React, { useEffect, useState } from "react";
import {
  Modal, Box, Typography, IconButton, Grid,
  TextField, Checkbox
} from "@mui/material";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';

import { completionsCreate } from "../../services/backend/OpenAIServices";
import { debugLog } from "../../functions";

const modalStyle = {
  bgcolor: "background.paper",
  boxShadow: 24,
  left: "50%",
  maxWidth: "980px",
  maxHeight: "680px",
  overflow: "scroll",
  p: 4,
  position: "absolute",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%"
};

const TasksModal = ({
  contract, noEdit, setTasks, setOpenBackdrop, open, onClose
}) => {
  const [inputTask, setInputTask] = useState('');
  const [indexTask, setIndexTask] = useState(null);
 
  const getTasks = async () => {
    if (!contract.documentText) return console.log('No hay text de documento');

    setOpenBackdrop("Recuperando obligaciones del contrato");

    const messages = [
      { role: 'user', content: 'De acuerdo al siguiente texto:' },
      { role: 'user', content: `""" ${contract.documentText} """` },
      { role: 'user', content: 'Extrae la lista completa, de las obligaciones generales y específicas del contratista y escribe sin ningún comentario adicional ni saltos de página un formato JSON , que no haya errores al ser parseado a objeto Javascript. Ejemplo de salida esperada: [ "Obligación 1", "Obligación 2"]' }
    ];

    const response = await completionsCreate({ messages });
    try {
      const tasks = JSON.parse(response.choices[0].message.content);
      setTasks(tasks.map(title => ({ title, followup: true })));
    } catch (err) {
      debugLog(err);
    }

    setOpenBackdrop(undefined);
  };

  const handleAdd = () => {
    if (!inputTask) return;
    const taks = { title: inputTask, followup: true };

    if (indexTask !== null) {
      const tasks = [...contract.tasks];
      tasks[indexTask] = taks;
      setTasks(tasks);
    } else {
      setTasks([taks, ...contract.tasks]);
    }

    setInputTask('');
    setIndexTask(null);
  };

  const handleDelete = (idx) => {
    const tasks = [...contract.tasks];
    tasks.splice(idx, 1);
    setTasks(tasks);
  };

  useEffect(() => {
    if (contract.tasks.length > 0 && !contract.tasks[0].title) {
      setTasks(contract.tasks.map(title => ({ title, followup: true })));
    }
  }, [contract.tasks, setTasks]);

  return (
    <Modal
      open={open}
      onClose={onClose}
    >
      <Box sx={modalStyle}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h5" sx={{ flex: 1 }}>Obligaciones del contratista</Typography>
          {!noEdit
            && <IconButton onClick={getTasks}>
              <AutoModeIcon />
            </IconButton>
          }
        </Box>
        {!noEdit
          && <Box sx={{ display: "flex", my: 2, backgroundColor: "#efefef", p: 2, borderRadius: 2 }}>
            <TextField
              fullWidth
              label="Escribe la obligación del contratista"
              size="small"
              value={inputTask}
              onChange={(evt) => setInputTask(evt.currentTarget.value)}
            />
            <IconButton onClick={handleAdd}>
              {indexTask !== null ? <CheckCircleOutlineIcon /> : <AddCircleOutlineIcon />}
            </IconButton>
            {indexTask !== null
              && <IconButton onClick={() => {
                setInputTask('');
                setIndexTask(null);
              }}>
                <CloseIcon />
              </IconButton>
            }
          </Box>
        }
        <Grid container spacing={1} sx={{ textAlign: "center", my: 1 }}>
          <Grid item xs={10}>
            <Typography variant="h6">Obligación</Typography>
          </Grid>
          {!noEdit
            && <Grid item xs={2}>
              <Typography variant="h6">Acciones</Typography>
            </Grid>
          }
        </Grid>
        {contract.tasks.map((task, idx) => (
          <Grid container key={idx} sx={{
            border: 'thin solid lightgray',
            padding: 1,
            my: 1,
            textAlign: "center"
          }}>
            <Grid item xs={10} sx={{ textAlign: "left" }}>{task.title}</Grid>
            {!noEdit
              && <Grid item xs={2}>
                <IconButton onClick={() => handleDelete(idx)}>
                  <DeleteIcon />
                </IconButton>
                <IconButton onClick={() => {
                  setInputTask(task.title);
                  setIndexTask(idx);
                }}>
                  <EditIcon />
                </IconButton>
                {task.title
                  && <Checkbox
                    checked={task.followup}
                    onChange={(__, value) => {
                      const tsks = [...contract.tasks];
                      tsks[idx] = { title: task.title, followup: value };
                      setTasks(tsks);
                    }}
                  />
                }
              </Grid>
            }
          </Grid>
        ))}
      </Box>
    </Modal>
  );
};

export default TasksModal;
