import React from "react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

const ProcessTemplateItem = ({
  processTemplateId, processName, description, qualityCode, type
}) => {
  const navigate = useNavigate();

  return (
    <Box
      xs={4}
      sx={{
        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
        cursor: 'pointer',
        m: 3,
        p: 3,
        position: "relative",
        width: 400
      }}
      onClick={() => navigate(`/processesTemplate/${processTemplateId}`)}
    >
      <Box sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        margin: 1
      }}>
        {qualityCode}
      </Box>
      <Box sx={{
        position: 'absolute',
        top: 0,
        right: 0  ,
        margin: 1
      }}>Código: <i>{type}</i></Box>
      <Box sx={{ mt: 3 }}>Nombre: <strong>{processName}</strong></Box>
      <Box sx={{ mt: 1, color: 'gray' }}>{description}</Box>
    </Box>
  )
};

export default ProcessTemplateItem;

