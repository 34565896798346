import * as React from "react";
import Header from "./Header";
import { Home } from "../home/Home";

export default function LayoutHome() {
  return (
    <>
      <Header />
      <Home />
    </>
  );
}
