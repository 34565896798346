import React from "react";
import { Box } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

const title = {
  internal: "COMUNICACIÓN INTERNA",
  external: "COMUNICACIÓN DESPACHADA",
  received: "COMUNICACIÓN RECIBIDA",
};

const FiledViewItem = ({ item }) => (
  <Box xs={4} sx={{ p: 1, width: 520 }}>
    <Box
      sx={{
        border: "thin solid lightgray",
        borderRadius: 1,
        boxShadow: "0px 0px 2px #ccc",
        display: "flex",
        flexDirection: "row",
        fontSize: "12px",
        height: 120,
        p: 1,
      }}
    >
      <a href={item.fileUrl} download target="blank">
        <Box
          sx={{
            alignItems: "center",
            backgroundColor: "lightgray",
            display: "flex",
            justifyContent: "center",
            height: 120,
            cursor: "pointer",
            position: 'relative',
            width: 100,
          }}
        >
          <img
            alt='PDF'
            src={item.thumbUrl || '/pdf.png'}
            height={120}
            style={{ position: 'absolute', maxWidth: 90, objectFit: 'contain' }}
          />
          <DownloadForOfflineIcon sx={{ color: "rgba(0,0,0,.3)" }} />
        </Box>
      </a>
      <Box
        sx={{
          ml: 1,
          width: "100%",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>{title[item.filedType]}</Box>
          <Box>{`No. ${item.filedId}`}</Box>
        </Box>
        <Box sx={{ flex: 1 }}>{item.subject}</Box>
        <Box sx={{ display: "flex" }}>
          <Box sx={{ flex: 1 }}>{item.createdAt.format("LL, h:mm:ss a")}</Box>
          <Box>
            {`No. folios ${item.folios}`}
            {item.status === "DONE" && (
              <LockIcon sx={{ ml: 1 }} fontSize="10" />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  </Box>
);

export default FiledViewItem;
