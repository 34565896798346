import * as React from "react";
import Header from "./Header";
import Config from "../config/Config";

export default function LayoutConfig() {
  return (
    <>
      <Header />
      <Config />
    </>
  );
}
