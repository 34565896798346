import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Dialog } from "@mui/material";
import LockIcon from "@mui/icons-material/Lock";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const title = {
  internal: "COMUNICACIÓN INTERNA",
  external: "COMUNICACIÓN DESPACHADA",
  received: "COMUNICACIÓN RECIBIDA",
};

const FiledItem = ({ item }) => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const handleClick = () => {
    if (item.status === "DONE") {
      navigate(`/filed/view/${item.referenceId}`);
    } else if (item.status !== "CANCELLED" && item.status !== "PDF") {
      navigate(`/filed/${item.filedId}`);
    }
  };

  return (
    <>
      <Dialog open={isOpen} onClose={() => setIsOpen(false)}>
        <img alt="Filed preview" src={item.thumbUrl} width={520} style={{ maxWidth: '100%' }} />
      </Dialog>
      <Box
        className="box-filed-item"
        xs={4}
        sx={{ p: 1, position: "relative" }}
        onClick={handleClick}
      >
        <Box
          sx={{
            border: "thin solid lightgray",
            borderRadius: 1,
            boxShadow: "0px 0px 2px #ccc",
            display: "flex",
            flexDirection: "row",
            fontSize: "12px",
            height: 120,
            p: 1,
          }}
        >
          <Box sx={{ width: 100, height: 120, backgroundColor: 'lightgray', border: 'thin dashed gray' }}>
            <img
              alt="Filed preview"
              src={item.thumbUrl || '/pdf.png'}
              height={120}
              style={{ backgroundColor: 'white', maxWidth: 90, objectFit: 'contain' }}
              onClick={(evt) => {
                if (!item.thumbUrl) return;
                evt.stopPropagation();
                setIsOpen(true);
              }}
            />
          </Box>
          <Box
            sx={{
              ml: 1,
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {item.status !== "CANCELLED" ? (
              <>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ flex: 1 }}>{title[item.filedType]}</Box>
                  <Box>{`No. ${item.filedId}`}</Box>
                </Box>
                <Box sx={{ flex: 1 }}>{item.subject}</Box>
                <Box sx={{ display: "flex" }}>
                  <Box sx={{ flex: 1, alignItems: "flex-start" }}>
                    {item.createdAt.format("LL, h:mm:ss a")}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    {`No. folios ${item.folios}`}
                    {item.status === "DONE" && (
                      <LockIcon sx={{ ml: 1 }} fontSize="10" />
                    )}
                    {item.status === "PDF" && (
                      <Box sx={{
                        alignItems: 'center',
                        display: 'flex',
                        position: 'absolute',
                        justifyContent: 'center',
                        top: '40%',
                        left: '40%',
                      }}>
                        <span>Construyendo...</span>
                        <PictureAsPdfIcon sx={{ ml: 1, color: 'red' }} />
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            ) : (
              <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box sx={{ flexGrow: 1 }}>{`No. ${item.filedId}`}</Box>
                <Box
                  sx={{
                    position: "absolute",
                    top: "40%",
                    left: "40%",
                    fontSize: 30,
                    fontWeight: "bold",
                    transform: "rotate(-20deg)",
                    color: "rgba(0,0,0,.1)",
                  }}
                >
                  ANULADA
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default FiledItem;
