import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const CircularProgressTitle = ({ title }) => (
  <Box position="relative" display="inline-flex">
    <CircularProgress color="primary" />
    <Box
      top={0}
      left={0}
      bottom={0}
      right={0}
      position="absolute"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Typography variant="caption" component="div" color="textSecondary">
        {title}
      </Typography>
    </Box>
  </Box>
);

export default CircularProgressTitle;
