import OpenAI from 'openai';

const openai = new OpenAI({
  organizaton: process.env.REACT_APP_OPENAI_ORG,
  apiKey: process.env.REACT_APP_OPENAI_APIKEY,
  dangerouslyAllowBrowser: true
});

export const completionsCreate = async ({
  model = 'gpt-3.5-turbo', max_tokens = 1024, messages
}) => {
  const response = await openai.chat.completions.create({
    model, max_tokens, messages
  });

  return response;
};
