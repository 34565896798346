import * as React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton
} from '@mui/material';

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';

const ActionItemTable = ({ actions, onRemove }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="Actions">
        <TableHead>
          <TableRow>
            <TableCell>actionName</TableCell>
            <TableCell>actionNamePP</TableCell>
            <TableCell>status</TableCell>
            <TableCell>addTask</TableCell>
            <TableCell>signed</TableCell>
            <TableCell>step</TableCell>
            <TableCell>type</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {actions.map((action) => (
            <TableRow
              key={action.actionName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{action.actionName}</TableCell>
              <TableCell>{action.actionNamePP}</TableCell>
              <TableCell>{action.status}</TableCell>
              <TableCell>
                {action.addTask ? <CheckCircleIcon color='success' /> : <RemoveCircleIcon color='error' />}
              </TableCell>
              <TableCell>
                {action.signed ? <CheckCircleIcon color='success' /> : <RemoveCircleIcon color='error' />}
              </TableCell>
              <TableCell>{action.step}</TableCell>
              <TableCell>{action.type}</TableCell>
              <TableCell>
                <IconButton aria-label="delete" onClick={() => onRemove(action.actionName)}>
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ActionItemTable;
