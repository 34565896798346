import React, { useEffect } from "react";
import {
  Modal,
  Box,
  Button,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { getProcessesTemplates } from "../../services/backend/ProcessService";
import CircularProgress from "../generic/CircularProgress";
import { setProcessTemplates } from "../../redux/slices/processSlice";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ProcessesTemplateListModal = ({ open, onClose }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const processesTemplate = useSelector((state) => state.process.processTemplates);

  useEffect(() => {
    (async () => {
      if (user && open && !processesTemplate) {
        const responseJSON = await getProcessesTemplates({ filter: "ALL" }, user);
        dispatch(setProcessTemplates(responseJSON));
      }
    })();
  }, [open, processesTemplate, user, dispatch]);

  return (
    <Modal
      open={open}
      onClose={(evt) => onClose(false)}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Seleccionar proceso
        </Typography>
        <List>
          {!processesTemplate && <CircularProgress />}
          {processesTemplate?.filter(p => !p.disabled).map((process, index) => (
            <ListItem
              key={index}
              onClick={(evt) => onClose(true, process)}
            >
              <ListItemButton>
                <ListItemText
                  primary={process.processName}
                  secondary={process.description}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Button onClick={(evt) => onClose(false)}>Cerrar</Button>
      </Box>
    </Modal>
  );
};

export default ProcessesTemplateListModal;
