import React from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";

const UploadFile = ({ accept, handleFileUpload }) => {
  const handleUploadFile = (e) => {
    //e.preventDefault();
    let newFiles = e.target.files;
    handleFileUpload(newFiles);
    e.target.value = "";
  };
  return (
    <label htmlFor="upload-file">
      <AttachFileIcon color="primary" />
      <input
        id="upload-file"
        hidden
        accept={accept}
        type="file"
        onChange={handleUploadFile}
      />
    </label>
  );
};

export default UploadFile;
