import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  TextField,
  Chip
} from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ModeEditIcon from '@mui/icons-material/ModeEdit';

import { getPeople, getReciversUser } from "../../services/backend/UserService";
import { setPeople, setUsers } from "../../redux/slices/userSlice";
import UserModal from "../generic/UserModal";
import { sortAsc } from "../../functions";

const SelectUser = ({
  multiple,
  userType,
  value = null,
  setValue,
  disabled,
  noedit,
  placeholder,
  filter,
  onChange
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.value);
  const users = useSelector((state) => {
    if (!state.user.users) return null;
    if (!filter) return state.user.users;
    return state.user.users.filter(u => u.roles?.some(r => filter.includes(r)));
  });
  const people = useSelector((state) => state.user.people);
  const [userModal, setUserModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [titleModal, setTitleModal] = useState();

  useEffect(() => {
    if (!users) {
      (async () => {
        const response = await getReciversUser(user.token);
        dispatch(setUsers(response.users.sort(sortAsc('name'))));
      })();
    }
    if (!people) {
      (async () => {
        const response = await getPeople(user.token);
        dispatch(setPeople(response.people.sort(sortAsc('name'))));
      })();
    }
  }, []); // eslint-disable-line

  if (!users || !people) return null;
  return (
    <>
      <Autocomplete
        multiple={multiple}
        options={userType === "users" ? users : people}
        value={value || (multiple ? [] : null)}
        getOptionLabel={(option) => `${option.name} ${option.lastName} <${option.email}>`}
        isOptionEqualToValue={(option, value) =>
          option.email === value?.email
        }
        disabled={disabled}
        sx={{ width: !multiple && value ? "calc(100% - 80px)" : "calc(100% - 50px)" }}
        renderInput={(params) => (
          <TextField {...params} label={placeholder} />
        )}
        onChange={onChange}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              sx={{ cursor: "pointer" }}
              onClick={() => {
                if (noedit) return;
                setOpenModal(true);
                setTitleModal(userType === "users" ? "Modificar Interno" : "Modificar Externo");
                setUserModal(option);
              }}
              label={(
                <div>{`${option.name} ${option.lastName}`} <small>{`<${option.email}>`}</small> </div>
              )}
              {...getTagProps({ index })}
            />
          ))
        }
      />
      <PersonAddIcon
        color={noedit ? "disabled" : "primary"}
        onClick={() => {
          if (noedit) return;
          setOpenModal(true);
          setTitleModal(userType === "users" ? "Crear Interno" : "Crear Externo");
        }}
        sx={{
          position: "absolute",
          right: "15px",
          top: "15px",
          cursor: "pointer",
        }}
      />
      {!multiple && value && !noedit &&
        <ModeEditIcon
          color={noedit ? "disabled" : "primary"}
          onClick={() => {
            if (noedit) return;
            setOpenModal(true);
            setTitleModal(userType === "users" ? "Modificar Interno" : "Modificar Externo");
            setUserModal(value);
          }}
          sx={{
            position: "absolute",
            right: "45px",
            top: "15px",
            cursor: "pointer",
          }}
        />
      }
      <UserModal
        title={titleModal}
        open={openModal}
        user={userModal}
        userType={userType}
        onSubmit={(userData) => {
          let vals = userData;

          if (multiple) {
            vals = [...value];
            if (!userData.isNew) {
              for (let i = 0; i < vals.length; i++) {
                if (vals[i].userId === userData.userId || vals[i].peopleId === userData.peopleId) {
                  vals[i] = userData;
                  break;
                }
              }
            } else {
              vals.push(userData);
            }
          }

          setValue(vals);
        }}
        onClose={() => {
          setOpenModal(false);
          setUserModal(undefined);
          setTitleModal(undefined);
        }}
      />
    </>
  );
};

export default SelectUser;
