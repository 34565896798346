import { getAuth } from "firebase/auth";

import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";
import { server } from "../../config/ConfigFirebase";
import { debugLog } from "../../functions";
import { PERMISSIONS } from "../../constants";

const handleExpedient = async (token, body) => {
  const response = await fetch("https://" + server + ".cloudfunctions.net/handleExpedient", {
    method: "post",
    body: JSON.stringify(body),
    headers: {
      "Content-Type": "application/json",
      Authorization: "Bearer " + token,
    },
  });

  if (response.status === 401) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
  }

  return response;
};

export const addExpedientType = async(expedientType, user) => {
  try {
    const userBody = { action: "CREATE_EXPEDIENT_TYPE", data: expedientType };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getExpedientTypes = async(user) => {
  try {
    const userBody = {
      action: "GET_EXPEDIENT_TYPES",
      ...(!user.permissions.includes(PERMISSIONS.EXPEDIENT.LIST) ? { owner: user.userId } : {})
    };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getExpedientType = async(expedientTypeId, user) => {
  try {
    const userBody = { action: "GET_EXPEDIENT_TYPE", expedientTypeId };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const addExpedient = async(expedient, user) => {
  try {
    const userBody = { action: "CREATE_EXPEDIENT", data: expedient };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getExpedients = async(user, owner) => {
  try {
    const userBody = { action: "GET_EXPEDIENTS", owner };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getExpedientsByValue = async(value, expedientTypeId, user) => {
  try {
    const userBody = { action: "EXPEDIENTS_BY_VALUE", expedientTypeId, value };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getExpedient = async(expedientId, user) => {
  try {
    const userBody = { action: "GET_EXPEDIENT", expedientId };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const addDocument = async(document, user) => {
  try {
    const userBody = { action: "CREATE_DOCUMENT", data: document };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getDocuments = async(expedientId, user) => {
  try {
    const userBody = { action: "DOCUMENTS_BY_FIELD", field: 'expedientId', value: expedientId};
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const updateDocument = async(document, user) => {
  try {
    const userBody = { action: "UPDATE_DOCUMENT", documentId: document.documentId, data: document };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};

export const getTRD = async(user) => {
  try {
    const userBody = { action: "GET_TRD" };
    let response = await handleExpedient(user.token, userBody);
    response = await response.json();
    if (response.result) {
      return response.body;
    } else {
      return null;
    }
  } catch (error) {
    debugLog(error);
    return null;
  }
};
