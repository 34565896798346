import React, { useState } from "react";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  TextField,
} from "@mui/material";

import SelectUser from "../generic/SelectUser";

const FormTagsModal = ({ open, tags, defaultValue = {}, onClose, onSubmit }) => {
  const [values, setValues] = useState(defaultValue);

  const handleClose = ()  => {
    if (onClose) onClose();
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    let filledValues = { ...values };

    Object.keys(values).forEach((key) => {
      if (typeof values[key] === "object") {
        const anchors = {};
        Object.keys(tags[key].anchors).forEach((anchor) => {
          anchors[anchor] = values[key][tags[key].anchors[anchor]];
        });
        filledValues = { ...filledValues, ...anchors };
      }
    });

    if (onSubmit) onSubmit(filledValues);
    handleClose();
  };

  const getControl = (tagValue) => {
    let field;
    const tag = tags[tagValue];

    switch (tag.type) {
      case "users":
        field = (
          <SelectUser
            noedit={true}
            userType={tag.type}
            value={values[tagValue]}
            setValue={(value) => {
              const vals = {...values};
              vals[tagValue] = value;
              setValues(vals);
            }}
            placeholder={tag.label}
            onChange={(__, value) => {
              const vals = {...values};
              vals[tagValue] = value;
              setValues(vals);
            }}
          />
        )
        break;

      case "people":
        field = (
          <SelectUser
            userType={tag.type}
            value={values[tagValue]}
            setValue={(value) => {
              const vals = {...values};
              vals[tagValue] = value;
              setValues(vals);
            }}
            placeholder={tag.label}
            onChange={(__, value) => {
              const vals = {...values};
              vals[tagValue] = value;
              setValues(vals);
            }}
          />
        )
        break;

      case "string":
        field = (
          <TextField
            label={tagValue}
            value={values[tagValue] || ""}
            onChange={(evt) => {
              const vals = {...values};
              vals[tagValue] = evt.target.value;
              setValues(vals);
            }}
          />
        );
        break;
      
      default:
        field = null;
    }
    return field;
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Ingresar datos de plantilla</DialogTitle>

      <Container maxWidth={false}>
        <Box component="form" onSubmit={handleSubmit} sx={{ m: 2, mb: 2 }}>
          <Grid container spacing={2}>

            {Object.keys(tags).map((tag) => (
              <Grid item xs={12} key={tag}>
                <FormControl fullWidth>
                  {getControl(tag)}
                </FormControl>
              </Grid>
            ))}

            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleClose}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                sx={{ marginLeft: 1 }}
              >
                Llenar tags
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};

export default FormTagsModal;
