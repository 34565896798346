import { server } from "../../config/ConfigFirebase";
import moment from "moment/moment";
import { getAuth } from "firebase/auth";
import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";
import { debugLog } from "../../functions";

const handleProcessTemplate = async (token, body) => {
  const response = await fetch(
    "https://" + server + ".cloudfunctions.net/handleProcessTemplate",
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );

  if (response.status === 403) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
  }

  return response;
};

export const getProcessesTemplate = async (user, filter = {}) => {
  try {
    const body = { ...filter, action: 'GET_ALL' };
    let response = await handleProcessTemplate(user.token, body);
    response = await response.json();
    return response;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const getProcessTemplate = async (processTemplateId, user) => {
  try {
    const filter = {
      action: 'BY_ID',
      value: processTemplateId
    };
    
    let response = await handleProcessTemplate(user.token, filter);
    response = await response.json();
    response.qualityDate = moment(response.qualityDate);    
    return response;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const createProcessTemplate = async (processTemplate, user) => {
  try {
    const filter = {
      action: 'CREATE_PROCESS_TEMPLATE',
      ...processTemplate
    };
    
    let response = await handleProcessTemplate(user.token, filter);
    response = await response.json();
    return response;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};

export const updateProcessTemplate = async (processTemplate, user) => {
  try {
    const filter = {
      action: 'UPDATE_PROCESS_TEMPLATE',
      ...processTemplate
    };
    
    let response = await handleProcessTemplate(user.token, filter);
    response = await response.json();
    return response;
  } catch (error) {
    debugLog("error: ", error);
    return null;
  }
};
