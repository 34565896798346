import React from "react";
import { Avatar, Box, Chip, Typography } from "@mui/material";

import BorderColorIcon from "@mui/icons-material/BorderColor";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DoneAllIcon from "@mui/icons-material/DoneAll";
import ChecklistIcon from "@mui/icons-material/Checklist";
import GradingIcon from "@mui/icons-material/Grading";

const ActionFlowItem = ({ flow, flowItem, handleOpenTask }) => {
  return (
    <Box sx={{ p: 0.3, position: "relative" }} key={flowItem?.name}>
      <Box
        sx={{
          border: "thin solid lightgray",
          borderRadius: 1,
          boxShadow: "0px 0px 2px #ccc",
          display: "flex",
          flexDirection: "row",
          fontSize: "14px",
          p: 1,
        }}
      >
        <Avatar alt="Remy Sharp" src="/static/images/avatar/1.jpg" />
        <Box
          sx={{
            ml: 1,
            display: "flex",
            flexDirection: "column",
            width: "35%",
          }}
        >
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle2" gutterBottom>
              {flowItem?.name + " " + flowItem?.lastName}
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Typography variant="caption" gutterBottom>
              {flowItem?.position}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            ml: 2,
            display: "flex",
            width: "20%",
          }}
        >
          {flowItem?.type === "create" ? (
            <BorderColorIcon color="primary" sx={{ fontSize: 25 }} />
          ) : flowItem?.type === "elaborate" ? (
            <EditNoteIcon color="primary" sx={{ fontSize: 30 }} />
          ) : flowItem?.type === "approve" ? (
            <GradingIcon color="primary" sx={{ fontSize: 25 }} />
          ) : flowItem?.type === "review" ? (
            <ChecklistIcon color="primary" sx={{ fontSize: 25 }} />
          ) : flowItem?.type === "sign" ? (
            <DoneAllIcon color="primary" sx={{ fontSize: 30 }} />
          ) : (
            <BorderColorIcon color="primary" sx={{ fontSize: 30 }} />
          )}

          <Box sx={{ ml: 1 }}>{flowItem?.actionName}</Box>
        </Box>
        {flow?.status !== 'cancelled'
          && <Box
            sx={{
              ml: 2,
              display: "flex",
              flexDirection: "column",
            }}
          >
            {flowItem?.tasks ? (
              flowItem.tasks.map((thisTask, idx) => (
                <Box key={idx} sx={{ flex: 1 }}>
                  {thisTask.status === "done" ? (
                    <Chip
                      size="small"
                      label="Finalizada"
                      color="success"
                      onClick={(env) =>
                        thisTask.taskId ? handleOpenTask(thisTask.taskId) : null
                      }
                    />
                  ) : thisTask.status === "pending" ? (
                    <Chip
                      size="small"
                      label="Pendiente"
                      color="primary"
                      variant="outlined"
                    />
                  ) : thisTask.status === "inProgress" ? (
                    <Chip
                      size="small"
                      label="En Progreso"
                      color="success"
                      variant="outlined"
                      onClick={(env) =>
                        thisTask.taskId ? handleOpenTask(thisTask.taskId) : null
                      }
                    />
                  ) : thisTask.status === "rejected" ? (
                    <Chip
                      size="small"
                      label="Devolver"
                      color="error"
                      variant="outlined"
                      onClick={(env) =>
                        thisTask.taskId ? handleOpenTask(thisTask.taskId) : null
                      }
                    />
                  ) : (
                    <Chip
                      size="small"
                      label="Cancelada"
                      color="error"
                      onClick={(env) =>
                        thisTask.taskId ? handleOpenTask(thisTask.taskId) : null
                      }
                    />
                  )}
                </Box>
              ))
            ) : (
              <Box sx={{ flex: 1 }}>
                <Chip
                  size="small"
                  label="Pendiente"
                  color="primary"
                  variant="outlined"
                />
              </Box>
            )}
          </Box>
        }
        <Box
          sx={{
            ml: 2,
            display: "flex",
          }}
        >
          <Box sx={{ flex: 1 }}>
            {flowItem?.signedAt
              ? flowItem.signedAt.format("LL, h:mm:ss a")
              : ""}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ActionFlowItem;
