import React from 'react';
import { TextField } from '@mui/material';
import { NumericFormat } from 'react-number-format';

const CurrencyFormat = React.forwardRef(({ onChange, ...props}, ref) => (
  <NumericFormat
    {...props}
    getInputRef={ref}
    thousandSeparator
    prefix="$"
    decimalScale={0}
    fixedDecimalScale
    onValueChange={(values) => {
      onChange({
        target: {
          name: props.name,
          value: values.floatValue
        },
      });
    }}
  />
));

const MoneyInput = (props) => (
  <TextField
    {...props}
    InputProps={{
      inputComponent: CurrencyFormat,
      readOnly: props.readOnly
    }}
  />
);

export default MoneyInput;