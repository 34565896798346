import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Container, Grid, Box, FormControl, TextField, Button, Typography,
  CircularProgress, Select, MenuItem, InputLabel
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";

import RichEditor from '../generic/rich-editor';
import TagItem from "./actions/TagItem";
import AddNewNameModal from "./actions/AddNewNameModal";
import {
  createProcessTemplate, getProcessTemplate, updateProcessTemplate
} from "../../services/backend/ProcessTemplateService";
import MyBackdrop from "../generic/MyBackdrop";
import MySnackbar from "../generic/MySnackbar";
import ActionItemTable from "./actions/ActionItemTable";
import { PERMISSIONS } from "../../constants";
import { getCorrespondences } from "../../services/backend/CorrespondenceService";
import { setCorrespondence } from "../../redux/slices/correspondenceSlice";
import { genString } from '../../functions';

const defaultActions = [
  {
    "signed": true,
    "owners": [],
    "step": 1,
    "actionNamePP": "Creado",
    "type": "create",
    "actionName": "Crear",
    "addTask": false,
    "status": "done",
    "tasks": [{ "status": "done" }]
  },
  {
    "isFirst": true,
    "signed": false,
    "owners": [],
    "step": 2,
    "type": "elaborate",
    "actionNamePP": "Elaborado",
    "actionName": "Elaborar",
    "addTask": true,
    "status": "pending"
  },
  {
    "signed": false,
    "owners": [],
    "step": 3,
    "actionNamePP": "Revisado",
    "type": "review",
    "actionName": "Revisar",
    "addTask": true,
    "status": "pending"
  },
  {
    "signed": false,
    "owners": [],
    "actionNamePP": "Aprobado",
    "type": "approve",
    "addTask": true,
    "actionName": "Aprobar",
    "status": "pending",
    "step": 4
  },
  {
    "isLast": true,
    "signed": false,
    "owners": [],
    "step": 5,
    "actionNamePP": "Firmado",
    "type": "sign",
    "status": "pending",
    "actionName": "Firmar",
    "addTask": true
  }
]

const ProcessTemplate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { processTemplateId } = useParams();
  const user = useSelector((state) => state.user.value);
  const correspondences = useSelector((state) => state.correspondences.value);
  const [qualityCode, setQualityCode] = useState('');
  const [qualityDate, setQualityDate] = useState(moment(new Date()));
  const [processName, setProcessName] = useState('');
  const [description, setDescription] = useState('');
  const [type, setType] = useState('');
  const [tags, setTags] = useState({});
  const [actions, setActions] = useState(defaultActions);
  const [showTagModal, setShowTagModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [editorRef, setEditorRef] = useState();
  const [file, setFile] = useState();
  const [infoSnack, setInfoSnack] = useState({ open: false });

  const dataSource = useMemo(() => {
    const keys = Object.keys(tags);
    let data = {};

    keys.forEach((key) => {
      if (!['users', 'people'].includes(tags[key].type)) {
        data[key] = `<<${key}>>`;
      } else {
        const anchs = { ...tags[key].anchors };
        const anchors = {};
        Object.keys(anchs).forEach((k) => {
          anchors[k] = `<<${k}>>`;
        })
        data = { ...data, ...anchors };
      }
    });

    return data;
  }, [tags]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    if (!file.url) {
      setInfoSnack({
        open: true,
        message: 'Primero debe guardar el archivo desde el editor',
        severity: 'error'
      });
      return;
    }

    if (editorRef.hasUnsavedChanges) editorRef.saveDocument();

    setLoading(true);

    const processTemplate = {
      qualityCode,
      qualityDate,
      processName,
      description,
      type,
      actions,
      template: {
        file,
        tags,
        type
      }
    }

    if (processTemplateId === 'new') {
      await createProcessTemplate(processTemplate, user);
    } else {
      processTemplate.processTemplateId = processTemplateId;
      await updateProcessTemplate(processTemplate, user);
    }
    
    setLoading(false);
    navigate(`/processesTemplate`);
  };

  const handleRemove = (name) => {
    setActions(actions.filter((act) => act.actionName !== name));
  };

  const handleEditorSave = (url) => {
    if (!file.url) setFile({ ...file, url });
  };

  useEffect(() => {
    (async () => {
      if (!correspondences) {
        getCorrespondences(user.token).then((responseJSON) => dispatch(setCorrespondence(responseJSON)));
      }

      if (processTemplateId !== 'new') {
        if (!user.permissions.includes(PERMISSIONS.TEMPLATE.UPDATE)) return navigate('/processesTemplate');

        const response = await getProcessTemplate(processTemplateId, user);
        setQualityCode(response.qualityCode);
        setQualityDate(response.qualityDate);
        setProcessName(response.processName);
        setDescription(response.description);
        setType(response.type);
        setFile(response.template.file);
        setTags(response.template.tags);
        setActions(response.actions);
      } else {
        if (!user.permissions.includes(PERMISSIONS.TEMPLATE.CREATE)) return navigate('/processesTemplate');
        const name = genString();
        setFile({
          name,
          path: `templates/${name}`,
          url: undefined
        });
      }
     })();
  }, []); // eslint-disable-line

  return (
    <>
      <AddNewNameModal
        title='Tag'
        open={showTagModal}
        onClose={() => setShowTagModal(false)}
        onSubmit={(name) => {
          const newTags = { ...tags };
          newTags[name] = {};
          setTags(newTags);
        }}
      />
      <MyBackdrop open={processTemplateId !== 'new' && !qualityCode} />
      <MySnackbar
        info={infoSnack}
        handleClose={() => setInfoSnack({ open: false })}
      />
      
      <Container maxWidth={false} style={{ padding: 3 }}>
        <Grid container sx={{ mb: 1 }}>
          <Grid item xs={5} sx={{ p: 1 }}>
            <Box component="form" onSubmit={handleSubmit}>
              <Grid container spacing={1}>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ marginY: 1 }}>
                    <TextField
                      label="Código de calidad"
                      value={qualityCode}
                      onChange={(evt) => setQualityCode(evt.target.value)}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth sx={{ my: 1 }}>
                    <DatePicker
                      label="Fecha de Calidad"
                      value={qualityDate}
                      onChange={(momt) => setQualityDate(momt)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ marginY: 1 }}>
                    <TextField
                      label="Nombre del proceso"
                      value={processName}
                      onChange={(evt) => setProcessName(evt.target.value)}
                      required
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth sx={{ marginY: 1 }}>
                    <TextField
                      label="Descripción"
                      value={description}
                      onChange={(evt) => setDescription(evt.target.value)}
                      rows={3}
                      fullWidth
                      multiline
                      required
                    />
                  </FormControl>
                  {correspondences
                    && <FormControl fullWidth sx={{ marginY: 1 }}>
                      <InputLabel id="filed-type-label">Tipo</InputLabel>
                      <Select
                        labelId="filed-type-label"
                        label="Tipo"
                        required
                        value={type}
                        onChange={(evt) => setType(evt.target.value)}
                      >
                        {correspondences.map((corr) => (
                          <MenuItem
                            key={corr.correspondenceId}
                            value={corr.correspondenceId}
                          >
                            {corr.correspondenceId}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  }
                </Grid>
              </Grid>
              <Grid item xs={12} sx={{ mt: 3, pl: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Tags</Typography>
                  <Button variant="contained" onClick={() => setShowTagModal(true)}>
                    +
                  </Button>
                </Box>
                {Object.keys(tags).map((key) => (
                  <TagItem
                    key={key}
                    name={key}
                    {...tags[key]}
                    onChange={(tag) => {
                      const newTags = { ...tags };
                      newTags[tag.name] = tag;
                      setTags(newTags);
                    }}
                    onRemove={(name) => {
                      const newTags = { ...tags };
                      delete newTags[name];
                      setTags(newTags);
                    }}
                  />
                ))}
              </Grid>
              <Grid item xs={12} sx={{ mt: 3, pl: 1 }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Typography variant="h6">Acciones</Typography>
                  <Button variant="contained">+</Button>
                </Box>
                <ActionItemTable actions={actions} onRemove={handleRemove} />
              </Grid>
              <Grid item xs={12}>
                <Button
                  sx={{ mt: 2 }}
                  variant="contained"
                  type='submit'
                  disabled={loading}
                >
                  {loading ? <CircularProgress size={24} /> : 'Guardar'}
                </Button>
                <Button
                  sx={{ mt: 2, ml: 2 }}
                  variant="contained"
                  onClick={() => navigate('/processesTemplate')}
                >
                  Cancelar
                </Button>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={7}>
            {file &&
              <RichEditor
                file={file}
                dataSource={dataSource}
                setEditorRef={(edt) => setEditorRef(edt)}
                onSave={handleEditorSave}
              />
            }
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ProcessTemplate;
