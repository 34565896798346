import React from "react";
import { Box, CircularProgress as BaseCircularProgress } from "@mui/material";

const CircularProgress = ({ p = 3 }) => (
  <Box sx={{ p, textAlign: "center"}}>
    <BaseCircularProgress />
  </Box>
);

export default CircularProgress;
