import { getAuth } from "firebase/auth";

import store from "../../redux/store";
import { setUserToken } from "../../redux/slices/userSlice";

import { firestore, server } from "../../config/ConfigFirebase";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { debugLog } from "../../functions";

export const uploadfileAPI = async (token, formData, retry = false) => {
  const response = await fetch("https://" + server + ".cloudfunctions.net/uploadFile", {
    method: "post",
    body: formData,
    headers: {
      Authorization: "Bearer " + token,
    },
  });

  if (response.status === 401 && !retry) {
    const auth = getAuth();
    const token = await auth.currentUser.getIdToken();
    store.dispatch(setUserToken(token));
    return await uploadfileAPI(token, formData, true);
  }

  return response;
};

export const fileSystemHandler = async (token, body) => {
  return await fetch(
    "https://" + server + ".cloudfunctions.net/handleExpedients",
    {
      method: "post",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    }
  );
};

export const getExpedients = async (collectionName, user) => {
  const body = {
    action: "GET_EXPEDIENTS",
    collectionName,
    allowToSee: [user.userId, user.dependence],
    userId: user.userId,
  };
  debugLog("getFiles request: ", body);
  const response = await fileSystemHandler(user.token, body);
  let responseJSON = await response.json();
  debugLog("getFiles response: ", responseJSON);
  return responseJSON;
};
export const addExpedient = async (bodyExpedient, user) => {
  const body = {
    action: "ADD_EXPEDIENT",
    ...bodyExpedient,
    allowToSee: [user.dependence, user.userId],
    owners: [user.dependence, user.userId],
    userId: user.userId,
  };
  debugLog("addExpedient request: ", body);
  const response = await fileSystemHandler(user.token, body);
  let responseJSON = await response.json();
  debugLog("addExpedient response: ", responseJSON);
  return responseJSON;
};
export const updateExpedient = async (bodyExpedient, user) => {
  const body = {
    action: "UPDATE_EXPEDIENT",
    ...bodyExpedient,
  };
  debugLog("updateExpedient request: ", body);
  const response = await fileSystemHandler(user.token, body);
  let responseJSON = await response.json();
  debugLog("updateExpedient response: ", responseJSON);
  return responseJSON;
};
export const updateExpedientConfig = async (bodyExpedient, user) => {
  const body = {
    action: "UPDATE_EXPEDIENT_CONFIG",
    ...bodyExpedient,
  };
  debugLog("updateExpedientConfig request: ", body);
  const response = await fileSystemHandler(user.token, body);
  let responseJSON = await response.json();
  debugLog("updateExpedientConfig response: ", responseJSON);
  return responseJSON;
};
export const addFileExpedient = async (bodyExpedient, user) => {
  const body = {
    action: "ADD_FILE_EXPEDIENT",
    ...bodyExpedient,
    userId: user.userId,
  };
  debugLog("addExpedient request: ", body);
  const response = await fileSystemHandler(user.token, body);
  let responseJSON = await response.json();
  debugLog("addExpedient response: ", responseJSON);
  return responseJSON;
};

export const uploadFile = async (
  token,
  file,
  correspondenceId,
  metadata,
  bucket,
  fileName,
  isFiled
) => {
  let formData = new FormData();
  formData.append("file", file);
  formData.append("metadata", JSON.stringify(metadata));
  formData.append("correspondenceId", correspondenceId);
  formData.append("bucket", bucket);
  formData.append("fileName", fileName);
  formData.append("isFiled", isFiled);
  const response = await uploadfileAPI(token, formData);
  let responseJSON = await response.json();
  return responseJSON;
};

export const deleteFile = async (token, bucket, fileName) => {
  let formData = new FormData();
  formData.append("fileName", fileName);
  formData.append("bucket", bucket);
  formData.append("action", "delete");
  const response = await uploadfileAPI(token, formData);
  let responseJSON = await response.json();
  return responseJSON;
};

export const getExtensionFile = (fileName) => {
  const index = fileName.lastIndexOf(".");
  if (index > -1) {
    let extension = fileName.substring(index);
    return extension;
  }
  return null;
};

export const getExpedientsOnSnapshot = (
  collectionName,
  userId,
  handleExpedients
) => {
  let queryExpedients = query(
    collection(firestore, collectionName),
    where("allowToSee", "array-contains", userId)
  );
  return onSnapshot(queryExpedients, (snapshot) => {
    let expedients = [];
    snapshot.forEach((doc) => {
      let files = doc.data().files ? doc.data().files : [];
      files?.map(function (file) {
        file.createdAt = file.createdAt?.toDate();
        return file;
      });
      const newExpedient = {
        fileId: doc.id,
        ...doc.data(),
        isOwner: doc.data().owners?.find((user) => user === userId)
          ? true
          : false,
        files,
        createdAt: doc.data().createdAt?.toDate(),
      };
      expedients.push(newExpedient);
    });
    expedients.sort((a, b) => b.fileId - a.fileId);
    debugLog("expedients: ", expedients);
    handleExpedients(collectionName, expedients);
  });
};

export const getExpedientsConfigOnSnapshot = (handleExpedients) => {
  return onSnapshot(collection(firestore, "expedients"), (snapshot) => {
    let expedients = [];
    snapshot.forEach((doc) => {
      const newExpedient = {
        expedientId: doc.id,
        ...doc.data(),
      };
      expedients.push(newExpedient);
    });
    expedients.sort((a, b) => b.fileId - a.fileId);
    debugLog("expedients: ", expedients);
    handleExpedients(expedients);
  });
};

export const getUsersbase = (users, usersSelected) => {
  let newUsers = [];
  usersSelected.forEach((userSelected) => {
    const findUser = users.find((user) => user.userId === userSelected);
    if (findUser) newUsers.push(findUser);
  });
  return newUsers;
};
