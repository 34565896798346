import {
  browserLocalPersistence,
  EmailAuthProvider,
  getAuth,
  reauthenticateWithCredential,
  setPersistence,
  signInWithEmailAndPassword,
  signOut,
  updateProfile,
  updatePassword,
  sendPasswordResetEmail
} from "firebase/auth";
import { userAuth } from "../../config/ConfigFirebase";
import { getUser } from "../backend/UserService";
import { debugLog } from "../../functions";

export const resetPasswordWhithEmail = async (email) => {
  try {
    await sendPasswordResetEmail(userAuth, email);
    debugLog('Correo de restablecimiento enviado!');
  } catch (error) {
    console.error('Hubo un error al enviar el correo:', error);
  }
};

export const logout = () => {
  debugLog("deslogueado desde el service");
  signOut(userAuth)
    .then(() => {
      // Sign-out successful.
      debugLog("Sign-out successful");
    })
    .catch((error) => {
      // An error happened.
      debugLog("signOut Error: ", error);
    });
};

export const loginUser = async (user) => {
  if (user) {
    if (user.email && user.password) {
      try {
        let userCredential = null;
        if (user.rememberMe) {
          userCredential = await handlePersistence(
            userAuth,
            user.email,
            user.password
          );
        } else {
          userCredential = await signInWithEmailAndPassword(
            userAuth,
            user.email,
            user.password
          );
        }
        debugLog("llegue al servicio de login: ");


        const userLog = userCredential.user;
        debugLog("user: ", userLog);

        return getUserDB(userLog);
      } catch (error) {
        debugLog("error: ", error);
        return null;
      }
    }
  }
  return null;
};

const handlePersistence = async (user) => {
  setPersistence(userAuth, browserLocalPersistence)
    .then(() => {
      // Existing and future Auth states are now persisted in the current
      // session only. Closing the window would clear any existing state even
      // if a user forgets to sign out.
      // ...
      // New sign-in will be persisted with session persistence.
      return signInWithEmailAndPassword(userAuth, user.email, user.password);
    })
    .catch((error) => {
      debugLog(error)
    });
};

export const getUserLogin = async (user) => {
  if (user) {
    const userLogin = await loginUser(user);
    debugLog("userLogin: ", userLogin);
    return getUserDB(userLogin);
  }
};

export const getUserUID = async (userLogin) => {
  return getUserDB(userLogin);
};

export const getUserDB = async (userLogin) => {
  //debugLog("userLogin: ", userLogin);
  if (userLogin) {
    const token = await getToken();
    return await getUser(token, userLogin.uid);
  } else {
    return null;
  }
};

export const changeUserPassword = async (currentPass, newPassword) => {
  try {
    const reAuth = await reauthenticateUser(currentPass);
    if (reAuth) {
      //debugLog("reauthenticateWithCredential OK: ");
      await updatePassword(userAuth.currentUser, newPassword);
      return true;
    } else {
      return false;
    }
  } catch (error) {
    debugLog("changeUserPassword ERROR: ", error);
    return false;
  }
};

export const reauthenticateUser = async (currentPass) => {
  try {
    const currentUser = getAuth().currentUser;
    const email = currentUser.providerData[0].email;
    const credential = EmailAuthProvider.credential(email, currentPass);
    await reauthenticateWithCredential(userAuth.currentUser, credential);
    return true;
  } catch (error) {
    debugLog("reauthenticateUser ERROR: ", error);
    return false;
  }
};

/*export const registerUser = async (userName, password, tokenId) => {
  //debugLog("userLogin: ", userLogin);
  if (userName && password && tokenId) {
    const userBody = {
      action: "REGISTER_USER",
      userName,
      password,
      tokenId,
    };
    let response = await handleUser(userBody);
    debugLog("responseJSON status: ", response.status);
    let responseJSON = await response.json();
    return responseJSON;
  } else {
    return null;
  }
};

export const checkTokenId = async (tokenId) => {
  //debugLog("userLogin: ", userLogin);
  if (tokenId) {
    const userBody = {
      action: "CHECK_TOKEN_ID",
      tokenId,
    };
    let response = await handleUser(userBody);
    let responseJSON = await response.json();
    if (responseJSON.result) {
      return true;
    }
    return false;
  } else {
    return false;
  }
};*/

export const updateUser = (fieldName, fieldValue) => {
  if (fieldName && fieldValue) {
    updateProfile(userAuth.currentUser, {
      [fieldName]: fieldValue,
    })
      .then(() => {
        // Profile updated!
        debugLog("rofile updated! ");
      })
      .catch((error) => {
        // An error occurred
        debugLog("error: ", error);
      });
  }
};

export const getToken = async () => {
  try {
    let idToken = await userAuth.currentUser?.getIdToken(true); // forceRefresh

    return idToken;
  } catch (error) {
    debugLog("Error: ", error);
    return null;
  }
};

export const verifyToken = async (idToken) => {
  if (idToken) {
    userAuth
      .verifyIdToken(idToken)
      .then(() => {
        return true;
      })
      .catch((error) => {
        debugLog("Error storeData.removeItem: ", error);
        return false;
      });
  } else {
    return false;
  }
};
