import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Box, Button, CircularProgress, Dialog, FormControl, Grid, InputLabel,
  MenuItem, Select, TextField, Typography
} from "@mui/material";

import { addExpedientType } from "../../services/backend/ExpedientService";
import SelectUser from "../generic/SelectUser";
import SelectTRD from "../generic/SelectTRD";

const defaultFields = {
  expedientTypeId: "",
  expedientTypeName: "",
  status: "actived",
  icon: "",
  trd: null,
  dependence: null,
  serie: null,
  subserie: null,
  owners: []
};

const ExpedientTypeFormModal = ({
  open, expedientType, onClose, onSubmit
}) => {
  const user = useSelector((state) => state.user.value);
  const [loading, setLoading] = useState(false);
  const [fields, setFields] = useState({ ...defaultFields });
  const [owners, setOwners] = useState([]);

  const handleSubmit = async (evt) => {
    evt.preventDefault();

    setLoading(true);
    const response = await addExpedientType(fields, user);
    if (response && onSubmit) onSubmit(response);
    setLoading(false);
    onClose();
  };

  useEffect(() => {
    if (expedientType) setFields(expedientType);
  }, [expedientType]);

  useEffect(() => {
    if (!open) setFields(defaultFields);
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <Box component="form" onSubmit={handleSubmit} sx={{ m: 2 }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h6">
              {expedientType ? "Modificar tipo de expediente" : "Crear tipo de expediente"}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                required
                label="Identificador de tipo"
                name="expedientTypeId"
                value={fields.expedientTypeId}
                onChange={(evt) => setFields({ ...fields, expedientTypeId: evt.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={8}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                required
                label="Tipo de expediente"
                name="expedientTypeName"
                value={fields.expedientTypeName}
                onChange={(evt) => setFields({ ...fields, expedientTypeName: evt.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <InputLabel id="expedient-status">Estado</InputLabel>
              <Select
                labelId='expedient-status'
                label="Estado"
                value={fields.status}
                onChange={(evt) => setFields({ ...fields, status: evt.target.value })}
              >
                <MenuItem value="actived">Activo</MenuItem>
                <MenuItem value="disabled">Inactivo</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <TextField
                label="Ícono"
                name="icon"
                value={fields.icon}
                onChange={(evt) => setFields({ ...fields, icon: evt.target.value })}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <SelectTRD onChange={(value) => setFields({
              ...fields,
              trd: value,
              dependence: value?.dependence.code,
              serie: value?.serie.code,
              subserie: value?.subserie.code
            })} />
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth sx={{ marginY: 1 }}>
              <SelectUser
                noedit={true}
                userType="users"
                placeholder="Permisos de acceso"
                multiple
                value={owners}
                onChange={(__, ows) => {
                  setOwners(ows);
                  setFields({ ...fields, owners: ows.map((o) => o.userId) })
                }}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sx={{ mt: 2 }}>
            <Button
              variant="contained"
              type="submit"
              disabled={!fields.expedientTypeId || !fields.expedientTypeName || !fields.trd || loading}
            >
              {loading ? (
                <CircularProgress size={26} />
              ) : (
                <>
                  {expedientType ? "Modificar" : "Crear"} tipo de expediente
                </>
              )}
            </Button>
            <Button
              variant="outlined"
              sx={{ ml: 1 }}
              disabled={loading}
              onClick={() => onClose()}
            >
              Cancelar
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
};

export default ExpedientTypeFormModal;
