import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Container,
  Box,
  Grid,
  FormControl,
  Button,
  Dialog,
  DialogTitle,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio
} from "@mui/material";

import { addUser, addPeople, updateUser, updatePeople } from "../../services/backend/UserService";
import {
  addPeople as addPeopleToStore,
  addUser as addUserToStore,
  updatePeople as updatePeopleToStore,
  updateUsers as updateUserToStore } from "../../redux/slices/userSlice";
import { useMemo } from "react";

const UserModal = ({ user, title, open, userType, onClose, onSubmit }) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.user.value);
  const [type, setType] = useState('people');
  const [usertitle, setUsertitle] = useState('Señor');
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [city, setCity] = useState('');
  const [country, setCountry] = useState('');
  const [postal, setPostal] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [nuip, setNuip] = useState('');
  const [entity, setEntity] = useState('');
  const [dependence, setDependence] = useState('');
  const [level, setLevel] = useState('');
  const [position, setPosition] = useState('');
  const [loading, setLoading] = useState(false);

  const titles = useMemo(() => {
    return type === 'people' ?
      ['Señor', 'Señora', 'Señores', 'Estudiante', 'Estudiantes', 'Profesor', 'Profesores', 'Doctor', 'Doctora', 'Doctores']
      : ['Empresa', 'Compañía', 'Union Temporal']
  }, [type]);

  const handleClose = ()  => {
    if (onClose) onClose();
    setLoading(false);
    setUsertitle('Señor');
    setType('people');
    setName('');
    setLastName('');
    setEmail('');
    setNuip('');
    setCity('');
    setCountry('');
    setPostal('');
    setPhone('');
    setAddress('');
    setEntity('');
    setDependence('');
    setLevel('');
    setPosition('');
    setLoading(false);
  };

  const handleSubmit = async (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    setLoading(true);

    const userData = {
      usertitle,
      name,
      lastName,
      email,
      city,
      country,
      nuip,
      postal,
      phone,
      address,
      entity,
      dependence,
      level,
      position,
      type
    };

    if (userType === "users") {
      userData.userId = user?.userId;
      if (user) {
        await updateUser(userData, authUser);
        dispatch(updateUserToStore(userData));
      } else {
        const response = await addUser(userData, authUser);
        userData.userId = response.userId;
        userData.isNew = true;
        dispatch(addUserToStore(userData));
      }
    } else if (userType === "people") {
      userData.peopleId = user?.peopleId;
      if (user) {
        await updatePeople(userData, authUser);
        dispatch(updatePeopleToStore(userData));
      } else {
        const response = await addPeople(userData, authUser);
        userData.peopleId = response.peopleId;
        userData.isNew = true;
        dispatch(addPeopleToStore(userData));
      }
    }

    if (onSubmit) onSubmit(userData);
    handleClose();
  };

  useEffect(() => {
    if (user) {
      setUsertitle(user.usertitle);
      setName(user.name);
      setLastName(user.lastName);
      setEmail(user.email);
      setNuip(user.nuip);
      setCity(user.city);
      setCountry(user.country);
      setPostal(user.postal);
      setPhone(user.phone);
      setAddress(user.address);
      setEntity(user.entity);
      setDependence(user.dependence);
      setLevel(user.level);
      setPosition(user.position);
      setType(user.type || 'people');
    }
  }, [user]);

  useEffect(() => {
    setUsertitle(type === 'people' ? 'Señor' : 'Empresa');
  }, [type])

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>{title}</DialogTitle>

      <Container maxWidth={false}>
        <Box component="form" onSubmit={handleSubmit} sx={{ m: 2, mb: 2 }}>
          <Grid container spacing={2}>
            {userType === 'people' &&
              <Grid item xs={12}>
                <RadioGroup value={type} onChange={(__, val) => setType(val)} row>
                  <FormControlLabel value="people" control={<Radio />} label="Persona" />
                  <FormControlLabel value="company" control={<Radio />} label="Empresa" />
                </RadioGroup>
              </Grid>
            }
            <Grid item xs={3}>
              <FormControl fullWidth>
                <Select
                  value={usertitle}
                  onChange={(evt) => setUsertitle(evt.target.value)}
                >
                  {titles.map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={type === 'people' ? 5 : 9}>
              <FormControl fullWidth>
                <TextField
                  label="Nombres"
                  required
                  value={name}
                  onChange={(evt) => setName(evt.target.value)}
                />
              </FormControl>
            </Grid>
            {type === 'people' &&
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    label="Apellidos"
                    required
                    value={lastName}
                    onChange={(evt) => setLastName(evt.target.value)}
                  />
                </FormControl>
              </Grid>
            }
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Correo electrónico"
                  required={type === 'people'}
                  value={email}
                  onChange={(evt) => setEmail(evt.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Número de identificación"
                  value={nuip}
                  onChange={(evt) => setNuip(evt.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Dirección"
                  value={address}
                  onChange={(evt) => setAddress(evt.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="País"
                  value={country}
                  onChange={(evt) => setCountry(evt.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Ciudad"
                  value={city}
                  onChange={(evt) => setCity(evt.target.value)}
                />
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <TextField
                  label="Código Postal"
                  value={postal}
                  onChange={(evt) => setPostal(evt.target.value)}
                />
              </FormControl>
            </Grid>
            {type === 'people' &&
              <>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Entidad"
                      value={entity}
                      onChange={(evt) => setEntity(evt.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Dependencia"
                      value={dependence}
                      onChange={(evt) => setDependence(evt.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Nivel"
                      value={level}
                      onChange={(evt) => setLevel(evt.target.value)}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth>
                    <TextField
                      label="Cargo"
                      value={position}
                      onChange={(evt) => setPosition(evt.target.value)}
                    />
                  </FormControl>
                </Grid>
              </>
            }
            <Grid item xs={12}>
              <Button
                variant="contained"
                onClick={handleClose}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                type="submit"
                disabled={loading}
                sx={{ marginLeft: 1 }}
              >
                {loading ? <CircularProgress size={24} /> : "Guardar"}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Dialog>
  );
};

export default UserModal;
